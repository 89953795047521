import twMerge from '@krea/common/utils/tailwind-utils';
import { PropsWithChildren, ReactNode } from 'react';

import Container from '@krea/common/shared-components/container';

import styles from './LayoutOneColumn.module.scss';

const LayoutOneColumn = ({
  children,
  aside,
  wide,
  className,
}: PropsWithChildren<{
  aside?: ReactNode;
  wide?: boolean;
  className?: string;
}>) => {
  const content = (
    <>
      <div className="w-100 py-6 py-md-7 pt-xl-8">{children}</div>
      {aside && <aside className="w-100 pb-6 pb-md-7 pb-xl-8">{aside}</aside>}
    </>
  );

  return (
    <>
      {!wide ? (
        <Container>
          <div
            className={twMerge({ [styles.root]: !wide }, 'mx-auto', className)}
          >
            {content}
          </div>
        </Container>
      ) : (
        <>{content}</>
      )}
    </>
  );
};

export default LayoutOneColumn;
