import classnames from 'classnames';
import twMerge from '@krea/common/utils/tailwind-utils';
import { ComponentProps, ReactNode } from 'react';

import Preloader from '../preloader';

import styles from './Button.module.scss';

interface ButtonProps extends ComponentProps<'button'> {
  color?: 'primary' | 'secondary' | 'danger' | 'white' | 'cta';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  variant?: 'contained' | 'outlined' | 'text';
  endIcon?: ReactNode;
  justify?: 'start' | 'center' | 'end' | 'between' | 'around';
  startIcon?: ReactNode;
  block?: boolean;
  preloader?: boolean;
  selected?: boolean;
}

const Button = ({
  className,
  children,
  color = 'primary',
  size = 'md',
  variant = 'contained',
  endIcon,
  justify = 'center',
  startIcon,
  block = false,
  onClick,
  disabled,
  preloader,
  selected = false,
  type = 'button',
  ...rest
}: ButtonProps) => {
  const buttonClassName = twMerge(
    styles.root,
    styles[size],
    styles[color],
    styles[variant],
    `tw-relative tw-items-center tw-justify-${justify}`,
    { [styles.selected]: selected },
    className,

    {
      'tw-inline-flex': !block,
      'tw-flex': block,
      'tw-py-1': variant !== 'text',
      'tw-px-3': size !== 'md' && size !== 'lg' && variant !== 'text',
      'tw-px-4': size !== 'sm' && size !== 'lg' && variant !== 'text',
      'tw-px-5': size !== 'sm' && size !== 'md' && variant !== 'text',
      'tw-px-0 tw-h-auto tw-bg-transparent': variant === 'text',
      'tw-border-0 tw-rounded': variant !== 'text',
    },
  );

  return (
    <button
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled || preloader}
      type={type}
      {...rest}
    >
      {startIcon && (
        <div
          className={classnames(styles.icon, {
            'tw-mr-3': size !== 'sm',
            'tw-mr-2': size === 'sm',
            invisible: preloader,
          })}
        >
          {startIcon}
        </div>
      )}

      <div className={classnames({ invisible: preloader })}>{children}</div>

      {endIcon && (
        <div
          className={classnames(
            styles.icon,
            { invisible: preloader },
            'tw-ml-3',
          )}
        >
          {endIcon}
        </div>
      )}

      {preloader && (
        <Preloader
          size={size}
          className={classnames('tw-absolute tw-h-full')}
        />
      )}
    </button>
  );
};

export default Button;
