import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getRedirectPathFromLocalStorage } from '@krea/common/services/redirectService';
import { settings } from '@krea/common/settings';
import BankIDFlow from '@krea/common/shared-components/bankID/BankIDFlow';
import FIBankIDFlow from '@krea/common/shared-components/bankID-fi/FIBankIDFlow';
import { useBankIDStore } from '@krea/common/store/bankID/hooks';
import { fetchUserData } from '@krea/common/store/user/api';
import { fetchUserQuery } from '@krea/common/store/user/hooks/queries';
import { APPLICATION_NAME, COUNTRY_CODE } from '@krea/common/utils';

import LoginGoToForm from './LoginGoToForm';
import LoginState from './LoginState';
import LoginWrapper from './LoginWrapper';

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isNoApplication, setIsNoApplication] = useState(false);
  const resetBankIDStore = useBankIDStore(
    ({ resetBankIDStore }) => resetBankIDStore,
  );

  const onSuccess = async () => {
    // Load data without putting user data into state.
    // To not trigger and state/route changes
    const data = await fetchUserData();

    const currentLoanApplicationID = data.me?.currentLoanApplication?.id;

    if (
      settings.appName === APPLICATION_NAME.CUSTOMER_WEB &&
      !currentLoanApplicationID
    ) {
      setIsNoApplication(true);

      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    } else {
      // Put user data into state
      await fetchUserQuery();

      navigate(getRedirectPathFromLocalStorage());
    }

    resetBankIDStore();
  };

  if (isNoApplication) {
    return (
      <LoginWrapper>
        <>
          <LoginState error>{t('login.noApplicationForSSN')}</LoginState>
          <LoginGoToForm />
        </>
      </LoginWrapper>
    );
  } else if (settings.countryCode === COUNTRY_CODE.SE) {
    return (
      <LoginWrapper>
        <BankIDFlow
          onSuccess={onSuccess}
          authenticationContext={settings.appName}
        />
      </LoginWrapper>
    );
  } else if (settings.countryCode === COUNTRY_CODE.FI) {
    return (
      <LoginWrapper>
        <FIBankIDFlow
          onSuccess={onSuccess}
          onCancel={() => {
            if (settings.appName === APPLICATION_NAME.CUSTOMER_WEB) {
              window.location.href = settings.countryWebsiteUrl;
            } else {
              navigate(0);
            }
          }}
        />
      </LoginWrapper>
    );
  }

  return null;
};

export default LoginPage;
