import loadable from '@loadable/component';
import axios from 'axios';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SVG from 'react-inlinesvg';
import CheckIcon from '@krea/common/images/svg/lang-check.svg';
import LangIcon from '@krea/common/images/svg/lang.svg';
import {
  getLanguageFromCookie,
  setLanguageInCookie,
} from '@krea/common/services/languageService';
import { settings } from '@krea/common/settings';
import { Text } from '@krea/common/shared-components/text';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';
import { COUNTRY_CODE } from '@krea/common/utils';

import styles from './Language.module.scss';

const Icon = ({ src, className }) => {
  return (
    <div className={classnames(styles.icon, 'position-relative', className)}>
      {src && <SVG className="position-absolute w-100 h-auto" src={src} />}
    </div>
  );
};

const Language = ({ className }) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState(
    getLanguageFromCookie() || i18n.language,
  );

  const { data: user = {} } = useGetUserData();

  const countryCode = settings.countryCode;

  const locales = useMemo(() => {
    const l = ['sv', 'en'];

    if (countryCode === COUNTRY_CODE.FI) {
      l.unshift('fi');
    }

    return l;
  }, [countryCode]);

  const capitalize = (str) => {
    return str.replace(/(^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  };

  const setLanguageOnBackend = async (lang) => {
    return axios.post(`/api/v1/me/language`, {
      preferred_language: lang,
    });
  };

  useEffect(() => {
    if (currentLang) {
      try {
        i18n.changeLanguage(currentLang);
        setLanguageInCookie(currentLang);
        dayjs.locale(currentLang);
      } catch (e) {
        console.error('Error changing language', e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang]);

  useEffect(() => {
    if (user?.preferredLanguage) {
      setCurrentLang(user.preferredLanguage.toLowerCase());
    }
  }, [user]);

  return (
    <div
      className={classnames(
        styles.lang,
        'position-relative user-select-none ml-xl-6',
        className,
      )}
      onMouseEnter={() => setTimeout(() => setIsOpen(true), 300)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div
        className={classnames(
          styles.current,
          'd-flex align-items-center px-3 font-weight-bold position-relative',
        )}
      >
        <Icon src={LangIcon} />
        <div className="ml-2 ml-xl-3">{capitalize(currentLang)}</div>
      </div>
      <ul
        className={classnames(
          styles.list,
          'list-unstyled m-0 position-absolute bg-white text-black rounded w-100 overflow-hidden border text-secondary',
        )}
      >
        {locales.map((item, index) => (
          <li
            className={classnames(
              styles.item,
              'd-flex align-items-center px-3 position-relative',
              {
                'text-primary': currentLang === item,
              },
            )}
            key={index}
            onClick={async () => {
              if (isOpen) {
                setCurrentLang(item);

                if (!isEmpty(user)) {
                  await setLanguageOnBackend(item);
                }

                window.location.reload();
              }
            }}
          >
            <div className={styles.checkboxWrapper}>
              <Icon
                className={styles.checkbox}
                src={currentLang === item && CheckIcon}
              />
            </div>

            <Text
              className={classnames(
                'font-weight-bold text-center ml-2 ml-xl-3',
              )}
            >
              {capitalize(item)}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Language;
