import { ToastContact } from './ToastContact';
import { ToastContent } from './ToastContent';
import { ToastRoot } from './ToastRoot';
import styles from './ToastWithContactPerson.module.scss';

export const ToastWithContactPerson = (props) => {
  return (
    <ToastRoot isWarning={props.isWarning} className={props.className}>
      <div className={styles.content}>
        <ToastContent {...props} />
        <ToastContact className={styles.contact} />
      </div>
    </ToastRoot>
  );
};
