import { useParams } from 'react-router-dom';

import { settings } from '@krea/common/settings';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';

import { APPLICATION_NAME } from '../utils';

export const useGetCurrentLoanApplicationID = () => {
  switch (settings.appName) {
    case APPLICATION_NAME.CUSTOMER_WEB:
      // Customer-Web
      const { data: user } = useGetUserData();
      const customerWebLoanApplicationID = user?.currentLoanApplication?.id;

      return customerWebLoanApplicationID?.toString();
    case APPLICATION_NAME.PARTNER_WEB:
      // Partner-Web
      const params = useParams();
      const { loanApplicationID: paramsLoanApplicationID } = params;

      // Some routes that need the loanApplicationID fall outside the RouterProvider (e.g. the app routes)
      // so we need to parse and split the URL manually instead of destructuring the object from useParams
      const splittedWildcardRoute = params['*']?.split('/');
      const splittedLocationURLRoute = window.location.pathname
        .split('/')
        .slice(1, 3);

      let parsedWildcardRoute = undefined;

      if (splittedWildcardRoute?.[0] === 'loan-applications') {
        parsedWildcardRoute = splittedWildcardRoute?.[1];
      }

      let parsedLocationURLRoute = undefined;

      if (splittedLocationURLRoute?.[0] === 'loan-applications') {
        parsedLocationURLRoute = splittedLocationURLRoute?.[1];
      }

      const partnerWebLoanApplicationID =
        paramsLoanApplicationID ||
        parsedWildcardRoute ||
        parsedLocationURLRoute;

      return partnerWebLoanApplicationID?.toString();

    default:
      throw new Error(
        'Invalid application name. Needs to run in either Customer-Web or Partner-Web context.',
      );
  }
};
