import React from 'react';
import { useTranslation } from 'react-i18next';

import { settings } from '@krea/common/settings';
import TextInput from '@krea/common/shared-components/fields/input';
import { TEST_IDS } from '@krea/common/utils';

const PhoneInput = (props) => {
  const { value, touched, errors, label, onBlur, name } = props;
  const { t } = useTranslation();

  return (
    <TextInput
      size="lg"
      id="mobile_phone_number"
      name={name || 'mobile_phone_number'}
      type="tel"
      label={label || t('applicationForm.commons.phoneLabel')}
      placeholder={t(
        `applicationForm.commons.phonePlaceholder.${settings.countryCode}`,
      )}
      autoComplete="on"
      value={value || ''}
      error={touched && errors ? errors : null}
      onChange={(e) => props.handleChange(e)}
      onBlur={onBlur}
      data-test-id={TEST_IDS.common.forms.phoneInput}
    />
  );
};

export default PhoneInput;
