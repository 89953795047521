import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  ReactNode,
} from 'react';

import CheckIcon from '@krea/common/images/svg/tick.svg';

import { Helper } from '../../helper';
import { Text } from '../../text';
import { Error } from '../error';
import { Label } from '../label';

import styles from './TextInput.module.scss';

interface IAdditionalTextProps {
  className: string;
  children: ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

const AdditionalText: React.FC<IAdditionalTextProps> = ({
  className,
  children,
  size,
}) => {
  return (
    <Text
      size={size}
      className={classnames(
        styles.additionalText,
        'tw-flex tw-items-center tw-justify-center tw-shrink-0 tw-font-bold tw-text-gray-dark tw-whitespace-nowrap',
        className,
      )}
    >
      {children}
    </Text>
  );
};

const useCombinedRefs = (...refs: React.Ref<HTMLInputElement>[]) => {
  const targetRef = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        (ref as any).current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};
interface ITextInputProps {
  className?: string;
  label?: ReactNode;
  textPrepend?: ReactNode;
  textAppend?: ReactNode;
  autoFocus?: boolean;
  id: string;
  error?: string;
  confirmation?: string;
  showConfirmation?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  disabled?: boolean;
  readonly?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  tooltip?: string;
  autoselect?: ReactNode;
  action?: ReactNode;
  forwardRef?: React.Ref<HTMLInputElement>;
  isFocused?: boolean;
  type?: string;
  name?: string;
  value?: string | number;
  placeholder?: string;
  autoComplete: 'on' | 'off' | string;
  inputMode?:
    | 'none'
    | 'text'
    | 'decimal'
    | 'numeric'
    | 'tel'
    | 'search'
    | 'email'
    | 'url';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ref: React.Ref<HTMLInputElement>;
}

const TextInput = forwardRef(
  ({
    className,
    label,
    textPrepend,
    textAppend,
    autoFocus,
    id,
    error,
    confirmation,
    showConfirmation,
    readonly,
    onFocus,
    onBlur,
    tooltip,
    autoselect,
    action,
    forwardRef,
    isFocused,
    value,
    name,
    placeholder,
    onChange,
    inputMode,
    autoComplete = 'off',
    disabled = false,
    type = 'text',
    size = 'md',
    ref,
    ...rest
  }: ITextInputProps) => {
    const [focused, setFocused] = useState(isFocused ? isFocused : false);

    const inputEl = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs(ref, inputEl);

    const inputFocus = () => {
      inputEl.current?.focus();
    };

    useEffect(() => {
      isFocused && inputFocus();
    }, [isFocused]);

    return (
      <div className={classnames(styles.textinput, className)} ref={forwardRef}>
        <div className="tw-relative">
          {label && (
            <Label
              className="tw-mb-2"
              htmlFor={id}
              onClick={inputFocus}
              disabled={disabled}
            >
              <span className="tw-align-middle tw-mr-1">{label}</span>
              {tooltip && (
                <Helper
                  className="tw-inline-block tw-align-middle tw-mt-1"
                  content={tooltip}
                />
              )}
            </Label>
          )}
          <div
            className={classnames(
              'tw-flex tw-items-center tw-flex-nowrap tw-rounded tw-overflow-hidden tw-relative',
              styles.textinputWrapper,
              {
                [styles.focused]: focused && !error,
                [styles.error]: error,
                [styles.confirmation]: showConfirmation && !error,
              },
            )}
          >
            {textPrepend && (
              <AdditionalText className="tw-pl-4">{textPrepend}</AdditionalText>
            )}

            <div className="tw-w-full">
              <input
                {...rest}
                placeholder={placeholder}
                autoComplete={autoComplete}
                type={type}
                value={value}
                name={name}
                id={id}
                autoFocus={autoFocus}
                ref={combinedRef}
                disabled={disabled}
                onChange={onChange}
                inputMode={inputMode}
                style={{ fontSize: '16px' }}
                className={classnames(
                  'tw-flex tw-items-center tw-w-full tw-px-4 tw-py-0 tw-border-0 tw-text-ellipsis tw-font-bold',
                  styles.textinputField,
                  styles[size],
                  {
                    'textinput-error': error,
                  },
                )}
                onFocus={(e) => {
                  setFocused(true);

                  if (onFocus) {
                    onFocus(e);
                  }
                }}
                onBlur={(e) => {
                  setFocused(false);

                  if (onBlur) {
                    onBlur(e);
                  }
                }}
              />
            </div>

            {textAppend && (
              <AdditionalText
                className={classnames('tw-pr-4', {
                  'textinput-error': error,
                  [styles.small]: size === 'sm',
                  [styles.large]: size === 'lg',
                  [styles.xtralarge]: size === 'xl',
                  invisible: !focused && !(rest as any).value,
                })}
              >
                {textAppend}
              </AdditionalText>
            )}

            {action}
          </div>
          {autoselect}
        </div>
        {error && <Error className="tw-mt-1 tw-ml-1">{error}</Error>}
        {showConfirmation && (
          <Text
            size="sm"
            className={classnames('tw-font-bold tw-mt-1 tw-ml-1 tw-flex')}
            style={{ color: '#00BAB5' }}
          >
            <SVG
              style={{ width: '13px', marginTop: '2px' }}
              className="tw-h-auto tw-mr-2"
              src={CheckIcon}
            />
            {confirmation}
          </Text>
        )}
      </div>
    );
  },
);

export default TextInput;
