import classnames from 'classnames';

import styles from './ToastAdditionalContent.module.scss';
import { ToastContent } from './ToastContent';
import { ToastRoot } from './ToastRoot';

export const ToastAdditionalContent = (props) => {
  const {
    isWarning,
    className,
    additional,
    additionalClassName,
    onClick,
    disabled,
    ...otherProps
  } = props;

  return (
    <ToastRoot isWarning={isWarning} className={className}>
      <div className={styles.main}>
        <ToastContent isWarning={isWarning} {...otherProps} />

        <div
          className={classnames(
            styles.additional,
            { 'mr-xl-10': !!onClick },
            additionalClassName,
          )}
        >
          {additional}
        </div>

        {onClick && (
          <div
            onClick={onClick}
            className={classnames(styles.closeBtn, {
              [styles.cursorDefault]: disabled,
              [styles.cursorPointer]: !disabled,
              [styles.closeButtonWarning]: isWarning,
              [styles.closeButtonPrimary]: !isWarning,
            })}
          >
            &times;
          </div>
        )}
      </div>
    </ToastRoot>
  );
};
