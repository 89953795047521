import classnames from 'classnames';
import React from 'react';
import { useNavigate, useMatch, useLocation } from 'react-router-dom';

import { useMobile } from '@krea/common/hooks';
import { Text } from '@krea/common/shared-components/text';

import styles from './ProgressBarItem.module.scss';

const ProgressBarItem = ({
  link,
  step,
  number,
  disabled,
  last,
  isAllowed,
  isTitle,
  count,
  hideStepsMenu,
}) => {
  const isMobile = useMobile();
  const location = useLocation();
  const match = useMatch(location.pathname);
  const navigate = useNavigate();

  const onClickItem = () => {
    if (link && !disabled && isAllowed) {
      navigate(link);
    }
  };

  const isPathMatching = () => {
    switch (step.id) {
      case 'dashboard':
        return match.pathname.includes('dashboard');
      case 'selected':
        return match.pathname.includes('selected');
      case 'advice':
        return match.pathname.includes('advice');
      case 'preview':
        return (
          match.pathname.includes('preview') ||
          match.pathname.includes('questions')
        );
      default:
        return false;
    }
  };

  return (
    <>
      {isTitle ? (
        <>
          {isPathMatching() && (
            <div
              className="d-flex align-items-center py-2 py-xl-0 grow-1 font-weight-bold"
              style={{ minWidth: '0px' }}
            >
              <Text
                size="sm"
                className="bg-disabled mr-2 text-white px-2 rounded shrink-0"
              >{`${number}/${count}`}</Text>
              <Text
                size="sm"
                className="text-truncate"
                style={{ minWidth: '0px' }}
              >
                {step.title}
              </Text>
            </div>
          )}
        </>
      ) : (
        <>
          <li
            className="d-flex align-items-center py-2 py-xl-0"
            style={{ cursor: !disabled && isAllowed ? 'pointer' : 'inherit' }}
            onClick={() => {
              onClickItem();
              hideStepsMenu && !isTitle && hideStepsMenu();
            }}
          >
            <Text
              size={isMobile ? 'sm' : 'md'}
              className={classnames(
                styles.number,
                'd-flex align-items-center justify-content-center font-weight-bold mr-2 rounded-circle border shrink-0',
                {
                  'bg-dark border-dark text-white': isPathMatching(),
                  'border-disabled text-disabled': disabled || !isAllowed,
                  'border-dark text-dark':
                    !isPathMatching() && !disabled && isAllowed,
                },
              )}
            >
              {number}
            </Text>
            <Text
              size={isMobile ? 'sm' : 'md'}
              className={classnames('font-weight-bold grow-1 text-truncate', {
                'text-dark': isPathMatching(),
                'text-disabled': disabled || !isAllowed,
              })}
              style={{ minWidth: '0px' }}
            >
              {step.title}
            </Text>
          </li>

          {!last && !isMobile && (
            <li className="grow-1 border-top border-disabled mx-3" />
          )}
        </>
      )}
    </>
  );
};

export default ProgressBarItem;
