import { camelCase } from 'lodash';
import { getI18n } from 'react-i18next';

const FIELD_REQUEST_WITH_TOAST_NAMES = {
  REASON: 'reasonExplanation',
  REFINANCING_AMOUNTS: 'refinancingAmounts',
  LEASING: 'leasingAdditionalData',
  PROPERTY_ADDITIONAL_DATA: 'propertyAdditionalData',
  GUARANTORS: 'guarantors',
};

export const FIELD_REQUEST_FIELDS = FIELD_REQUEST_WITH_TOAST_NAMES;

export const FIELD_REQUEST_TYPES = {
  COLLATERAL: 'COLLATERAL',
  ADDITIONAL_INFORMATION: 'ADDITIONAL_INFORMATION',
};

export const FIELD_REQUESTS_WITH_DISABLED_BUTTONS_WHILE_SUBMITTING = [
  'selfEstimatedHistoricRevenue',
];

export const getDefaultToastProperties = (steps) => {
  const { t } = getI18n();
  const numberOfQuestions = steps?.length || 0;

  return {
    [FIELD_REQUEST_WITH_TOAST_NAMES.REASON]: {
      warning: true,
      title: t(
        `triageFields.toastTexts.${FIELD_REQUEST_WITH_TOAST_NAMES.REASON}.title`,
      ),
      subtitle: t(
        `triageFields.toastTexts.${FIELD_REQUEST_WITH_TOAST_NAMES.REASON}.subtitle`,
        { numberOfQuestions },
      ),
    },
    [FIELD_REQUEST_WITH_TOAST_NAMES.REFINANCING_AMOUNTS]: {
      skippable: true,
      title: t(
        `triageFields.toastTexts.${FIELD_REQUEST_WITH_TOAST_NAMES.REFINANCING_AMOUNTS}.title`,
      ),
      subtitle: t(
        `triageFields.toastTexts.${FIELD_REQUEST_WITH_TOAST_NAMES.REFINANCING_AMOUNTS}.subtitle`,
      ),
    },
    [FIELD_REQUEST_WITH_TOAST_NAMES.GUARANTORS]: {
      closable: true,
      warning: true,
      title: t(
        `triageFields.toastTexts.${FIELD_REQUEST_WITH_TOAST_NAMES.GUARANTORS}.title`,
      ),
      subtitle: t(
        `triageFields.toastTexts.${FIELD_REQUEST_WITH_TOAST_NAMES.GUARANTORS}.subtitle`,
      ),
    },
    [FIELD_REQUEST_WITH_TOAST_NAMES.LEASING]: {
      title: t(
        `triageFields.toastTexts.${FIELD_REQUEST_WITH_TOAST_NAMES.LEASING}.title`,
      ),
      subtitle: t(
        `triageFields.toastTexts.${FIELD_REQUEST_WITH_TOAST_NAMES.LEASING}.subtitle`,
      ),
    },
    [FIELD_REQUEST_WITH_TOAST_NAMES.PROPERTY_ADDITIONAL_DATA]: {
      title: t(
        `triageFields.toastTexts.${FIELD_REQUEST_WITH_TOAST_NAMES.PROPERTY_ADDITIONAL_DATA}.title`,
      ),
      subtitle: t(
        `triageFields.toastTexts.${FIELD_REQUEST_WITH_TOAST_NAMES.PROPERTY_ADDITIONAL_DATA}.subtitle`,
      ),
    },
  };
};

export const getDefaultToastStates = (creationEntryPoint) => ({
  [FIELD_REQUEST_WITH_TOAST_NAMES.REASON]: { isOpen: true },
  [FIELD_REQUEST_WITH_TOAST_NAMES.REFINANCING_AMOUNTS]: { isOpen: true },
  [FIELD_REQUEST_WITH_TOAST_NAMES.GUARANTORS]: { isOpen: true },
  [FIELD_REQUEST_WITH_TOAST_NAMES.LEASING]: { isOpen: true },
  [FIELD_REQUEST_WITH_TOAST_NAMES.PROPERTY_ADDITIONAL_DATA]: { isOpen: true },
});

export const formatFRFields = (fieldRequests) => {
  return fieldRequests.map((fieldRequest) => ({
    ...fieldRequest,
    field: camelCase(fieldRequest.field),
  }));
};

export const getFilteredFieldRequests = (
  fieldsToFilter = [],
  fieldRequests = [],
) => {
  if (fieldsToFilter.length) {
    const result = [];

    // eslint-disable-next-line array-callback-return
    fieldsToFilter.forEach((field) =>
      // eslint-disable-next-line array-callback-return
      fieldRequests.find((item) => {
        if (item.field === camelCase(field)) {
          result.push(item);
        }
      }),
    );

    return result;
  } else {
    return fieldRequests;
  }
};
