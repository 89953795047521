import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { client } from '@krea/common/graphql';
import { getUtmParamsFromCookie } from '@krea/common/services/queryParamsService';
import { collectViaBankID } from '@krea/common/store/bankID/api';
import { useBankIDStore } from '@krea/common/store/bankID/hooks';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import { BANKID_AUTHENTICATE_STORE_KEY } from '@krea/common/store/queryClient';
import { BANKID_FLOW_STATES, HINT_CODE_STATES } from '@krea/common/utils';

import { useAuthenticateMutation } from './useAuthenticateMutation';

export const useCollectQuery = (
  onSuccess,
  identificationContext,
  authenticationContext,
  onFailure,
) => {
  const { i18n } = useTranslation();
  const { mutate: bankIDAuthenticate } = useAuthenticateMutation();

  const extraData = {
    gaClientId: JSON.parse(getUtmParamsFromCookie()).ga_client_id,
    preferredLanguage: i18n.language,
  };

  const clearSsn = useLoanApplicationFormStore(({ clearSsn }) => clearSsn);
  const { orderRef, resetBankIDStore, setQrCode } = useBankIDStore((state) => ({
    orderRef: state.orderRef,
    resetBankIDStore: state.resetBankIDStore,
    setQrCode: state.setQrCode,
  }));

  return useQuery({
    queryKey: [BANKID_AUTHENTICATE_STORE_KEY],
    queryFn: async () => {
      try {
        const response = await collectViaBankID({
          orderRef,
          extraData,
          identificationContext,
          authenticationContext,
        });

        const { status, qrCode, hintCode } = response?.data || {};

        setQrCode(qrCode);

        if (status === BANKID_FLOW_STATES.FAILED) {
          if (hintCode === HINT_CODE_STATES.START_FAILED) {
            // If we get start failed, we need to restart the flow.
            bankIDAuthenticate();

            return {
              status: BANKID_FLOW_STATES.PENDING,
              hintCode: HINT_CODE_STATES.OUTSTANDING_TRANSACTION,
            };
          }

          clearSsn();
          resetBankIDStore();

          return { status, hintCode };
        } else if (status === BANKID_FLOW_STATES.PENDING) {
          return { status, hintCode };
        } else if (status === BANKID_FLOW_STATES.COMPLETE) {
          const { accessToken, refreshToken } = response?.data?.tokens || {};

          window.localStorage.setItem('accessToken', accessToken);
          window.localStorage.setItem('refreshToken', refreshToken);
          client.setHeader('authorization', `Bearer ${accessToken}`);

          clearSsn();

          //Always stop the flow on BankID complete
          resetBankIDStore();

          onSuccess();

          return { status, hintCode };
        } else {
          // Any other status is not handled (dont thing there is one).
          return { status, hintCode };
        }
      } catch (err) {
        if (onFailure) {
          await onFailure();
        }

        if (err?.status !== 400) {
          console.error('Collecting BankID failed: ', err);
          Sentry.captureException(err);
        }

        clearSsn();
        resetBankIDStore();

        return {
          status: BANKID_FLOW_STATES.FAILED,
          hintCode: HINT_CODE_STATES.ERROR,
        };
      }
    },
    refetchInterval: 2000,
    refetchIntervalInBackground: true,
    staleTime: Infinity,
    enabled: !!orderRef,
  });
};
