import axios from 'axios';

import { client } from '@krea/common/graphql';

export const fieldRequestGraphQLDataQueryString = `{
      id,
      displayName,
      subtitle,
      displayText,
      delegatedInformationResources {
        id,
        delegatedEmail
      },
      type,
      field,
      required,
      isSubmitted,
      isCollectedByLender,
      submittedData,
    }`;

export const fetchFieldRequestsData = async (loanApplicationID) => {
  return client.request(`{
      loanApplication(loanApplicationId: ${loanApplicationID}) {
        fieldRequests ${fieldRequestGraphQLDataQueryString}
      }
    }`);
};

export const fetchFieldRequestData = async (fieldRequestId) => {
  return client.request(`{
    fieldRequest(fieldRequestId: ${fieldRequestId}) ${fieldRequestGraphQLDataQueryString}
  }`);
};

export const postFieldRequestData = async ({ fieldRequestID, value }) => {
  return axios.post(`/api/v1/field-requests/${fieldRequestID}/`, value);
};

export const putAccountingIntegrationData = async (
  accountingDataId,
  serviceType = null,
  organisationNumber = null,
) => {
  const body = {
    action: 'CONSENT',
    service_type: serviceType,
    organisation_number: organisationNumber,
  };

  return axios.put(
    `/accounting-service/api/v1/accounting-data/${accountingDataId}`,
    body,
  );
};

export const deleteFile = async (id) => {
  await axios.delete(`/media-service/api/v1/files/${id}`);
};

export const delegateFieldRequest = async (id, email) => {
  await axios.post(`/api/v1/field-requests/${id}/delegate`, {
    delegated_email: email,
  });
};

export const withdrawDelegationForFieldRequest = async (id, email) => {
  await axios.post(`/api/v1/field-requests/${id}/withdraw-delegation`, {
    delegated_email: email,
  });
};

export const fetchDelegatedFieldRequestsData = async () => {
  return axios.get(`/api/v1/delegated-information`);
};
