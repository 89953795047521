import {
  useAuthenticateMutation,
  useCollectQuery,
  useCancelBankID,
} from '@krea/common/store/bankID/hooks/utils';
import { BANKID_FLOW_STATES, HINT_CODE_STATES } from '@krea/common/utils';

export const useBankIDAuthenticate = (
  onSuccess,
  identificationContext,
  authenticationContext,
  onFailure,
) => {
  const { data: collectResponse = {} } = useCollectQuery(
    onSuccess,
    identificationContext,
    authenticationContext,
    onFailure,
  );
  const {
    mutate: bankIDAuthenticate,
    isError: authenticateIsError,
    data: authenticateData,
    reset: resetAuthenticateMutation,
  } = useAuthenticateMutation();
  const { mutate: cancelBankID } = useCancelBankID();

  let status = null;

  if (authenticateIsError) {
    status = BANKID_FLOW_STATES.FAILED;
  } else if (collectResponse?.status) {
    status = collectResponse.status;
  } else if (authenticateData) {
    // On authenticate mutate, we optimistically set the status to pending
    status = BANKID_FLOW_STATES.PENDING;
  }

  const errorCode = collectResponse.errorCode || authenticateData?.errorCode;

  const hintCode = authenticateIsError
    ? HINT_CODE_STATES.ERROR
    : collectResponse?.hintCode;

  const isPending = status === BANKID_FLOW_STATES.PENDING;
  const isFailed = status === BANKID_FLOW_STATES.FAILED;
  const isComplete = status === BANKID_FLOW_STATES.COMPLETE;
  const isNotInitiated = !isPending && !isFailed && !isComplete;

  const isError = isFailed && hintCode === HINT_CODE_STATES.ERROR;
  const isUserCancel = isFailed && hintCode === HINT_CODE_STATES.USER_CANCEL;
  const isUserSign = isPending && hintCode === HINT_CODE_STATES.USER_SIGN;

  return {
    isPending,
    isFailed,
    isComplete,
    isNotInitiated,
    isError,
    isUserCancel,
    isUserSign,
    bankIDAuthenticate,
    cancelBankID,
    resetAuthenticateMutation,
    errorCode,
  };
};
