import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { getPrimaryLanguage } from '@krea/common/utils';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        partnerWeb: require('./translations/partner-web/en.json'),
        common: require('@krea/common/translations/common/en.json'),
      },
      sv: {
        partnerWeb: require('./translations/partner-web/sv.json'),
        common: require('@krea/common/translations/common/sv.json'),
      },
      fi: {
        partnerWeb: require('./translations/partner-web/fi.json'),
        common: require('@krea/common/translations/common/fi.json'),
      },
    },
    lng: getPrimaryLanguage(),
    fallbackLng: 'en',
    supportedLngs: ['sv', 'en', 'fi'],
    ns: ['partnerWeb', 'common'],
    defaultNS: 'partnerWeb',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'ul', 'li'],
    },
  });

export default i18n;
