import classnames from 'classnames';
import React from 'react';

import styles from './Dialog.module.scss';

const DialogContent = ({ children, withLabel, className }) => {
  return (
    <div className={classnames(styles.content, 'grow-1 shrink-1', className)}>
      <div
        className={classnames('px-6 pb-6 position-relative', {
          'pt-8': withLabel,
          'pt-4': !withLabel,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default DialogContent;
