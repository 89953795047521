import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { isEmpty, set } from 'lodash';
import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetCurrentLoanApplicationID, useMobile } from '@krea/common/hooks';
import CloseSVG from '@krea/common/images/svg/close.svg';
import UserSVG from '@krea/common/images/svg/user.svg';
import { settings } from '@krea/common/settings';
import Language from '@krea/common/shared-components/app/language';
import Logo from '@krea/common/shared-components/app/logo';
import Menu from '@krea/common/shared-components/app/menu';
import Button from '@krea/common/shared-components/button';
import Container from '@krea/common/shared-components/container';
import CustomerServiceContactResponsiveDialog from '@krea/common/shared-components/responsive-dialogs/CustomerServiceContact';
import { useLogout } from '@krea/common/store/user/hooks/mutations';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';
import { COUNTRY_CODE } from '@krea/common/utils';

import DesktopSettingsMenu from './DesktopSettingsMenu';
import styles from './Header.module.scss';
import HeaderAppSelector from './HeaderAppSelector';
import HeaderSteps from './HeaderSteps';

export const MenuContext = React.createContext({
  toggleMenu: () => {},
  showSteps: () => {},
  stepsIsVisible: false,
});

const motionBackdrop = {
  hidden: {
    opacity: 0,
    zIndex: 0,
  },
  visible: {
    opacity: 1,
    zIndex: 200000,
  },
};

const motionMenu = {
  hidden: {
    opacity: 0,
    transitionEnd: {
      visibility: 'hidden',
      zIndex: 0,
    },
    transition: {
      duration: 0.4,
      ease: 'easeOut',
    },
  },
  visible: {
    opacity: 1,
    visibility: 'visible',
    zIndex: 200001,
    transition: {
      duration: 0.4,
      ease: 'easeOut',
    },
  },
};

const logoStyling = (countrycode) => {
  switch (countrycode) {
    case COUNTRY_CODE.SE:
      return styles.kreaLogo;
    case COUNTRY_CODE.FI:
      return styles.kreditaLogo;
    default:
      return styles.kreaLogo;
  }
};

const MenuButton = forwardRef(({ className, onClick, children }, ref) => {
  return (
    <button
      ref={ref}
      className={classnames(
        styles.menuButton,
        'd-flex justify-content-center align-items-center p-2',
        className,
      )}
      onClick={onClick}
      aria-label="Menu"
    >
      <div className={classnames(styles.menuIcon, 'position-relative')}>
        {children}
      </div>
    </button>
  );
});

const MobileMenuDrawer = forwardRef(
  ({ onClose, children, fullScreen }, ref) => {
    return (
      <div
        ref={ref}
        className={classnames(
          styles.mobileMenu,
          'd-flex flex-column position-fixed w-100 mh-100 bg-white',
          {
            'h-100': fullScreen,
          },
        )}
      >
        <div
          className={classnames(
            styles.height,
            'grow-0 shrink-0 w-100 border-bottom',
          )}
        >
          <Container fluid className="h-100">
            <div className="d-flex justify-content-between align-items-center h-100 text-primary">
              <Logo
                className={classnames(
                  styles.logo,
                  logoStyling(settings.countryCode),
                )}
              />

              <MenuButton className="mr-n2" onClick={onClose}>
                <SVG
                  className="position-absolute w-100 h-auto"
                  src={CloseSVG}
                />
              </MenuButton>
            </div>
          </Container>
        </div>

        <div className="grow-1 shrink-1 w-100 overflow-auto">
          <Container>
            <div className="py-6 w-100">{children}</div>
          </Container>
        </div>
      </div>
    );
  },
);

const Header = ({ menu, showSubHeader }) => {
  const isMobile = useMobile();
  const navigate = useNavigate();
  const loanApplicationID = useGetCurrentLoanApplicationID();

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const refHeader = useRef(null);
  const navRef = useRef(null);
  const refMenu = useRef(null);

  const [sticky, setSticky] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [stepsMenu, setStepsMenu] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);

  const { data: user = {} } = useGetUserData();
  const { mutate: logout } = useLogout();

  const isPublicRoute = settings.unauthorizedPaths.includes(pathname);
  const isOnLoanApplicationFlowPage = !!loanApplicationID;
  const isNotLoginOrRegisterPage = !['/login', '/register'].includes(pathname);

  const mainLogoHref = isPublicRoute ? settings.countryWebsiteUrl : '/';
  const partnerOrganisationName =
    user.partnerOrganisation?.customerCompany?.name;

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const toggleStepsMenu = () => {
    setStepsMenu(!stepsMenu);
  };

  const onLogout = () => {
    logout('/login');
  };

  const [openContactDrawer, setOpenContactDrawer] = useState(false);

  useEffect(() => {
    function scrollCalc() {
      if (refHeader.current && refHeader.current.offsetHeight) {
        window.pageYOffset > refHeader.current.offsetHeight
          ? setSticky(true)
          : setSticky(false);
      }
    }

    scrollCalc();

    window.addEventListener('scroll', scrollCalc);

    return () => {
      window.removeEventListener('scroll', scrollCalc);
    };
  }, []);

  useEffect(() => {
    let lastScrollTop = 0;

    function UpDownScroll() {
      let st = window.pageYOffset || document.documentElement.scrollTop;

      if (st > lastScrollTop) {
        // downscroll code
        setScrollDown(true);
      } else {
        // upscroll code
        setScrollDown(false);
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }

    if (sticky) {
      UpDownScroll();
      window.addEventListener('scroll', UpDownScroll);
    }

    return () => {
      window.removeEventListener('scroll', UpDownScroll);
    };
  }, [sticky]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (mobileMenu) {
        if (refMenu.current && !refMenu.current.contains(event.target)) {
          toggleMenu();
        }
      }

      if (stepsMenu) {
        if (navRef.current && !navRef.current.contains(event.target)) {
          toggleStepsMenu();
        }
      }
    }

    window.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleClickOutside);
    };
  });

  useEffect(() => {
    function handleResize() {
      if (mobileMenu) {
        toggleMenu();
      }

      if (stepsMenu) {
        toggleStepsMenu();
      }
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = (
    <MenuContext.Provider
      value={{ showSteps: toggleStepsMenu, stepsIsVisible: stepsMenu }}
    >
      <HeaderSteps
        isOnLoanApplicationFlowPage={isOnLoanApplicationFlowPage}
        loanApplicationID={loanApplicationID}
      />
    </MenuContext.Provider>
  );

  const showBackdrop = mobileMenu || stepsMenu;

  return (
    <>
      <header
        ref={refHeader}
        className={classnames(
          styles.root,
          styles.height,
          'position-fixed grow-0 shrink-0 w-100 bg-white border-bottom',
          {
            [styles.sticky]: sticky,
            [styles.down]: scrollDown && !isMobile,
          },
        )}
      >
        <Container fluid className="h-100">
          <div className="d-flex align-items-center justify-content-between h-100 text-primary">
            <div className="d-flex align-items-center">
              <span style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
                <Logo
                  className={classnames(
                    styles.logo,
                    logoStyling(settings.countryCode),
                  )}
                  href={mainLogoHref}
                />
              </span>

              {isNotLoginOrRegisterPage && menu && !isMobile && (
                <MenuContext.Provider value={{ toggleMenu }}>
                  <Menu
                    list={menu}
                    forMobile={isMobile}
                    onCloseMenu={toggleMenu}
                    className="mx-5 my-0 p-0"
                  />
                </MenuContext.Provider>
              )}
            </div>

            <div className="d-flex align-items-center">
              <>
                {!isMobile && isOnLoanApplicationFlowPage && !isEmpty(user) && (
                  <HeaderAppSelector
                    isMobile={isMobile}
                    loanApplicationID={loanApplicationID}
                  />
                )}

                <Language />

                {!isEmpty(user) && (
                  <>
                    <div className="shrink-0">
                      <Button
                        variant="text"
                        className="px-4"
                        onClick={() => {
                          setOpenContactDrawer(!openContactDrawer);
                        }}
                      >
                        {t('menu.support')}
                      </Button>
                    </div>

                    <CustomerServiceContactResponsiveDialog
                      open={openContactDrawer}
                      setOpen={setOpenContactDrawer}
                    />
                  </>
                )}

                {isNotLoginOrRegisterPage && isEmpty(user) && (
                  <Button
                    size={isMobile ? 'sm' : 'md'}
                    variant="outlined"
                    onClick={() => {
                      navigate('/login');
                    }}
                  >
                    {t('menu.login')}
                  </Button>
                )}

                {isNotLoginOrRegisterPage && !isMobile && !isEmpty(user) && (
                  <DesktopSettingsMenu />
                )}

                {isMobile && isNotLoginOrRegisterPage && !isEmpty(user) && (
                  <MenuButton className="mr-n2" onClick={toggleMenu}>
                    <SVG
                      className="position-absolute w-100 h-auto"
                      src={UserSVG}
                    />
                  </MenuButton>
                )}
              </>
            </div>
          </div>
        </Container>
      </header>

      {isNotLoginOrRegisterPage && (
        <>
          {showSubHeader && (
            <nav
              ref={navRef}
              className={classnames(
                styles.navigation,
                'd-flex flex-column flex-xl-row position-fixed w-100 mh-100 bg-white border-bottom',
                {
                  [styles.sticky]: sticky || stepsMenu,
                  [styles.down]: scrollDown && !isMobile,
                  'border-white': sticky || stepsMenu,
                },
              )}
            >
              {!isMobile ? (
                <div className="h-100 d-flex justify-content-center w-100">
                  <Container className="h-100">
                    <div className="h-100 d-flex justify-content-center">
                      {steps}
                    </div>
                  </Container>
                </div>
              ) : (
                <>{steps}</>
              )}
            </nav>
          )}

          {isMobile && (
            <AnimatePresence>
              {mobileMenu && (
                <>
                  <motion.div
                    variants={motionMenu}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    key="menu"
                  >
                    <MobileMenuDrawer ref={refMenu} onClose={toggleMenu}>
                      <div className="d-flex flex-column align-items-center bg-gray rounded p-4">
                        {isNotLoginOrRegisterPage && menu && (
                          <MenuContext.Provider value={{ toggleMenu }}>
                            <Menu
                              list={menu}
                              forMobile={isMobile}
                              onCloseMenu={toggleMenu}
                              className="w-100 border-bottom border-white mx-0 mt-0 mb-4 pb-4"
                            />
                          </MenuContext.Provider>
                        )}

                        {isOnLoanApplicationFlowPage && !isEmpty(user) && (
                          <HeaderAppSelector
                            isMobile={isMobile}
                            loanApplicationID={loanApplicationID}
                          />
                        )}

                        {!isEmpty(user) && (
                          <>
                            {!user.isAdmin && (
                              <div className="shrink-0">
                                <div
                                  style={{ cursor: 'pointer' }}
                                  className="font-weight-bold text-left text-primary mb-2"
                                  onClick={() => {
                                    setMobileMenu(false);
                                    navigate('/settings');
                                  }}
                                >
                                  {t('menu.settings')}
                                </div>
                              </div>
                            )}
                            <div className="shrink-0">
                              <Button
                                variant="text"
                                className="px-4"
                                onClick={onLogout}
                              >
                                {t('menu.logout')}{' '}
                                {partnerOrganisationName &&
                                  ` ${partnerOrganisationName}`}
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </MobileMenuDrawer>
                  </motion.div>

                  <motion.div
                    variants={motionBackdrop}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    transition={{
                      default: { duration: 0.4, ease: 'easeOut' },
                    }}
                    className={classnames(
                      styles.backdrop,
                      'position-fixed w-100 vh-100',
                    )}
                    key="backdrop"
                  />
                </>
              )}
            </AnimatePresence>
          )}
        </>
      )}
    </>
  );
};

export default Header;
