import { getCookie, setCookie } from './cookieService';

const LANGUAGE_COOKIE_KEY = 'django_language';

export function getLanguageFromCookie() {
  let lang = getCookie(LANGUAGE_COOKIE_KEY);

  return lang;
}

export function setLanguageInCookie(lang) {
  console.log('Setting language cookie to', lang);

  return setCookie(LANGUAGE_COOKIE_KEY, lang);
}
