import classnames from 'classnames';

import warningInfoIcon from '@krea/common/images/svg/info-icon-warning.svg';
import infoIcon from '@krea/common/images/svg/info-icon.svg';
import { Text } from '@krea/common/shared-components/text';

import { useMobile } from '../../hooks';

import styles from './ToastContent.module.scss';

import SVG from 'react-inlinesvg';

export const ToastContent = ({
  innerText: { title, subtitle },
  onClick,
  isWarning,
  disabled,
  excludeIcon,
  disabledIconSmallScreens,
  icon,
}) => {
  const isMobile = useMobile();

  return (
    <div className={styles.main}>
      {!excludeIcon && !(disabledIconSmallScreens && isMobile) && (
        <div className={classnames(styles.icon)}>
          <div
            className={
              icon &&
              classnames(styles.iconContent, {
                [styles.iconWarning]: !icon && isWarning,
                [styles.iconPrimary]: !icon && !isWarning,
                [styles.iconCustomWarning]: icon && isWarning,
                [styles.iconCustomPrimary]: icon && !isWarning,
              })
            }
          >
            {!(disabledIconSmallScreens && isMobile) && (
              <SVG
                style={{ width: '22px' }}
                className={classnames('h-auto', {
                  'mx-1': isMobile,
                  'mr-4': !isMobile,
                })}
                src={icon ? icon : isWarning ? warningInfoIcon : infoIcon}
              />
            )}
          </div>
        </div>
      )}

      <div className={styles.textDiv}>
        <Text size="md" className={styles.title}>
          {title}
        </Text>
        <Text size={isMobile ? 'sm' : 'md'}>{subtitle}</Text>
      </div>

      {onClick && (
        <div
          onClick={onClick}
          className={classnames(styles.closeBtn, {
            [styles.cursorDefault]: disabled,
            [styles.cursorPointer]: !disabled,
            [styles.closeButtonWarning]: isWarning,
            [styles.closeButtonPrimary]: !isWarning,
          })}
        >
          &times;
        </div>
      )}
    </div>
  );
};
