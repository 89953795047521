import classnames from 'classnames';
import React from 'react';

import styles from './LoginLayout.module.scss';

const LoginLayout = ({ children, className }) => {
  return (
    <div
      className={classnames(
        styles.root,
        'd-flex flex-column align-items-center w-100 p-6 p-lg-7 border rounded',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default LoginLayout;
