import loadable from '@loadable/component';
import * as PropTypes from 'prop-types';
import React from 'react';

import styles from './Tooltip.module.scss';

const Tooltip = loadable(() => import('react-tooltip-lite'));

const AppTooltip = ({ className, children, content, ...rest }) => {
  return (
    <Tooltip
      className={className}
      tipContentClassName={styles.root}
      content={content}
      padding="0.875rem"
      zIndex={20001}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

AppTooltip.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node,
};

export default AppTooltip;
