import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import {
  CURRENT_LOAN_APPLICATION_STORE_KEY,
  SELECTED_LENDER_RESPONSE_FIELD_REQUESTS_STORE_KEY,
} from '@krea/common/store/queryClient';

import { deselectLenderResponse } from 'store/current-loan-application/api';

export const useDeselectLenderResponse = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ loanApplicationID }) =>
      deselectLenderResponse(loanApplicationID),
    onSuccess: async (_, { loanApplicationID }) => {
      await queryClient.invalidateQueries({
        queryKey: [CURRENT_LOAN_APPLICATION_STORE_KEY, loanApplicationID],
      });
      queryClient.setQueryData(
        [SELECTED_LENDER_RESPONSE_FIELD_REQUESTS_STORE_KEY, loanApplicationID],
        {},
      );

      navigate(`/loan-applications/${loanApplicationID}`);
    },
    onError: (err) => {
      console.error('Failed deselecting bid: err', err);
    },
  });
};
