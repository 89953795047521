import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMobile } from '@krea/common/hooks';
import ProgressBarItem from '@krea/common/shared-components/app/progress-bar/ProgressBarItem';
import ProgressBarMobile from '@krea/common/shared-components/app/progress-bar/ProgressBarMobile';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';
import {
  ALLOWED_STATES_FOR_DASHBOARD,
  ALLOWED_STATES_FOR_SELECTED_BID,
  LOAN_APPLICATION_STATES,
} from '@krea/common/utils';

import { MenuContext } from 'components/app/header';
import { useGetCurrentLoanApplication } from 'store/current-loan-application/hooks/queries';
import { PartnerOrganisationType, hasPermission } from 'utils';

import styles from './ProgressBar.module.scss';

const DesktopStepItem = ({
  step,
  state,
  number,
  disabled,
  activeOnlyWhenExact,
  last,
  isTitle,
  count,
  hideStepsMenu,
}) => {
  const isAllowedForRoute = () => {
    if (step.id === 'dashboard') {
      return ALLOWED_STATES_FOR_DASHBOARD.includes(state);
    } else if (step.id === 'selected') {
      return ALLOWED_STATES_FOR_SELECTED_BID.includes(state);
    } else if (step.id === 'advice') {
      return state === LOAN_APPLICATION_STATES.ADVICE;
    }

    return true;
  };

  return (
    <ProgressBarItem
      step={step}
      number={number}
      last={last}
      isAllowed={isAllowedForRoute()}
      disabled={disabled}
      link={step.link}
      activeOnlyWhenExact={activeOnlyWhenExact}
      isTitle={isTitle}
      count={count}
      hideStepsMenu={hideStepsMenu}
    />
  );
};

const ProgressBar = ({
  className,
  isOpen,
  hideStepsMenu,
  loanApplicationID,
}) => {
  const { t } = useTranslation();
  const { data: user = {} } = useGetUserData();
  const { data: { state } = {} } =
    useGetCurrentLoanApplication(loanApplicationID);

  const dashboardOrAdvice =
    state === LOAN_APPLICATION_STATES.ADVICE ? 'advice' : 'dashboard';

  const steps = [
    {
      id: 'preview',
      link: `/loan-applications/${loanApplicationID}/preview`,
      title: t('commons.processSteps.improveLoanApplication'),
      description: t('commons.processSteps.improveLoanApplicationSubheading', {
        numAnswered: 0,
        numQuestions: 0,
      }),
      disabled: false,
    },
    {
      id: dashboardOrAdvice,
      link: `/loan-applications/${loanApplicationID}/${dashboardOrAdvice}`,
      title: t('commons.processSteps.compareBids'),
      description: '',
      disabled:
        dashboardOrAdvice === 'dashboard' &&
        !hasPermission(user, PartnerOrganisationType.REGULAR),
    },
    {
      id: 'selected',
      link: `/loan-applications/${loanApplicationID}/selected`,
      title: t('commons.processSteps.bidSelected'),
      description: '',
      disabled: !hasPermission(user, PartnerOrganisationType.REGULAR),
    },
  ];

  const isMobile = useMobile();

  return !isMobile ? (
    <ul
      className={classnames(
        styles.desktopList,
        'd-flex align-items-center justify-content-between list-unstyled m-0 w-100',
        className,
      )}
      style={{ maxWidth: '54rem' }}
    >
      {steps.map((step, index) => (
        <DesktopStepItem
          key={step.link}
          step={step}
          number={index + 1}
          disabled={step.disabled}
          state={state}
          activeOnlyWhenExact={false}
          last={step === steps[steps.length - 1]}
        />
      ))}
    </ul>
  ) : (
    <ProgressBarMobile
      isOpen={isOpen}
      context={MenuContext}
      title={steps.map((step, index) => (
        <DesktopStepItem
          isTitle
          key={step.link}
          step={step}
          number={index + 1}
          disabled={step.disabled}
          state={state}
          count={steps.length}
        />
      ))}
    >
      <ul className="list-unstyled p-0 m-0">
        {steps.map((step, index) => (
          <DesktopStepItem
            key={step.link}
            step={step}
            number={index + 1}
            disabled={step.disabled}
            state={state}
            count={steps.length}
            hideStepsMenu={hideStepsMenu}
          />
        ))}
      </ul>
    </ProgressBarMobile>
  );
};

export default ProgressBar;
