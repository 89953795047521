import { getI18n } from 'react-i18next';

import { PRODUCT_TYPE } from '@krea/common/utils';

const PartnerOrganisationType = {
  REGULAR: 'REGULAR',
  LENDER_LEAD_PARTNER: 'LENDER_LEAD_PARTNER',
};

const RemoteIdentificationType = {
  BANK_ID: 'BANK_ID',
  SMS: 'SMS',
};

const hasPermission = (
  user: Record<string, Record<string, string>>,
  partnerOrganisationTypeToCheckFor: string,
  allowAdmins = true,
) => {
  let isAllowed = false;

  if (user?.isAdmin) {
    if (allowAdmins) {
      isAllowed = true;
    }
  } else if (partnerOrganisationTypeToCheckFor) {
    if (user?.partnerOrganisation?.type === partnerOrganisationTypeToCheckFor) {
      isAllowed = true;
    }
  }

  return isAllowed;
};

export { PartnerOrganisationType, RemoteIdentificationType, hasPermission };
