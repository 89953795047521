import classnames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import { settings } from '@krea/common/settings';
import { Text } from '@krea/common/shared-components/text';
import { useGetCachedCurrentLoanApplication } from '@krea/common/store/current-loan-application/hooks';

import TextActionLink from '../text/TextActionLink';

import styles from './ToastContact.module.scss';

export const ToastContact = ({ className }) => {
  const { t } = useTranslation();
  const { data: { assignedToAdminUser: person } = {} } =
    useGetCachedCurrentLoanApplication();

  const { firstName, mobilePhoneNumber, profileImage } = person || {};
  const phone = mobilePhoneNumber
    ? mobilePhoneNumber
    : settings.contacts.phone.value;
  const phoneLink = mobilePhoneNumber
    ? mobilePhoneNumber.replace(/[^+\d]/g, '')
    : settings.contacts.phone.link;

  return (
    <div className={classnames(styles.main, className)}>
      {profileImage?.lowResolutionUrl && (
        <div className={styles.profileImage}>
          <img
            src={profileImage.lowResolutionUrl}
            alt={firstName}
            className={styles.imgClass}
          />
        </div>
      )}
      <Text size="md">
        <Trans
          t={t}
          values={{ firstName: firstName, mobilePhoneNumber: phone }}
          components={{
            a: (
              <TextActionLink
                className="no-wrap"
                value={phone}
                htmlTag="span"
                onClick={() => window.open(`tel:${phoneLink}`, '_self')}
              />
            ),
            b: <Text size="md" htmlTag="span" className="font-weight-bold" />,
          }}
        >
          {person && firstName
            ? t(`commons.support.assignedToAdminUser`)
            : t(`commons.support.default`)}
        </Trans>
      </Text>
    </div>
  );
};
