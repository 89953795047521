import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { get } from 'lodash';

import SortIcon from '@krea/common/images/svg/sort.svg';
import Button from '@krea/common/shared-components/button';
import { Text } from '@krea/common/shared-components/text';

import { router } from 'router';

import styles from './Applications.module.scss';

const PRODUCT_TRANSLATION_KEYS = {
  COMPANY_LOAN: 'productNames.COMPANY_LOAN',
  FACTORING: 'productNames.FACTORING',
  REAL_ESTATE: 'productNames.REAL_ESTATE',
  LEASING: 'productNames.LEASING',
};

export const getColumns = (t) => [
  {
    columnName: 'id',
    label: 'ID',
    sortable: true,
    getValue: (item) => get(item, 'id'),
  },
  {
    columnName: 'applying_company__customer_company__name',
    label: t('listApplicationsView.columns.companyName'),
    sortable: true,
    getValue: (item) => get(item, 'applying_company.customer_company.name'),
  },
  {
    columnName: 'created_at',
    label: t('listApplicationsView.columns.createdAt'),
    sortable: true,
    noWrap: true,
    getValue: (item) =>
      dayjs(get(item, 'created_at')).format('MMM D YYYY HH:mm'),
  },
  {
    columnName: 'state',
    label: t('listApplicationsView.columns.state'),
    sortable: true,
    getValue: (item) => t(`loanApplication.states.${get(item, 'state')}`),
  },
  {
    columnName: 'product',
    label: t('listApplicationsView.columns.product'),
    noWrap: true,
    getValue: (item) => {
      const translationKey = PRODUCT_TRANSLATION_KEYS[get(item, 'product')];

      return t(translationKey);
    },
  },
  {
    columnName: 'requested_amount',
    label: t('listApplicationsView.columns.requested'),
    noWrap: true,
    getValue: (item) => {
      const value = get(item, 'requested_amount');

      if (value) {
        return parseFloat(value).toLocaleString('sv');
      }

      return '';
    },
  },
  {
    columnName: 'selected_amount',
    label: t('listApplicationsView.columns.selected'),
    noWrap: true,
    getValue: (item) => {
      const value = get(item, 'selected_amount');

      if (value) {
        return parseFloat(value).toLocaleString('sv');
      }

      return '';
    },
  },
  {
    columnName: 'readMore',
    label: t('listApplicationsView.columns.handle'),
    getValue: ({ id }) => {
      return (
        <a
          href={`/loan-applications/${id}`}
          title={`${t('listApplicationsView.applicationButton')}`}
        >
          <Button variant="outlined" size="sm">
            <div className="tw-text-nowrap">
              {t('listApplicationsView.applicationButton')}
            </div>
          </Button>
        </a>
      );
    },
  },
];

export const TableTH = ({
  columnName,
  sortable,
  sortColumn,
  setSorting,
  children,
}) => {
  return (
    <th className="align-middle px-3 py-5 border-bottom">
      <div
        className={classnames('d-inline-flex align-items-center', {
          [styles.sort]: sortable,
          'text-primary': columnName === sortColumn,
          'text-gray-dark': columnName !== sortColumn,
        })}
        {...(sortable && { onClick: () => setSorting(columnName) })}
      >
        <Text size="sm" className="font-weight-bold">
          {children}
        </Text>

        {sortable && (
          <div className={classnames(styles.sortIcon, 'ml-2 shrink-0')}>
            <SVG src={SortIcon} />
          </div>
        )}
      </div>
    </th>
  );
};

export const TableTD = ({ children, className }) => {
  return (
    <td className={classnames('align-middle px-3 py-4', className)}>
      <Text size="sm" className="font-weight-bold">
        {children}
      </Text>
    </td>
  );
};

export const getMobileTD = (item, { columnName, label, getValue }) => {
  const isIDColumn = columnName === 'id';
  const isReadMoreColumn = columnName === 'readMore';

  const size = isIDColumn ? 'md' : 'sm';
  const title = isIDColumn ? `#${item.id}` : label;

  const value = getValue(item);
  const companyNameValue = get(item, 'applying_company.customer_company.name');

  const getContent = () => {
    if (isIDColumn) {
      return companyNameValue;
    } else if (isReadMoreColumn) {
      return (
        <div className="d-flex border-top justify-content-end pt-4 mt-4">
          {value}
        </div>
      );
    }

    return value;
  };

  return (
    <div key={`mobile-${columnName}`}>
      <Text size={size} className="font-weight-bold">
        {title}
      </Text>

      <Text size={size} className="font-weight-bold">
        {getContent()}
      </Text>
    </div>
  );
};
