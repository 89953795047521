import classnames from 'classnames';
import { useShallow } from 'zustand/react/shallow';

import { useModalStore } from '@krea/common/store/modal/hooks';

import styles from '../dialog/Dialog.module.scss';

const Dialog = () => {
  const {
    modal,
    isOpen,
    closeOnBackgroundClick,
    onCloseSideEffects,
    closeModal,
  } = useModalStore(
    useShallow((state) => {
      const {
        modal,
        isOpen,
        closeOnBackgroundClick,
        onCloseSideEffects,
        closeModal,
      } = state;

      return {
        modal,
        isOpen,
        closeOnBackgroundClick,
        onCloseSideEffects,
        closeModal,
      };
    }),
  );

  const onCloseModal = () => {
    if (closeOnBackgroundClick) {
      closeModal();

      if (onCloseSideEffects) {
        onCloseSideEffects();
      }
    }
  };

  return (
    <div
      onClick={onCloseModal}
      className={classnames(
        styles.root,
        'd-flex align-items-center justify-content-center position-fixed p-6 h-100 w-100',
        { [styles.active]: isOpen },
      )}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={classnames(
          styles.container,
          'd-flex flex-column bg-white rounded w-100 mh-100 position-relative overflow-hidden',
        )}
      >
        {modal}
      </div>
    </div>
  );
};

export default Dialog;
