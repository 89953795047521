import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMobile } from '@krea/common/hooks';
import { settings } from '@krea/common/settings';

const Footer = () => {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const kreaOrganizationNumber = '5591732895';
  const kreditaOrganizationNumber = '3117389-5';

  return (
    !isMobile && (
      <section
        className="text-dark pt-7 pb-5"
        aria-label={'footer'}
        style={{ backgroundColor: '#FBF2FF' }}
      >
        <div style={{ marginLeft: '11%' }}>
          <p className="m-0 mb-3 mb-xl-1" style={{ fontSize: '14px' }}>
            <b>{t('applicationForm.common.questions')}</b>{' '}
            {t('applicationForm.common.callUs')}{' '}
            <a
              onClick={() =>
                window.open(`tel:${settings.contacts.phone.link}`, '_self')
              }
            >
              {settings.contacts.phone.value}
            </a>{' '}
            {t('applicationForm.common.or')}{' '}
            {t('applicationForm.common.writeTo')}{' '}
            <a
              style={{ textDecoration: 'none', color: 'black' }}
              href={`mailto:${settings.contacts.email}`}
            >
              {settings.contacts.email}
            </a>
          </p>
          {settings.countryCode === 'SE' && (
            <p style={{ color: '#6B6B6B', fontSize: '14px' }}>
              {t('applicationForm.common.kreaFullName')}{' '}
              <a
                style={{ color: '#6B6B6B' }}
                target="_blank"
                href="https://www.allabolag.se/5591732895/krea-technology-ab"
                rel="noreferrer"
              >
                ({kreaOrganizationNumber})
              </a>{' '}
              {t('applicationForm.common.kreaRegisteredBy')}
            </p>
          )}
          {settings.countryCode === 'FI' && (
            <p style={{ color: '#6B6B6B', fontSize: '14px' }}>
              {t('applicationForm.common.kreditaFullName')}{' '}
              <a
                style={{ color: '#6B6B6B' }}
                target="_blank"
                href="https://www.finder.fi/Rahoitus+ja+luottotoiminta/Kredita+Oy/Espoo/yhteystiedot/3991551"
                rel="noreferrer"
              >
                ({kreditaOrganizationNumber})
              </a>{' '}
              {t('applicationForm.common.kreditaIsPartOf')}{' '}
              {t('applicationForm.common.kreaFullName')}{' '}
              <a style={{ color: '#6B6B6B' }}>({kreaOrganizationNumber})</a>
              {', '}
              {t('applicationForm.common.kreditaRegisteredBy')}
            </p>
          )}
        </div>
      </section>
    )
  );
};

export default Footer;
