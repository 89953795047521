import loadable from '@loadable/component';

export const FieldRequestsFormsImports = {
  mobilePhoneNumber: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/PhoneNumberForm'
      ),
  ),
  guarantors: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/GuarantorsForm'
      ),
  ),
  accountingData: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/AccountingDataForm'
      ),
  ),
  bankingData: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/BankingDataForm'
      ),
  ),
  property: loadable(
    () =>
      import('@krea/common/shared-components/field-request/forms/PropertyForm'),
  ),
  propertyAdditionalData: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/PropertyAdditionalDataForm'
      ),
  ),
  additionalSecurities: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/AdditionalSecuritiesForm'
      ),
  ),
  reason: loadable(
    () =>
      import('@krea/common/shared-components/field-request/forms/ReasonForm'),
  ),
  reasonExplanation: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/ReasonExplanationForm'
      ),
  ),
  unpaidInvoicesAdditionalData: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/UnpaidInvoicesAdditionalDataForm'
      ),
  ),
  unpaidInvoices: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/UnpaidInvoicesForm'
      ),
  ),
  selfEstimatedRevenue: loadable(
    () =>
      import('@krea/common/shared-components/field-request/forms/AmountForm'),
  ),
  selfEstimatedHistoricRevenue: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/SelfEstimateHistoricRevenueForm'
      ),
  ),
  companyMortgage: loadable(
    () =>
      import('@krea/common/shared-components/field-request/forms/AmountForm'),
  ),
  budget: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/FileUploadForm'
      ),
  ),
  businessPlan: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/FileUploadForm'
      ),
  ),
  requestedAmount: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/RequestedAmountForm'
      ),
  ),
  repaymentTime: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/RepaymentTimeForm'
      ),
  ),
  suggestedAmount: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/SuggestedAmountForm'
      ),
  ),
  pawnedInvoices: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/PawnedInvoicesForm'
      ),
  ),
  groupedRealEstateCollateral: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/GroupedFieldRequestForm'
      ),
  ),
  groupedRealEstateRepayment: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/GroupedFieldRequestForm'
      ),
  ),
  refinancing: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/RefinancingForm'
      ),
  ),
  propertyCashFlow: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/FileUploadForm'
      ),
  ),
  constructionCompletedRepayment: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/ConstructionCompletedRepaymentForm'
      ),
  ),
  shareMortgage: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/ShareMortgageForm'
      ),
  ),
  parentCompanyGuarantee: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/FileUploadForm'
      ),
  ),
  constructionEstimate: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/FileUploadForm'
      ),
  ),
  buildingPermit: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/FileUploadForm'
      ),
  ),
  propertyOwner: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/PropertyOwnerForm'
      ),
  ),
  refinancingAmounts: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/RefinancingAmountForm'
      ),
  ),
  leasing: loadable(
    () =>
      import('@krea/common/shared-components/field-request/forms/LeasingForm'),
  ),
  leasingAdditionalData: loadable(
    () =>
      import(
        '@krea/common/shared-components/field-request/forms/LeasingAdditionalDataForm'
      ),
  ),
};
