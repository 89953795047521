import classnames from 'classnames';

import MenuLink from './MenuLink';

const Menu = ({ className, list, forMobile, onCloseMenu }) => {
  return (
    <ul
      className={classnames(
        'd-flex flex-column flex-xl-row align-items-center list-unstyled visible',
        className,
      )}
    >
      {list?.map((item, index) => (
        <li key={index} className="mx-5 py-xl-3">
          <MenuLink
            title={item.title}
            href={item.href}
            forMobile={forMobile}
            onCloseMenu={onCloseMenu}
            disabled={item.disabled}
          />
        </li>
      ))}
    </ul>
  );
};

export default Menu;
