import sweValidation from 'swe-validation';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import relativeTime from 'dayjs/esm/plugin/relativeTime';
import { map } from 'lodash';
import { getI18n } from 'react-i18next';

import ImportantEuLoanIcon from '../images/svg/important-info/eu-loan-flag.svg';
import ImportantNoFeeIcon from '../images/svg/important-info/no-fee.svg';
import ImportantNoAdditionsRequiredIcon from '../images/svg/important-info/no-additions-required.svg';
import ImportantReducedGuarantorCommitmentIcon from '../images/svg/important-info/reduced-guarantor-commitment.svg';
import ImportantNoGuarantorRequiredIcon from '../images/svg/important-info/no-guarantor-required.svg';
import ImportantGoodContractIcon from '../images/svg/important-info/good-contract.svg';

declare global {
  // Adding the MessageBirdChatWidget to the window object
  interface Window {
    MessageBirdChatWidget?: {
      isOpen: boolean;
      toggleChat: () => void;
    };
  }
}

dayjs.extend(relativeTime as any);

const APPLICATION_NAME = {
  CUSTOMER_WEB: 'CUSTOMER_WEB',
  PARTNER_WEB: 'PARTNER_WEB',
};

const BANKID_FLOW_STATES = {
  PENDING: 'pending',
  FAILED: 'failed',
  COMPLETE: 'complete',
};

const COMPANY_TYPE = {
  LIMITED_COMPANY: 'LIMITED_COMPANY',
  PARTNERSHIP: 'PARTNERSHIP',
  SOLE_TRADER: 'SOLE_TRADER',
};

const CUSTOMER_RATES = {
  FIRST_MONTH_RATE: 'firstMonthInterestRate',
  MONTHLY_INTEREST_RATE: 'comparableMonthlyInterestRate',
  YEARLY_INTEREST_RATE: 'comparableYearlyInterestRate',
};

const HINT_CODE_STATES = {
  ERROR: 'error',
  USER_CANCEL: 'userCancel',
  OUTSTANDING_TRANSACTION: 'outstandingTransaction',
  USER_SIGN: 'userSign',
  START_FAILED: 'startFailed',
};

const IMPORTANT_INFO_TYPE = {
  NO_ADDITIONS_REQUIRED: 'NO_ADDITIONS_REQUIRED',
  REDUCED_GUARANTOR_COMMITMENT: 'REDUCED_GUARANTOR_COMMITMENT',
  NO_GUARANTOR_REQUIRED: 'NO_GUARANTOR_REQUIRED',
  FREE_EARLY_PAYMENT: 'FREE_EARLY_PAYMENT',
  NO_LEASING_DOWN_PAYMENT: 'NO_LEASING_DOWN_PAYMENT',
  NO_LIMIT_FEE: 'NO_LIMIT_FEE',
  NO_SETUP_FEE: 'NO_SETUP_FEE',
  EU_LOAN: 'EU_LOAN',
  NO_WITHDRAWAL_FEE: 'NO_WITHDRAWAL_FEE',
  NO_RECOURSE: 'NO_RECOURSE',
  NO_AMORTIZATION: 'NO_AMORTIZATION',
};

const isUrlRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

const RESOURCE_TYPE = {
  GUARANTOR: 'GUARANTOR',
  APPLYING_COMPANY: 'APPLYING_COMPANY',
};

const amountValuesList = (values: number[], ratio: number) => {
  return map(values, (n) => n / ratio);
};

const FormatNumber = (value: number | bigint) => {
  if (value)
    return new Intl.NumberFormat('sv', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);

  return '';
};

const numberFromFormat = (value: string) => {
  return parseInt(value.replace(/\s+/g, ''), 10);
};

const getBenefits = (benefits: any[]) => {
  if (!benefits) return [];

  return benefits.map((benefit) => {
    const benefitToMap = {
      ...benefit,
      icon: null,
    };

    switch (benefit.type) {
      case IMPORTANT_INFO_TYPE.NO_ADDITIONS_REQUIRED:
        benefitToMap.icon = ImportantNoAdditionsRequiredIcon;
        break;

      case IMPORTANT_INFO_TYPE.REDUCED_GUARANTOR_COMMITMENT:
        benefitToMap.icon = ImportantReducedGuarantorCommitmentIcon;
        break;

      case IMPORTANT_INFO_TYPE.NO_GUARANTOR_REQUIRED:
        benefitToMap.icon = ImportantNoGuarantorRequiredIcon;
        break;

      case IMPORTANT_INFO_TYPE.FREE_EARLY_PAYMENT:
        benefitToMap.icon = ImportantNoFeeIcon;
        break;

      case IMPORTANT_INFO_TYPE.NO_LEASING_DOWN_PAYMENT:
        benefitToMap.icon = ImportantNoFeeIcon;
        break;

      case IMPORTANT_INFO_TYPE.NO_WITHDRAWAL_FEE:
        benefitToMap.icon = ImportantNoFeeIcon;
        break;

      case IMPORTANT_INFO_TYPE.EU_LOAN:
        benefitToMap.icon = ImportantEuLoanIcon;
        break;

      case IMPORTANT_INFO_TYPE.NO_RECOURSE:
        benefitToMap.icon = ImportantGoodContractIcon;
        break;

      case IMPORTANT_INFO_TYPE.NO_AMORTIZATION:
        benefitToMap.icon = ImportantNoFeeIcon;
        break;

      default:
    }

    return benefitToMap;
  });
};

const isIOS = () => !!window.navigator.userAgent.match(/iPhone|iPod|iPad/i);

const isMobile = () =>
  window.navigator.userAgent.match(
    /Android|webOS|iPhone|iPod|iPad|Blackberry/i,
  );

const isValidEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
};

const isValidSwedishPersonalNumber = (ssn: number) => {
  return sweValidation.ssn(ssn).isValid;
};

const isValidUrl = (urlString: string) => {
  return !!urlString && urlString.match(isUrlRegex);
};

const NormalizeRequestedPaymentTimeMonths = (months: number) => {
  const { t } = getI18n();

  const numberOfYears = Math.floor(months / 12);
  const numberOfMonths = months % 12;

  const monthPostfix = `${numberOfMonths} ${numberOfMonths === 1 ? t('commons.month') : t('commons.month_plural')}`;

  const monthPostfixWithYear = `${numberOfMonths} ${
    numberOfMonths === 1 ? t('commons.months_short') : t('commons.months_short')
  }`;

  const yearPrefix = `${numberOfYears} ${t('commons.year', { count: numberOfYears })}`;

  if (numberOfYears && !numberOfMonths) {
    return yearPrefix;
  }

  if (numberOfYears && numberOfMonths) {
    return `${yearPrefix} ${t('commons.and')} ${monthPostfixWithYear}`;
  }

  return monthPostfix;
};

const openSupport = () => {
  window.MessageBirdChatWidget &&
    !window.MessageBirdChatWidget.isOpen &&
    window.MessageBirdChatWidget.toggleChat();
};

const ScrollToElement = (
  element: string,
  offset: null | undefined,
  callback: (() => void) | undefined,
) => {
  let offsetValue;

  if (offset != null) {
    offsetValue = offset;
  } else {
    offsetValue = -140;
  }

  import('react-scroll')
    .then((module) => {
      const scroller = module.scroller;
      const Events = module.Events;

      scroller.scrollTo(element, {
        duration: 1000,
        delay: 100,
        smooth: true,
        offset: offsetValue,
      });

      if (callback) {
        Events.scrollEvent.register('end', () => {
          callback();
          Events.scrollEvent.remove('end');
        });
      }
    })
    .catch((error) => {
      console.error(error.message, 'Unable to load react-scroll module');
    });
};

const ScrollToTop = () => {
  import('react-scroll')
    .then((module) => {
      const Scroll = module.default;
      var scroll = Scroll.animateScroll;

      scroll.scrollToTop({
        duration: 1000,
        delay: 0,
        smooth: true,
      });
    })
    .catch((error) => {
      console.error(error.message, 'Unable to load react-scroll module');
    });
};

const termsOfServiceLink = (
  country: string,
  websiteUrl: string,
  lang: string,
) => {
  const links_SE = (lang: string) => {
    switch (lang) {
      case 'sv':
        return 'terms-of-service';
      case 'en':
        return 'terms-of-service';
      default:
        return null;
    }
  };

  const links_FI = (lang: string) => {
    switch (lang) {
      case 'fi':
        return 'kayttoehdot';
      case 'sv':
        return 'kayttoehdot';
      case 'en':
        return 'kayttoehdot';
      default:
        return null;
    }
  };

  switch (country) {
    case 'SE':
      return `${websiteUrl}/${links_SE(lang)}`;
    case 'FI':
      return `${websiteUrl}/${links_FI(lang)}`;
    default:
      return `${websiteUrl}/terms-of-service`;
  }
};

const privacyPolicyLink = (
  country: string,
  websiteUrl: string,
  lang: string,
) => {
  const links_SE = (lang: string) => {
    switch (lang) {
      case 'sv':
        return 'integritetspolicy';
      case 'en':
        return 'privacy-policy';
      default:
        return null;
    }
  };

  const links_FI = (lang: string) => {
    switch (lang) {
      case 'fi':
        return 'tietosuojakaytanto';
      case 'sv':
        return 'tietosuojakaytanto';
      case 'en':
        return 'tietosuojakaytanto';
      default:
        return null;
    }
  };

  switch (country) {
    case 'SE':
      return `${websiteUrl}/${links_SE(lang)}`;
    case 'FI':
      return `${websiteUrl}/${links_FI(lang)}`;
    default:
      return `${websiteUrl}/privacy-policy`;
  }
};

export {
  APPLICATION_NAME,
  BANKID_FLOW_STATES,
  COMPANY_TYPE,
  CUSTOMER_RATES,
  HINT_CODE_STATES,
  IMPORTANT_INFO_TYPE,
  isUrlRegex,
  RESOURCE_TYPE,
  amountValuesList,
  FormatNumber,
  numberFromFormat,
  getBenefits,
  isIOS,
  isMobile,
  isValidEmail,
  isValidSwedishPersonalNumber,
  isValidUrl,
  NormalizeRequestedPaymentTimeMonths,
  openSupport,
  ScrollToElement,
  ScrollToTop,
  termsOfServiceLink,
  privacyPolicyLink,
};

export * from './country';
export * from './fieldRequests';
export * from './loanApplications';
export * from './bids';
export * from './testConstants';
