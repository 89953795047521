import { useTranslation } from 'react-i18next';

import { useMobile } from '@krea/common/hooks';
import ChatSVG from '@krea/common/images/svg/contacts/new/chat.svg';
import EmailSVG from '@krea/common/images/svg/contacts/new/email.svg';
import PhoneSVG from '@krea/common/images/svg/contacts/new/phone.svg';
import { settings } from '@krea/common/settings';
import Button from '@krea/common/shared-components/button';
import ResponsiveDialog from '@krea/common/shared-components/responsive-dialog';
import { Toast } from '@krea/common/shared-components/toast/Toast';
import { ToastAdditionalContent } from '@krea/common/shared-components/toast/ToastAdditionalContent';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import { COUNTRY_CODE, openSupport } from '@krea/common/utils';

const CustomerServiceContactResponsiveDialog = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { clearFormErrors } = useLoanApplicationFormStore((state) => ({
    clearFormErrors: state.clearFormErrors,
  }));

  const getCustomerServiceOpenHours = () => {
    const date = new Date();
    const day = date.getDay();
    const hour = date.getHours();

    let openNow = false;
    let openHours = '';

    if (settings.countryCode === COUNTRY_CODE.SE) {
      // if (day > 0 && day < 5) {
      //   // Monday to Thursday
      //   openHours = '(09 - 18)';

      //   if (hour >= 9 && hour < 18) {
      //     openNow = true;
      //   }
      // } else if (day === 5) {
      //   // Friday
      //   openHours = '(09 - 17)';

      //   if (hour >= 9 && hour < 17) {
      //     openNow = true;
      //   }
      // }

      // Summer opening hours (June 1st - August 16th)
      if (day > 0 && day < 6) {
        // Monday to Friday
        openHours = '(09 - 17)';

        if (hour >= 9 && hour < 17) {
          openNow = true;
        }
      }
    } else if (settings.countryCode === COUNTRY_CODE.FI) {
      if (day > 0 && day < 6) {
        // Monday to Friday
        openHours = '(10 - 16)';

        if (hour >= 10 && hour < 16) {
          openNow = true;
        }
      }
    }

    const openText = openNow
      ? t('commons.contactCustomerServiceDialog.customerServiceOpen') +
        openHours
      : t('commons.contactCustomerServiceDialog.customerServiceClosed');

    return (
      <div style={isMobile ? { marginLeft: '30px' } : {}} className="d-flex">
        <div
          style={{
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            boxShadow: '0px 3.5px 3.5px #ced4da',
            marginTop: '5px',
            marginRight: '10px',
            backgroundColor: openNow ? '#00E8D1' : '#FF8B00',
          }}
        ></div>
        <p className="m-0 p-0">{openText}</p>
      </div>
    );
  };

  return (
    <ResponsiveDialog
      open={open}
      setOpen={setOpen}
      title={t('commons.contactCustomerServiceDialog.contactCustomerService')}
    >
      <Toast
        innerText={{
          title: t('commons.contactCustomerServiceDialog.needHelp'),
          subtitle: t('commons.contactCustomerServiceDialog.contactUs'),
        }}
      />
      <ToastAdditionalContent
        innerText={{
          title: (
            <a href={`tel:${settings.contacts.phone.link}`}>
              {settings.contacts.phone.value}
            </a>
          ),
        }}
        icon={PhoneSVG}
        additional={getCustomerServiceOpenHours()}
      />

      <Toast
        innerText={{
          title: (
            <a href={`mailto:${settings.contacts.email}`}>
              {settings.contacts.email}
            </a>
          ),
        }}
        icon={EmailSVG}
      />

      <Toast
        className="w-100"
        innerText={{
          title: (
            <a
              onClick={() => {
                setOpen(false);
                openSupport();
              }}
              className="text-primary"
            >
              {t('commons.footer.chatCta')}
            </a>
          ),
        }}
        icon={ChatSVG}
      />
      <div className="xl:tw-my-3">
        <Button
          size="lg"
          variant="contained"
          className="tw-w-full"
          onClick={() => {
            setOpen(false);
            clearFormErrors();
          }}
        >
          {'Ok'}
        </Button>
      </div>
    </ResponsiveDialog>
  );
};

export default CustomerServiceContactResponsiveDialog;
