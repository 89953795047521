import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { CURRENT_LOAN_APPLICATION_STORE_KEY } from '@krea/common/store/queryClient';

import { markBidsRejectedByCustomer } from 'store/current-loan-application/api';

export const useMarkBidsRejectedByCustomer = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ loanApplicationID }) =>
      markBidsRejectedByCustomer(loanApplicationID),
    onSuccess: async (_, { loanApplicationID }) => {
      await queryClient.invalidateQueries({
        queryKey: [CURRENT_LOAN_APPLICATION_STORE_KEY, loanApplicationID],
      });
      navigate(`/loan-applications/${loanApplicationID}`);
    },
    onError: (err) => {
      console.error('Failed marking no bids received: err', err);
    },
  });
};
