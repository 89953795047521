import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import React, { useRef } from 'react';

import ArrowDownSvg from '@krea/common/images/svg/arrow-down-colorless.svg';
import { Error } from '@krea/common/shared-components/fields/error';
import { Label } from '@krea/common/shared-components/fields/label';

import styles from './Select.module.scss';

export const Select = ({
  className,
  label,
  id,
  error,
  value,
  children,
  disabled,
  size = 'md',
  ...rest
}: {
  className?: string;
  label?: React.ReactNode;
  id?: string;
  error?: string | null;
  value?: string | number;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  children?: React.ReactNode;
  disabled?: boolean;
  [x: string]: any;
}) => {
  const selectEl = useRef(null);

  return (
    <div className={classnames(styles.root, className)}>
      {label && (
        <Label
          className="mb-2"
          htmlFor={`${id}`}
          onClick={() =>
            (selectEl.current as unknown as HTMLLabelElement).focus()
          }
        >
          {label}
        </Label>
      )}

      <div className="position-relative">
        <select
          {...rest}
          id={id}
          ref={selectEl}
          value={value}
          disabled={disabled}
          className={classnames(
            styles.field,
            styles[size],
            'd-flex w-100 text-truncate rounded font-weight-bold py-0 pl-4',
            {
              [styles.initial]: !value,
              [styles.error]: error,
            },
          )}
        >
          {children}
        </select>

        <div
          className={classnames(
            styles.arrow,
            'd-flex position-absolute justify-content-center align-items-center',
            {
              [styles.arrowError]: error,
            },
          )}
        >
          <SVG src={ArrowDownSvg} />
        </div>
      </div>

      {error && <Error className="mt-1">{error}</Error>}
    </div>
  );
};
