import { create } from 'zustand';

const initialState = {
  orderRef: null,
  autoStartToken: null,
  qrCode: null,
};

export const useBankIDStore = create((set) => ({
  ...initialState,

  // setters
  setOrderRef: (orderRef) => set((state) => ({ ...state, orderRef })),
  setAutoStartToken: (autoStartToken) =>
    set((state) => ({ ...state, autoStartToken })),

  // Sets the animated QR code
  setQrCode: (qrCode) => set((state) => ({ ...state, qrCode })),

  // reset store
  resetBankIDStore: () => set(initialState),
}));
