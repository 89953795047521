import i18n from 'i18next';
import { cin } from 'swe-validation';
import * as Yup from 'yup';

import { settings } from '../settings';
import { isValidSwedishPersonalNumber, COUNTRY_CODE } from '../utils';

export const OrganisationValidation = () => {
  return Yup.object().shape({
    organisationNumber: Yup.string()
      .required(
        `${i18n.t('applicationForm.commons.companyNameErrors.orgNumberRequired')}`,
      )
      .test('organisationNumber', function (value) {
        const { path, createError } = this;

        let isValid = false;

        if (settings.countryCode === COUNTRY_CODE.SE) {
          isValid =
            cin(parseFloat(value)).isValid ||
            isValidSwedishPersonalNumber(parseInt(value));
        } else if (settings.countryCode === COUNTRY_CODE.FI) {
          isValid = value ? value.length === 9 && value[7] === '-' : false;
        }

        return (
          isValid ||
          createError({
            path,
            message: `${i18n.t(
              'applicationForm.commons.companyNameErrors.orgNumberInvalid',
            )}`,
          })
        );
      }),
    name: Yup.string().required(
      `${i18n.t('applicationForm.commons.companyNameErrors.orgNameRequired')}`,
    ),
  });
};
