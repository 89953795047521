import { useInfiniteQuery } from '@tanstack/react-query';

import { settings } from '@krea/common/settings';
import { LOAN_APPLICATIONS_STORE_KEY } from '@krea/common/store/queryClient';

import { fetchLoanApplications } from 'store/loan-applications/api';

export const useGetLoanApplications = ({ filter, sortParam, searchTerm }) => {
  const sort = `${sortParam.column}:${sortParam.direction}`;

  return useInfiniteQuery({
    queryKey: [LOAN_APPLICATIONS_STORE_KEY, filter, sort, searchTerm],
    queryFn: async ({
      pageParam = {
        state: filter,
        sort,
        search: searchTerm,
        limit: settings.defaultListLimit,
        offset: 0,
      },
    }) => {
      const response = await fetchLoanApplications(pageParam);

      return { ...response?.data, filter, sort, searchTerm };
    },
    getNextPageParam: (lastPage, allPages) => {
      const offset = allPages.length * settings.defaultListLimit;

      if (offset < lastPage.count) {
        const { filter, sort, searchTerm } = lastPage;

        const nextPageParam = {
          state: filter,
          sort,
          searchTerm,
          limit: settings.defaultListLimit,
          offset,
        };

        return nextPageParam;
      }
    },
    enabled: !!localStorage.getItem('accessToken'),
  });
};
