import classnames from 'classnames';
import React from 'react';

import { Text } from '../../text';

export const Error = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Text
      size="sm"
      className={classnames(
        'tw-text-red-500 font-bold leading-[1.25rem]',
        className,
      )}
    >
      {children}
    </Text>
  );
};
