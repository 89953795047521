import loadable from '@loadable/component';
import { useTranslation } from 'react-i18next';

import Button from '@krea/common/shared-components/button';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@krea/common/shared-components/dialog';
import { useModalStore } from '@krea/common/store/modal/hooks';

const BidDetails = loadable(
  () => import('@krea/common/shared-components/bid-details/BidDetails'),
);
const FactoringBidDetails = loadable(
  () =>
    import(
      '@krea/common/shared-components/bid-dashboards/factoring-bids/FactoringBidDetails'
    ),
);
const LeasingBidDetails = loadable(
  () =>
    import(
      '@krea/common/shared-components/bid-dashboards/leasing-bids/LeasingBidDetails'
    ),
);
const RevolvingCreditBidDetails = loadable(
  () =>
    import('../bid-dashboards/revolving-credit-bids/RevolvingCreditBidDetails'),
);

export const SelectedBidCurrentModal = ({ selectedLenderResponse }) => {
  const { t } = useTranslation();
  const closeModal = useModalStore(({ closeModal }) => closeModal);

  const {
    bid,
    invoicePurchaseBid,
    realEstateBid,
    leasingBid,
    revolvingCreditBid,
  } = selectedLenderResponse;
  const lenderCompany =
    selectedLenderResponse?.lenderApplication?.lenderCompany;

  // realEstateBid uses the same "DetailsComponent" and "bidTypeTranslationKey" as a Bid/CompanyLoanBid does below
  const DetailsComponent = invoicePurchaseBid
    ? FactoringBidDetails
    : leasingBid
      ? LeasingBidDetails
      : revolvingCreditBid
        ? RevolvingCreditBidDetails
        : BidDetails;

  let bidTypeTranslationKey;

  switch (true) {
    case invoicePurchaseBid !== null:
      bidTypeTranslationKey = 'factoringBidSelected';
      break;
    case leasingBid !== null:
      bidTypeTranslationKey = 'leasingBidSelected';
      break;
    case revolvingCreditBid !== null:
      bidTypeTranslationKey = 'revolvingCreditBidSelected';
      break;
    default:
      bidTypeTranslationKey = 'bidSelected';
  }

  return (
    <>
      <DialogTitle
        title={t(`${bidTypeTranslationKey}.bidDetailsLabel`, {
          lenderCompanyName: lenderCompany && lenderCompany.name,
        })}
      />

      <DialogContent>
        <DetailsComponent
          bid={
            invoicePurchaseBid ||
            realEstateBid ||
            leasingBid ||
            revolvingCreditBid ||
            bid
          }
          className="p-6"
          isPostSelect={true}
        />
      </DialogContent>

      <DialogActions className="justify-content-center">
        <Button
          variant="outlined"
          size="sm"
          className="w-100"
          style={{ maxWidth: '8.75rem' }}
          onClick={() => closeModal()}
        >
          OK
        </Button>
      </DialogActions>
    </>
  );
};
