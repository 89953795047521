import { useQuery } from '@tanstack/react-query';

import { CURRENT_LOAN_APPLICATION_STORE_KEY } from '@krea/common/store/queryClient';

import { fetchCurrentLoanApplicationData } from 'store/current-loan-application/api';

export const useGetCurrentLoanApplication = (loanApplicationID) => {
  return useQuery({
    queryKey: [CURRENT_LOAN_APPLICATION_STORE_KEY, loanApplicationID],
    queryFn: async () => {
      try {
        const response =
          await fetchCurrentLoanApplicationData(loanApplicationID);

        return response?.loanApplication || {};
      } catch (err) {
        console.error(
          'Failed to fetch current loan application data: err',
          err,
        );

        return {};
      }
    },
    enabled: !!loanApplicationID,
  });
};
