export const TEST_IDS = {
  common: {
    forms: {
      loanApplication: {
        heading: 'form-heading',
        getStartedButton: 'get-started-button',
        hasOngoingApplication: 'has-ongoing-application',
      },
      reasonForm: {
        container: 'reason-form-container',
        reasonValue: (value) => `reason-value-${value}`,
      },
      leasingForm: {
        amountInput: 'leasing-amount-input',
        leasingObjectValue: (value) => `leasing-object-value-${value}`,
      },
      realEstateForm: {
        reasonValue: (value) => `real-estate-reason-value-${value}`,
        propertyUnitDesignationInput: 'property-unit-designation-input',
      },
      factoringForm: {
        invoiceAmountInput: 'invoice-financing-amount-input',
        invoiceNumberInput: 'invoice-financing-number-input',
        invoiceDueDate: (value) => `invoice-financing-due-date-value-${value}`,
        invoiceDescriptionInput: 'invoice-financing-description-input',
      },
      organizationNumberInput: 'organisation-number-input',
      phoneInput: 'phone-input',
      emailInput: 'email-input',
      companyResult: (index) => `company-result-${index}`,
      loanAmountInput: 'loan-amount-input',
      loanAmountSlider: 'loan-amount-slider',
      loanAmountSliderOrInputSwitch: 'loan-amount-slider-or-input-switch',
      continueButton: 'loan-form-continue-button',
    },
    bankId: {
      mobileBankIdButton: 'mobile-bank-id-button',
      mobileBankIdTestFlowButton: 'mobile-bank-id-test-flow-button',
      ssnInput: 'bank-id-ssn-input',
      bankIdContinueButton: 'bank-id-continue-button',
    },
  },
};
