import loadable from '@loadable/component';
import { isEmpty } from 'lodash';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, Outlet, useLocation } from 'react-router-dom';

import { settings } from '@krea/common/settings';
import { Dialog } from '@krea/common/shared-components/dialog';
import Preloader from '@krea/common/shared-components/preloader';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';

import LoanApplicationRouter from 'components/LoanApplicationRouter';
import SettingsRouter from 'components/SettingsRouter';
import Layout from 'components/app/layout';
import Applications from 'components/applications';
import { PartnerLoginPage } from 'pages/login';
import { RegisterPage } from 'pages/register';

import ScrollToTop from './ScrollToTop';
import SentryBoundary from './sentry';

const InvoiceSupportPage = loadable(() => import('./pages/invoices'));

const ApplicationFormPage = loadable(async () => {
  const { ApplicationFormPage } = await import('./pages/application-form-page');

  return ApplicationFormPage;
});

const PrivateRoute = loadable(() => import('./private-route'));
const NotFound = loadable(() => import('./pages/not-found'));

const PreloadApplicationsForms = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // preload applications forms
    if (pathname.startsWith('application')) {
      ApplicationFormPage.preload();
    }
  }, [pathname]);

  return null;
};

function App() {
  const { appTitle } = settings;
  const { t } = useTranslation();
  const { data: user = {}, isLoading, isFetching } = useGetUserData();

  const { isAdmin, partnerOrganisation } = user;
  const isPartnerOrgActive = partnerOrganisation?.isActive;

  const menu = [
    {
      title: t('navbar.showApplications'),
      href: '/loan-applications',
    },
    {
      title: t('navbar.newApplication'),
      href: '/application',
      disabled: !isPartnerOrgActive && !isAdmin,
    },
    {
      title: t('navbar.invoiceDocumentation'),
      href: '/invoices',
      disabled: !isPartnerOrgActive && !isAdmin,
    },
  ];

  if (isLoading && isFetching) {
    return (
      <Preloader
        className="text-primary position-fixed w-100 h-100 bg-white"
        size="xl"
      />
    );
  }

  return (
    <SentryBoundary contactPersonId={null}>
      <PreloadApplicationsForms />
      {/* @ts-ignore */}
      <Layout menu={menu}>
        <Suspense fallback={null}>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute
                  isAllowed={!isEmpty(user)}
                  redirectOnPermissionDenied="/login"
                >
                  <Navigate replace={true} to="loan-applications" />
                </PrivateRoute>
              }
            />
            <Route
              path="application"
              element={
                <PrivateRoute
                  isAllowed={!isEmpty(user)}
                  redirectOnPermissionDenied="/login"
                >
                  <Helmet
                    title={`${appTitle} Partner - ${t('commons.app.title.loanAppForm')}`}
                  />
                  <ApplicationFormPage />
                </PrivateRoute>
              }
            />
            <Route
              path="new/*"
              element={<Navigate replace={true} to="/application" />}
            />

            <Route
              path="loan-applications/*"
              element={
                <PrivateRoute
                  isAllowed={!isEmpty(user)}
                  redirectOnPermissionDenied="/login"
                >
                  <Outlet />
                </PrivateRoute>
              }
            >
              <Route
                index
                element={
                  <>
                    <Helmet
                      title={`${appTitle} Partner - ${t('navbar.showApplications')}`}
                    />
                    <Applications />
                  </>
                }
              />

              <Route
                path=":loanApplicationID/*"
                element={<LoanApplicationRouter />}
              />
            </Route>

            <Route
              path="invoices"
              element={
                <PrivateRoute
                  isAllowed={isPartnerOrgActive || isAdmin}
                  redirectOnPermissionDenied="/login"
                >
                  <Helmet
                    title={`${appTitle} Partner - ${t('navbar.invoiceDocumentation')}`}
                  />
                  <InvoiceSupportPage />
                </PrivateRoute>
              }
            />

            <Route
              path="settings/*"
              element={
                <PrivateRoute
                  isAllowed={isPartnerOrgActive || isAdmin}
                  redirectOnPermissionDenied="/login"
                >
                  <SettingsRouter />
                </PrivateRoute>
              }
            />

            <Route
              path="login"
              element={
                <PrivateRoute
                  isAllowed={isEmpty(user)}
                  redirectOnPermissionDenied="/"
                >
                  <Helmet title={`${appTitle} Partner - ${t('menu.login')}`} />
                  <PartnerLoginPage />
                </PrivateRoute>
              }
            />

            <Route
              path="register"
              element={
                <PrivateRoute
                  isAllowed={isEmpty(user)}
                  redirectOnPermissionDenied="/"
                >
                  <Helmet
                    title={`${appTitle} Partner - ${t('register.heading')}`}
                  />
                  <RegisterPage />
                </PrivateRoute>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>

        <ScrollToTop />

        <Dialog />
      </Layout>
    </SentryBoundary>
  );
}

export default App;
