import { create } from 'zustand';

const initialState = {
  modal: null,
  isOpen: false,
  closeOnBackgroundClick: true,
  onCloseSideEffects: null,
  isSupportTabOpen: false,
};

export const useModalStore = create((set) => ({
  ...initialState,
  openModal: ({
    modal,
    closeOnBackgroundClick,
    onCloseSideEffects,
    isSupportTabOpen,
  }) => {
    return set({
      modal,
      isOpen: true,
      closeOnBackgroundClick,
      onCloseSideEffects,
      isSupportTabOpen,
    });
  },
  closeModal: () => set(initialState),
}));
