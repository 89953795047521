import classnames from 'classnames';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';

import UserSVG from '@krea/common/images/svg/arrow-down-colorless.svg';
import Container from '@krea/common/shared-components/container';

import styles from './ProgressBarMobile.module.scss';

import SVG from 'react-inlinesvg';

const motionSteps = {
  hidden: {
    opacity: 0,
    height: 0,
    borderTop: '0px solid rgba(255, 255, 255, 0)',
  },
  visible: {
    opacity: 1,
    height: 'auto',
    borderTop: '1px solid #ebe8ef',
  },
};

const motionArrow = {
  hidden: {
    transform: 'rotateZ(0deg)',
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
  visible: {
    transform: 'rotateZ(180deg)',
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
};

const ProgressBarMobile = ({ title, children, isOpen, context }) => {
  const thisContext = useContext(context);

  return (
    <>
      <div
        className={classnames(styles.title, 'w-100 grow-0 shrink-0')}
        onClick={thisContext.showSteps}
      >
        <Container className="h-100">
          <div className="d-flex justify-content-between align-items-center h-100">
            {title}

            <motion.div
              variants={motionArrow}
              initial="hidden"
              animate={isOpen ? 'visible' : 'hidden'}
              className={classnames(
                styles.icon,
                'shrink-0 text-secondary ml-2 p-3 mr-n2',
              )}
            >
              <div className="position-relative">
                <SVG className="position-absolute w-100 h-auto" src={UserSVG} />
              </div>
            </motion.div>
          </div>
        </Container>
      </div>

      <motion.div
        variants={motionSteps}
        initial="hidden"
        animate={isOpen ? 'visible' : 'hidden'}
        transition={{
          default: { duration: 0.4, ease: 'easeOut' },
        }}
        className="grow-1 shrink-1 overflow-hidden"
      >
        <Container>
          <div className="d-flex justify-content-center py-4">{children}</div>
        </Container>
      </motion.div>
    </>
  );
};

export default ProgressBarMobile;
