import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';

export const useLogout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const resetFormState = useLoanApplicationFormStore(
    ({ resetFormState }) => resetFormState,
  );

  return useMutation({
    mutationFn: () => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
    onSuccess: async (_, redirectTo) => {
      await queryClient.resetQueries();

      resetFormState();

      if (redirectTo) {
        navigate(redirectTo);
      }
    },
    onError: (err) => {
      console.error('Failed to logout: err', err);
    },
  });
};
