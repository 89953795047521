import classnames from 'classnames';
import React from 'react';

import styles from './LoginState.module.scss';

const LoginState = ({ children, error, className }) => {
  return (
    <div
      className={classnames(
        styles.root,
        'text-center rounded',
        { 'bg-danger text-white p-6': error },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default LoginState;
