import React from 'react';
import { useMatch, useNavigate, useLocation } from 'react-router-dom';

import Button from '@krea/common/shared-components/button';

const MenuLink = ({ href, title, forMobile, onCloseMenu, disabled }) => {
  let location = useLocation();
  let match = useMatch(location.pathname);
  let navigate = useNavigate();

  return (
    <Button
      variant="text"
      color={match.pathname.includes(href) ? 'secondary' : 'primary'}
      disabled={disabled}
      onClick={() => {
        href && navigate(href);
        forMobile && onCloseMenu();
      }}
    >
      {title}
    </Button>
  );
};

export default MenuLink;
