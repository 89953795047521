import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import UserSVG from '@krea/common/images/svg/user.svg';
import Button from '@krea/common/shared-components/button';
import { useLogout } from '@krea/common/store/user/hooks/mutations';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';

const motionDesktopMenu = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
};

const DesktopSettingsMenu = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { data: { isAdmin = false, partnerOrganisation = {} } = {} } =
    useGetUserData();
  const { mutate: logout } = useLogout();

  const toggleSelector = () => {
    setIsOpen(!isOpen);
  };

  const onLogout = () => {
    logout('/login');
  };

  return (
    <div
      className={classnames('position-relative', className)}
      onMouseEnter={toggleSelector}
      onMouseLeave={toggleSelector}
    >
      <div className="tw-flex tw-gap-2 tw-border-b tw-border-solid tw-border-primary tw-px-1 tw-py-2 tw-font-semibold">
        {isAdmin ? 'Admin' : partnerOrganisation?.customerCompany?.name}
        <SVG style={{ width: 18 }} className="h-auto" src={UserSVG} />
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={motionDesktopMenu}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={classnames(
              'position-absolute bg-gray p-5 rounded mt-1 mr-5',
            )}
            style={{ width: 200, right: -20 }}
          >
            <div style={{ textAlign: 'center' }}>
              {!isAdmin && (
                <p
                  onClick={() => {
                    setIsOpen(false);
                    navigate('/settings');
                  }}
                  style={{ cursor: 'pointer', fontWeight: 600 }}
                >
                  {t('menu.settings')}
                </p>
              )}

              <div>
                <Button size={'md'} variant="outlined" onClick={onLogout}>
                  {t('menu.logout')}
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DesktopSettingsMenu;
