import { cloneDeep } from 'lodash';

import {
  COUNTRY_CODE,
  CURRENCY_BY_COUNTRY_CODE,
  getCountryCode,
} from './utils';

const countryCode = getCountryCode();
const currency = CURRENCY_BY_COUNTRY_CODE[countryCode];

const isOnFakturino =
  window?.location.hostname.includes('fakturino.se') ||
  ['fakturino.krea.se', 'staging-fakturino.krea.se'].includes(
    window?.location.hostname,
  );

const getGtmID = () => {
  if (countryCode === COUNTRY_CODE.SE) {
    return 'GTM-PDQ5MPJ';
  } else if (countryCode === COUNTRY_CODE.FI) {
    return 'GTM-56JQDSP';
  }

  return '';
};

const getCountryWebsiteUrl = () => {
  let url = null;

  if (countryCode === COUNTRY_CODE.SE) {
    url = 'http://localhost:3002';
  } else if (countryCode === COUNTRY_CODE.FI) {
    url = 'http://localhost:3005';
  }

  if (import.meta.env.REACT_APP_APPLICATION_ENV === 'production') {
    if (countryCode === COUNTRY_CODE.SE) {
      url = 'https://krea.se';
    } else if (countryCode === COUNTRY_CODE.FI) {
      url = 'https://kredita.fi';
    }
  } else if (import.meta.env.REACT_APP_APPLICATION_ENV === 'staging') {
    if (countryCode === COUNTRY_CODE.SE) {
      url = 'https://staging-next.krea.se';
    } else if (countryCode === COUNTRY_CODE.FI) {
      url = 'https://staging-next.kredita.fi';
    }
  } else if (import.meta.env.REACT_APP_APPLICATION_ENV === 'test') {
    if (countryCode === COUNTRY_CODE.SE) {
      url = 'https://test-next.krea.se';
    } else if (countryCode === COUNTRY_CODE.FI) {
      url = 'https://test-next.kredita.fi';
    }
  } else if (import.meta.env.REACT_APP_APPLICATION_ENV === 'local-docker') {
    if (countryCode === COUNTRY_CODE.SE) {
      url = 'http://localhost:3002';
    } else if (countryCode === COUNTRY_CODE.FI) {
      url = 'http://localhost:3005';
    }
  }

  if (isOnFakturino) {
    if (import.meta.env.REACT_APP_APPLICATION_ENV === 'production') {
      url = 'https://fakturino.se';
    } else if (import.meta.env.REACT_APP_APPLICATION_ENV === 'staging') {
      url = 'https://staging2.fakturino.se';
    }
  }

  if (!url) {
    throw Error('countryWebsiteUrl cant be null, not configured correct.');
  }

  return url;
};

const getContacts = () => {
  switch (countryCode) {
    case COUNTRY_CODE.SE:
      return {
        phone: {
          value: '08-551 082 62',
          link: '+46855108262',
        },
        email: 'kundservice@krea.se',
        adress: {
          value: 'Valhallavägen 60, 114 27 Stockholm',
          link: 'https://maps.google.com/?q=KREA%20F%C3%B6retagsl%C3%A5n%20Valhallav%C3%A4gen%2060,%20114%2027%20Stockholm',
        },
        openingHours: [
          {
            day: `footer.mondayToThursdayLabel.${countryCode}`,
            time: '09 - 18',
          },
          { day: 'footer.fridayLabel', time: '09 - 17' },
        ],
      };

    case COUNTRY_CODE.FI:
      return {
        phone: {
          value: '09 315 316 10',
          link: '+358931531610',
        },
        email: 'asiakaspalvelu@kredita.fi',
        adress: {
          value: 'Linnoitustie 6, 02600 Espoo',
          link: 'https://www.google.com/maps/place/Wonderland+Work+Lepp%C3%A4vaara/@60.2117072,24.8134392,17z/data=!4m15!1m8!3m7!1s0x468df5d7e2cae661:0x98e466774ad35947!2sLinnoitustie+6,+02600+Espoo,+Finland!3b1!8m2!3d60.2117072!4d24.8134392!16s%2Fg%2F11c88dc9hv!3m5!1s0x468df5bf2d23861b:0x8d3c916b48882443!8m2!3d60.2119617!4d24.8147542!16s%2Fg%2F11ng59wsqy',
        },
        openingHours: [
          {
            day: `footer.mondayToThursdayLabel.${countryCode}`,
            time: '10 - 16',
          },
        ],
      };

    default:
      throw new Error('There is no contact information for this country');
  }
};

export let settings = {
  countryCode,
  currency,
  isOnFakturino,
  contacts: getContacts(),
  countryWebsiteUrl: getCountryWebsiteUrl(),
  gtmId: getGtmID(), // Google tagmanager Id
  appTitle: countryCode === COUNTRY_CODE.SE ? 'Krea' : 'Kredita',
  defaultListLimit: 25,
  appName: null,
  environment: null,
  kreaBaseUrl: null,
  unauthorizedPaths: [],
};

export const initCommonSettings = (appSpecificSettings) => {
  settings = {
    ...settings,
    ...cloneDeep(appSpecificSettings),
  };

  return settings;
};
