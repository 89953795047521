import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowIcon from '@krea/common/images/svg/arrow-down-colorless.svg';
import Button from '@krea/common/shared-components/button';
import {
  DialogActions,
  DialogTitle,
} from '@krea/common/shared-components/dialog';
import { Text } from '@krea/common/shared-components/text';
import { useModalStore } from '@krea/common/store/modal/hooks';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';
import {
  LOAN_APPLICATION_STATES,
  LOAN_APPLICATION_STATES_WITH_DISABLED_PREVIEW_EDIT,
} from '@krea/common/utils';

import {
  useDeselectLenderResponse,
  useMarkBidsRejectedByCustomer,
  useMarkNoBidsReceived,
} from 'store/current-loan-application/hooks/mutations';
import {
  useGetCurrentLoanApplication,
  useGetSelectedLenderResponse,
} from 'store/current-loan-application/hooks/queries';
import { PartnerOrganisationType, hasPermission } from 'utils';

import styles from './HeaderAppSelector.module.scss';

const motionDesctopMenu = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
};

const motionMobileMenu = {
  hidden: {
    opacity: 0,
    height: 0,
    marginTop: '0px',
    borderTop: '0px solid rgba(255, 255, 255, 0)',
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
  visible: {
    opacity: 1,
    height: 'auto',
    marginTop: '16px',
    borderTop: '1px solid rgba(255, 255, 255, 1)',
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
};

const motionArrow = {
  hidden: {
    transform: 'rotateZ(0deg)',
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
  visible: {
    transform: 'rotateZ(180deg)',
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
};

const HeaderAppSelectorHeader = ({ isOpen, onClick, company, className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classnames(
        'd-flex justify-content-between align-items-center',
        className,
      )}
      tabIndex="0"
      role="button"
      aria-expanded={isOpen ? 'true' : 'false'}
      onClick={onClick}
    >
      <div>
        <Text size="sm" className="font-weight-bold text-gray-dark">
          {t('loanApplication.toolbar.currentApplicationHeading')}
        </Text>
        <Text className="font-weight-bold mt-n1 text-secondary">{company}</Text>
      </div>
      <motion.div
        variants={motionArrow}
        initial="hidden"
        animate={isOpen ? 'visible' : 'hidden'}
        className={classnames(
          styles.icon,
          'position-relative ml-3 text-primary',
        )}
      >
        <SVG src={ArrowIcon} className="position-absolute w-100 h-100" />
      </motion.div>
    </div>
  );
};

const HeaderAppSelectorMenuData = ({ title, value, action, isMobile }) => {
  const currentTitle = (
    <Text
      size="sm"
      className="font-weight-bold text-left text-secondary"
    >{`${title}:`}</Text>
  );
  const currentValue = value && (
    <Text size="sm" className="text-left text-secondary">
      {value}
    </Text>
  );
  const currentAction = action && action;

  return (
    <>
      {!isMobile ? (
        <tr>
          <td className="align-top py-1 text-nowrap">{currentTitle}</td>
          <td className="align-top py-1 pl-4 text-left">
            {currentValue}
            {currentAction}
          </td>
        </tr>
      ) : (
        <dl className="mt-2 mx-0 p-0">
          <dd className="m-0 p-0">{currentTitle}</dd>
          <dd className="m-0 p-0">
            {currentValue}
            {currentAction}
          </dd>
        </dl>
      )}
    </>
  );
};

const ConfirmActionModal = ({ title, onConfirm }) => {
  const { t } = useTranslation();
  const closeModal = useModalStore(({ closeModal }) => closeModal);

  const confirm = () => {
    onConfirm();
    onCloseModal();
  };

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <>
      <DialogTitle title={title} />

      <DialogActions>
        <Button variant="text" onClick={onCloseModal} id="modal-cancel-button">
          {t('loanApplication.actions.cancel')}
        </Button>

        <Button variant="contained" onClick={confirm} id="modal-submit-button">
          {t('loanApplication.actions.confirm')}
        </Button>
      </DialogActions>
    </>
  );
};

const HeaderAppSelectorMenuDataList = ({
  isMobile,
  currentLoanApplication,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const openModal = useModalStore(({ openModal }) => openModal);

  const {
    id: loanApplicationID,
    state,
    applyingCompany,
    contactPerson,
  } = currentLoanApplication;
  const { data: selectedLenderResponse = {} } =
    useGetSelectedLenderResponse(loanApplicationID);
  const { data: user = {} } = useGetUserData();

  const { mutate: deselectLenderResponse } = useDeselectLenderResponse();
  const { mutate: markNoBidsReceived } = useMarkNoBidsReceived();
  const { mutate: markBidsRejectedByCustomer } =
    useMarkBidsRejectedByCustomer();

  const selectedLenderName =
    selectedLenderResponse?.lenderApplication?.lenderCompany?.name;

  return (
    <>
      <HeaderAppSelectorMenuData
        title={t('loanApplication.toolbar.companyNameHeading')}
        value={applyingCompany?.customerCompany?.name}
        isMobile={isMobile}
      />
      <HeaderAppSelectorMenuData
        title={t('loanApplication.toolbar.companyOrgNumHeading')}
        value={applyingCompany?.customerCompany?.organisationNumber}
        isMobile={isMobile}
      />
      <HeaderAppSelectorMenuData
        title={t('loanApplication.toolbar.contactPerson')}
        value={
          <div>
            <div className="m-0 p-0">{`${contactPerson?.firstName} ${contactPerson?.lastName}`}</div>
            <div className="m-0 p-0">{contactPerson?.email}</div>
            {contactPerson?.mobilePhoneNumber !== 'null' &&
              contactPerson?.mobilePhoneNumber !== '' && (
                <div className="m-0 p-0">
                  {contactPerson?.mobilePhoneNumber}
                </div>
              )}
          </div>
        }
        isMobile={isMobile}
      />
      <HeaderAppSelectorMenuData
        title={t('loanApplication.toolbar.loanApplicationIdHeading')}
        value={loanApplicationID}
        isMobile={isMobile}
      />
      <HeaderAppSelectorMenuData
        title={t('loanApplication.toolbar.loanApplicationStatusHeading')}
        value={t(`loanApplication.states.${state}`)}
        isMobile={isMobile}
      />
      <HeaderAppSelectorMenuData
        title={t('loanApplication.toolbar.additionsHeading')}
        action={
          <Button
            variant="text"
            size="sm"
            className="my-n2 text-left"
            disabled={LOAN_APPLICATION_STATES_WITH_DISABLED_PREVIEW_EDIT.includes(
              state,
            )}
            onClick={() =>
              navigate(`/loan-applications/${loanApplicationID}/preview`)
            }
          >
            {t('loanApplication.toolbar.additions')}
          </Button>
        }
        isMobile={isMobile}
      />

      {hasPermission(user, PartnerOrganisationType.REGULAR) && (
        <HeaderAppSelectorMenuData
          title={t('loanApplication.toolbar.deselectBidHeading')}
          action={
            <Button
              variant="text"
              disabled={
                !(
                  selectedLenderResponse?.id &&
                  state === LOAN_APPLICATION_STATES.BID_SELECTED
                )
              }
              size="sm"
              className="my-n2 text-left"
              onClick={() =>
                openModal({
                  modal: (
                    <ConfirmActionModal
                      title={`${t('loanApplication.toolbar.deselectBid')} ${selectedLenderName}`}
                      onConfirm={() =>
                        deselectLenderResponse({ loanApplicationID })
                      }
                    />
                  ),
                })
              }
            >
              {selectedLenderName
                ? `${t('loanApplication.toolbar.deselectBid')} ${selectedLenderName}`
                : t('loanApplication.toolbar.deselectBidHeading')}
            </Button>
          }
          isMobile={isMobile}
        />
      )}

      {hasPermission(user, PartnerOrganisationType.REGULAR) && (
        <HeaderAppSelectorMenuData
          title={t('loanApplication.toolbar.cancelApplicationHeading')}
          action={
            <>
              <Button
                variant="text"
                disabled={state !== LOAN_APPLICATION_STATES.OPEN_FOR_BIDS}
                size="sm"
                className="my-n2 text-left"
                onClick={() =>
                  openModal({
                    modal: (
                      <ConfirmActionModal
                        title={t(
                          'loanApplication.toolbar.customerWantsToCancelApplication',
                        )}
                        onConfirm={() =>
                          markBidsRejectedByCustomer({ loanApplicationID })
                        }
                      />
                    ),
                  })
                }
              >
                {t('loanApplication.toolbar.customerWantsToCancelApplication')}
              </Button>
              <Button
                variant="text"
                disabled={
                  ![
                    LOAN_APPLICATION_STATES.CREATED,
                    LOAN_APPLICATION_STATES.OPEN_FOR_BIDS,
                  ].includes(state)
                }
                size="sm"
                className="my-n2 text-left"
                onClick={() =>
                  openModal({
                    modal: (
                      <ConfirmActionModal
                        title={t('loanApplication.toolbar.markNoBidsReceived')}
                        onConfirm={() =>
                          markNoBidsReceived({ loanApplicationID })
                        }
                      />
                    ),
                  })
                }
              >
                {t('loanApplication.toolbar.markNoBidsReceived')}
              </Button>
            </>
          }
          isMobile={isMobile}
        />
      )}
    </>
  );
};

const HeaderAppSelectorMenu = ({ isMobile, currentLoanApplication }) => {
  return (
    <>
      {!isMobile ? (
        <table>
          <tbody>
            <HeaderAppSelectorMenuDataList
              currentLoanApplication={currentLoanApplication}
            />
          </tbody>
        </table>
      ) : (
        <HeaderAppSelectorMenuDataList
          currentLoanApplication={currentLoanApplication}
          isMobile
        />
      )}
    </>
  );
};

const HeaderAppSelector = ({ className, isMobile, loanApplicationID }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: currentLoanApplication = {} } =
    useGetCurrentLoanApplication(loanApplicationID);

  const toggleSelector = () => {
    setIsOpen(!isOpen);
  };

  if (isEmpty(currentLoanApplication)) {
    return null;
  }

  const currentCompanyName =
    currentLoanApplication?.applyingCompany?.customerCompany?.name;

  return (
    <>
      {!isMobile ? (
        <div
          className={classnames('position-relative', className)}
          onMouseEnter={toggleSelector}
          onMouseLeave={toggleSelector}
        >
          <HeaderAppSelectorHeader
            company={currentCompanyName}
            isOpen={isOpen}
            className="mr-1"
          />

          <AnimatePresence>
            {isOpen && (
              <motion.div
                variants={motionDesctopMenu}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className={classnames(
                  styles.menu,
                  'position-absolute bg-gray p-5 rounded mt-1',
                )}
              >
                <HeaderAppSelectorMenu
                  currentLoanApplication={currentLoanApplication}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : (
        <div
          className={classnames('w-100 mb-4 border-bottom border-white', {
            'pb-2': isOpen,
            'pb-4': !isOpen,
          })}
        >
          <HeaderAppSelectorHeader
            company={currentCompanyName}
            onClick={toggleSelector}
            isOpen={isOpen}
          />

          <motion.div
            variants={motionMobileMenu}
            initial="hidden"
            animate={isOpen ? 'visible' : 'hidden'}
            className={classnames('overflow-hidden')}
          >
            <div className="pt-2">
              <HeaderAppSelectorMenu
                currentLoanApplication={currentLoanApplication}
                isMobile
              />
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default HeaderAppSelector;
