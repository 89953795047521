import React from 'react';
import { useTranslation } from 'react-i18next';

import { settings } from '@krea/common/settings';
import { Text } from '@krea/common/shared-components/text';
import { useLogout } from '@krea/common/store/user/hooks/mutations';

const LoginGoToForm = () => {
  const { t } = useTranslation();
  const { mutate: logout } = useLogout();

  return (
    <div className="mt-6">
      <Text
        size="md"
        className="font-weight-bold text-center"
        role="button"
        onClick={() => {
          logout();
          window.location.href = settings.countryWebsiteUrl;
        }}
      >
        {t('login.back')}
      </Text>
    </div>
  );
};

export default LoginGoToForm;
