import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useRef, useState } from 'react';
import { Drawer } from 'vaul';

import { useMobile } from '@krea/common/hooks';
import twMerge from '@krea/common/utils/tailwind-utils';

const Header = ({ title, onClose }) => {
  return (
    <div className="tw-justify-center tw-w-full tw-rounded-t-xl tw-flex tw-border-0 tw-border-b tw-border-gray-200 tw-border-solid tw-py-[5px]">
      <h5 className="tw-mt-2">{title}</h5>
      {onClose && (
        <p
          className="tw-mt-1 tw-font-bold tw-absolute tw-pr-6 tw-right-0 tw-text-2xl tw-text-gray-400 tw-cursor-pointer"
          onClick={onClose}
          onMouseEnter={(e) => {
            e.target.classList.remove('tw-text-gray-400');
            e.target.classList.add('tw-text-gray-600');
          }}
          onMouseLeave={(e) => {
            e.target.classList.remove('tw-text-gray-600');
            e.target.classList.add('tw-text-gray-400');
          }}
        >
          &times;
        </p>
      )}
    </div>
  );
};

const ResponsiveDialog = ({
  open,
  setOpen,
  children,
  dialogContentStyling,
  drawerContentStyling,
  onClose,
  title,
}) => {
  const isMobile = useMobile();

  const ref = useRef(null);

  const [viewport, setViewport] = useState({
    maxHeight: 0,
    maxWidth: '100vw',
  });

  const updateViewport = () => {
    setViewport({
      maxHeight: window.visualViewport.height,
      maxWidth: window.visualViewport.width,
    });
  };

  useEffect(() => {
    if (isMobile) {
      if (
        typeof window !== 'undefined' &&
        typeof window.visualViewport !== 'undefined' &&
        open
      ) {
        window.visualViewport.addEventListener('resize', updateViewport);

        return () =>
          window.visualViewport.removeEventListener('resize', updateViewport);
      }
    }
  }, [open, isMobile]);

  const [screenHeight, setScreenHeight] = useState(0);
  const [screenHeightWithoutKeyboard, setScreenWithoutKeyboard] = useState(0);

  if (screenHeight === 0) {
    setScreenHeight(window.visualViewport.height);
  }

  if (screenHeightWithoutKeyboard === 0) {
    if (
      window.visualViewport.height < screenHeight - 100 &&
      window.visualViewport.height > 0
    ) {
      setScreenWithoutKeyboard(window.visualViewport.height);
    }
  }

  let isKeyboardActive =
    window.visualViewport.height !== 0 &&
    screenHeight !== 0 &&
    window.visualViewport.height < screenHeight;

  let drawerMaxHeight = isKeyboardActive
    ? screenHeightWithoutKeyboard !== 0
      ? screenHeightWithoutKeyboard
      : '500px'
    : screenHeight !== 0
      ? screenHeight
      : '500px';

  return isMobile ? (
    <Drawer.Root
      shouldScaleBackground={false}
      dismissible={false}
      open={open}
      onOpenChange={setOpen}
    >
      <Drawer.Portal>
        <Drawer.Overlay className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-z-[400000] tw-transition-opacity tw-animation-fade-in-0 tw-duration-200 tw-ease-in-out tw-opacity-50" />

        <Drawer.Content
          className={
            'tw-border-t tw-border-solid tw-border-gray-200 tw-overflow-hidden tw-fixed tw-w-full tw-outline-none tw-bottom-0 tw-bg-white tw-flex tw-flex-col tw-rounded-t-md tw-z-[4000000]'
          }
          style={{ maxHeight: window.visualViewport.height - 100 }}
          onInteractOutside={(event) => {
            event.preventDefault();
          }}
          onEscapeKeyDown={(event) => {
            event.preventDefault();
          }}
          onPointerDownOutside={(event) => {
            event.preventDefault();
          }}
          onOpenAutoFocus={(event) => {
            event.preventDefault();
          }}
        >
          <Drawer.Title>
            <Header title={title} onClose={onClose} />
          </Drawer.Title>

          <div
            ref={ref}
            className={twMerge(
              'tw-overflow-y-auto tw-px-[22px] tw-h-full tw-py-2 tw-flex tw-flex-col',
              drawerContentStyling,
            )}
          >
            {children}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  ) : (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-z-[400000] tw-transition-opacity tw-animation-fade-in-0 tw-duration-200 tw-ease-in-out tw-opacity-50" />
        <Dialog.Content
          className="tw-fixed tw-z-[400000] tw-h-auto tw-w-[700px] tw-bg-white tw-rounded-md tw-left-1/2 tw-top-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-animation-fade-in-0 tw-duration-200 tw-ease-in-out"
          onInteractOutside={(event) => {
            event.preventDefault();
          }}
          onEscapeKeyDown={(event) => {
            event.preventDefault();
          }}
          onOpenAutoFocus={(event) => {
            event.preventDefault();
          }}
        >
          <Dialog.Title>
            <Header title={title} onClose={onClose} />
          </Dialog.Title>
          <div
            className={twMerge(
              'tw-w-full tw-overflow-y-auto tw-p-4',
              'tw-max-h-full',
              dialogContentStyling,
            )}
          >
            {children}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ResponsiveDialog;
