import classnames from 'classnames';
import React from 'react';

import { Text } from '../../text';

export const Label = ({
  children,
  htmlFor,
  className,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  htmlFor: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <Text
      htmlTag="label"
      size="sm"
      className={classnames(
        'tw-inline-block tw-font-bold tw-leading-6 tw-m-0',
        className,
      )}
      aria-disabled={disabled}
      htmlFor={htmlFor}
      onClick={onClick}
    >
      {children}
    </Text>
  );
};
