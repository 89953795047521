import React from 'react';
import { useTranslation } from 'react-i18next';

import { settings } from '@krea/common/settings';
import TextInput from '@krea/common/shared-components/fields/input';
import { TEST_IDS } from '@krea/common/utils';

const EmailInput = (props) => {
  const { value, touched, errors, isPreliminaryBid, label, onBlur } = props;
  const { t } = useTranslation();

  return (
    <TextInput
      size="lg"
      id="email"
      type="email"
      name="email"
      label={label || t('applicationForm.commons.emailLabel')}
      placeholder={t(
        `applicationForm.commons.emailPlaceholder.${settings.countryCode}`,
      )}
      autoComplete="on"
      value={value || ''}
      error={touched && errors ? errors : null}
      onChange={(e) => props.handleChange(e)}
      onBlur={onBlur}
      tooltip={isPreliminaryBid && t('applicationForm.commons.emailTooltip')}
      data-test-id={TEST_IDS.common.forms.emailInput}
    />
  );
};

export default EmailInput;
