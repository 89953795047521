import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

import { authenticateViaBankID } from '@krea/common/store/bankID/api';
import { useBankIDStore } from '@krea/common/store/bankID/hooks';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import { BANKID_FLOW_STATES, HINT_CODE_STATES } from '@krea/common/utils';

export const useAuthenticateMutation = () => {
  // Ssn is only used for the test flow in non-production environments
  const ssn = useLoanApplicationFormStore(({ ssn }) => ssn);

  const { setOrderRef, setAutoStartToken, setQrCode } = useBankIDStore(
    useShallow(({ setOrderRef, setAutoStartToken, setQrCode }) => ({
      setOrderRef,
      setAutoStartToken,
      setQrCode,
    })),
  );

  return useMutation({
    mutationFn: () => authenticateViaBankID(ssn),
    onSuccess: (response) => {
      const { orderRef, autoStartToken, qrCode } = response?.data || {};

      setOrderRef(orderRef);
      setAutoStartToken(autoStartToken);
      setQrCode(qrCode);
    },
    onError: (err) => {
      console.error(
        'Failed to start BankID authentication',
        JSON.stringify(err),
      );
      Sentry.captureException(err);
      return {
        status: BANKID_FLOW_STATES.FAILED,
        hintCode: HINT_CODE_STATES.ERROR,
      };
    },
  });
};
