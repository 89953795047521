import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useBankIDStore } from '@krea/common/store/bankID/hooks';
import { BANKID_AUTHENTICATE_STORE_KEY } from '@krea/common/store/queryClient';
import { fetchUserQuery } from '@krea/common/store/user/hooks/queries';
import { BANKID_FLOW_STATES, HINT_CODE_STATES } from '@krea/common/utils';

import { register } from 'store/register/api';

export const useRegisterPartner = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const resetBankIDStore = useBankIDStore(
    ({ resetBankIDStore }) => resetBankIDStore,
  );

  return useMutation({
    mutationFn: (payload) => register(payload),
    onSuccess: async () => {
      await fetchUserQuery();
      navigate('/');
    },
    onError: (err) => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');

      const error = err?.data?.errors?.[0];

      if (error.message === 'User already has a partner organisation') {
        navigate('/login');
      }

      queryClient.setQueryData([BANKID_AUTHENTICATE_STORE_KEY], {
        status: BANKID_FLOW_STATES.FAILED,
        hintCode: HINT_CODE_STATES.ERROR,
      });
    },
    onSettled: () => {
      resetBankIDStore();
    },
  });
};
