import { useTranslation } from 'react-i18next';

import TextInput from '@krea/common/shared-components/fields/input';
import { Label } from '@krea/common/shared-components/fields/label';
import Preloader from '@krea/common/shared-components/preloader';

const ApplicationsSearch = ({
  searchTerm,
  setSearchTerm,
  loading,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Label htmlFor="app-search" className="mb-2">
        <>
          <span>{t('listApplicationsView.search.label')}</span>
          <div
            style={{
              height: 18,
              display: 'inline-block',
              verticalAlign: 'middle',
              marginLeft: 5,
            }}
          >
            {loading && <Preloader className="text-primary" size="sm" />}
          </div>
        </>
      </Label>
      <TextInput
        id="app-search"
        value={searchTerm}
        onChange={({ target }) => setSearchTerm(target.value)}
        placeholder={t('listApplicationsView.search.placeholder')}
      />
    </div>
  );
};

export default ApplicationsSearch;
