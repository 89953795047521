import { ToastContent } from './ToastContent';
import { ToastRoot } from './ToastRoot';

export const Toast = (props) => {
  const { isWarning, className, excludeBorder, ...otherProps } = props;

  return (
    <ToastRoot
      isWarning={isWarning}
      className={className}
      excludeBorder={excludeBorder}
    >
      <ToastContent isWarning={isWarning} {...otherProps} />
    </ToastRoot>
  );
};
