import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import HttpService from '@krea/common/services/httpService';
import { client } from '@krea/common/graphql';
import { getUtmParamsFromCookie } from '@krea/common/services/queryParamsService';
import { settings } from '@krea/common/settings';
import Button from '@krea/common/shared-components/button';
import LoginContainer from '@krea/common/shared-components/login/LoginContainer';
import LoginState from '@krea/common/shared-components/login/LoginState';
import Preloader from '@krea/common/shared-components/preloader';

import FIBankCredentials from './FIBankCredentials';

const instanceWithoutInterceptors =
  HttpService.createInstanceWithoutInterceptors();

const FLOW_STATE = {
  NOT_INITIALIZED: 'NOT_INITIALIZED',
  INITIALIZED: 'INITIALIZED',
  OAUTH_STARTED: 'OAUTH_STARTED',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
};

const initialState = {
  flowState: FLOW_STATE.NOT_INITIALIZED,
  initData: {},
  error: null,
  externalFlowWindow: null,
  noApplication: false,
};

const BankIDFlow = ({
  identificationContext = undefined,
  authenticationContext = undefined,
  onSuccess,
  onCancel,
  onFailure = undefined,
}) => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const flowCheckerTimer = useRef(null);

  function reducer(state, action) {
    switch (action.type) {
      case FLOW_STATE.NOT_INITIALIZED:
        return { ...state, flowState: FLOW_STATE.NOT_INITIALIZED };
      case FLOW_STATE.INITIALIZED:
        return {
          ...state,
          flowState: FLOW_STATE.INITIALIZED,
          initData: action.payload,
        };
      case FLOW_STATE.OAUTH_STARTED:
        return {
          ...state,
          flowState: FLOW_STATE.OAUTH_STARTED,
          externalFlowWindow: action.payload,
        };
      case FLOW_STATE.COMPLETED:
        return {
          ...state,
          flowState: FLOW_STATE.COMPLETED,
          externalFlowWindow: null,
        };
      case FLOW_STATE.ERROR:
        return {
          ...state,
          flowState: FLOW_STATE.ERROR,
          externalFlowWindow: null,
          error: action.payload,
        };
      case 'setNoApplication':
        return { ...state, noApplication: true };
      case 'closeExternalWindow':
        return { ...state, externalFlowWindow: null };
      case 'resetState':
        return { ...initialState };
      default:
        throw new Error(`No such type: [${action.type}]`);
    }
  }

  const [state, setState] = useReducer(reducer, initialState);
  const isLoginPage = pathname.includes('/login');

  const startAuth = useCallback(async () => {
    try {
      const response = await instanceWithoutInterceptors.post(
        '/api/fi/v1/identity/initialize',
      );
      setState({ type: FLOW_STATE.INITIALIZED, payload: response.data });
    } catch (e) {
      if (onFailure) {
        await onFailure();
      }

      const translatedError = t('login.loginError');
      setState({ type: FLOW_STATE.ERROR, payload: translatedError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.location.hash = '#bankid-container';
    startAuth();

    return () => {
      if (flowCheckerTimer.current) {
        clearInterval(flowCheckerTimer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearHash = () => {
    if ('pushState' in window.history) {
      window.history.pushState(
        '',
        document.title,
        window.location.href.replace(window.location.hash, ''),
      );
    } else {
      const scrollingElement =
        document.scrollingElement || document.documentElement;

      // Prevent scrolling by storing the page's current scroll offset
      const scrollV = scrollingElement.scrollTop;
      const scrollH = scrollingElement.scrollLeft;

      window.location.hash = '';

      // Restore the scroll offset, should be flicker free
      scrollingElement.scrollTop = scrollV;
      scrollingElement.scrollLeft = scrollH;
    }
  };

  const onComplete = async ({ tokens }) => {
    const { access_token, refresh_token } = tokens;

    window.localStorage.setItem('accessToken', access_token);
    window.localStorage.setItem('refreshToken', refresh_token);

    client.setHeader('authorization', `Bearer ${access_token}`);

    await onSuccess();

    clearHash();
  };

  const cancel = () => {
    onCancel();

    setState({ type: FLOW_STATE.NOT_INITIALIZED });

    clearHash();

    if (state.externalFlowWindow) {
      try {
        state.externalFlowWindow.close();
      } catch (e) {}
    }

    setState({ type: 'resetState' });
  };

  const flowChecker = () => {
    const { externalFlowWindow } = state;
    flowCheckerTimer.current = setInterval(async () => {
      if (externalFlowWindow && !externalFlowWindow.closed) {
        try {
          // Yay we are redirected back to our domain in the external window
          if (externalFlowWindow.location.origin === window.location.origin) {
            const params = new URLSearchParams(
              externalFlowWindow.location.search,
            );

            // code (encrypted JWT) we got from oauth flow (on success)
            const code = params.get('code');

            // error string we got from oauth flow (on non-success)
            let error = params.get('error');

            if (code === 'cancel') {
              error = code;
            }

            if (externalFlowWindow) {
              externalFlowWindow.close();
            }

            if (error) {
              console.error('ERROR: ', error);
              let translatedError = null;
              const userCancellationErrors = ['AUS-3200', 'IDP-3200', 'cancel'];

              if (userCancellationErrors.includes(error)) {
                translatedError = t('login.loginCancelled');
              } else {
                Sentry.captureMessage(
                  `Error code [${error}] in FI oauth flow:`,
                );

                if (onFailure) {
                  await onFailure();
                }

                translatedError = t('login.loginError');
              }

              setState({ type: FLOW_STATE.ERROR, payload: translatedError });
            } else if (code) {
              // Success from external window
              try {
                const extraData = JSON.parse(getUtmParamsFromCookie());
                const response = await instanceWithoutInterceptors.post(
                  '/api/fi/v1/identity/finalize',
                  {
                    code,
                    preferred_language: i18n.language,
                    redirect_url: `${window.location.origin}/redirect-return-page`,
                    authentication_context:
                      authenticationContext || settings.appName,
                    identification_context: identificationContext,
                    extra_data: {
                      preferred_language: i18n.language,
                      ga_client_id: extraData?.ga_client_id,
                    },
                  },
                );

                setState({ type: FLOW_STATE.COMPLETED });
                onComplete({ tokens: response.data.tokens });
              } catch (e) {
                if (onFailure) {
                  await onFailure();
                }

                if (e?.status !== 400) {
                  console.error(
                    'Error on FI auth finalize: ',
                    JSON.stringify(e),
                  );
                  Sentry.captureException(e);
                }

                const translatedError = t('login.loginError');
                setState({ type: FLOW_STATE.ERROR, payload: translatedError });
              }
            } else {
              if (onFailure) {
                await onFailure();
              }

              console.log('Unknown: ', error);
              const translatedError = t('login.loginError');
              setState({ type: FLOW_STATE.ERROR, payload: translatedError });
            }

            clearInterval(flowCheckerTimer.current);
            flowCheckerTimer.current = null;
          }
        } catch (e) {
          if (e instanceof DOMException) {
            // This mean that the the external window is still on oath provider's domain.
            // So CORS will force us in here.
            // We're not allowed to access "state.externalFlowWindow.location") if external window is
            // not on same domain as us.
            // As long as we go in here the "oath provider" user flow can be considered "pending"
          } else {
            console.error('Error in FI auth flowChecker: ', e);
            Sentry.captureException(e);
            throw e;
          }
        }
      } else {
        clearInterval(flowCheckerTimer.current);
        flowCheckerTimer.current = null;

        setState({ type: 'closeExternalWindow' });
      }
    }, 500);
  };

  const selectIdentityProvider = useCallback(async (ftn_idp_id) => {
    let externalFlowWindow = null;

    //const useNewTab = isMobile() // Use this if we want to control new/small window popup for oauth.
    const useNewTab = true;

    if (useNewTab) {
      // If mobile "pre-open" the window is needed
      externalFlowWindow = window.open('about:blank', '_blank');
    }

    try {
      const response = await instanceWithoutInterceptors.post(
        `/api/fi/v1/identity/authenticate`,
        {
          ftn_idp_id,
          redirect_url: `${window.location.origin}/redirect-return-page`,
          ui_locales: i18n.language,
        },
      );

      //const externalFlowWindow = window.open(response.data.auth_url, '_blank').focus()
      const url = response.data.auth_url;

      if (!url) {
        throw new Error('auth_url was null in react state. Should never be');
      }

      if (externalFlowWindow) {
        // If mobile the window opened pre http fetch (mobile browser security reasons)
        externalFlowWindow.location = url;
      } else {
        const features = `
        location=no,
        menubar=no,
        status=no,
        titlebar=no,
        toolbar=no,
        width=${400},
        height=${650},
        top=${(window.innerHeight - 400) / 2},
        left=${(window.innerWidth - 400) / 2}`;

        externalFlowWindow = window.open(url, 'newwindow', features);
      }
      setState({ type: FLOW_STATE.OAUTH_STARTED, payload: externalFlowWindow });
    } catch (e) {
      Sentry.captureException(e);

      if (onFailure) {
        await onFailure();
      }

      if (externalFlowWindow && !externalFlowWindow.closed) {
        externalFlowWindow.close();
      }

      const translatedError = t('login.loginError');
      setState({ type: FLOW_STATE.ERROR, payload: translatedError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBackButton = (
    <Button
      variant="text"
      className="mt-6"
      onClick={cancel}
      onKeyDown={(event) => {
        if (event.key !== 'Enter') return;
        event.preventDefault();

        cancel();
      }}
      tabIndex="0"
    >
      {t('login.back')}
    </Button>
  );

  useEffect(() => {
    if (state.externalFlowWindow) {
      flowChecker();
      state.externalFlowWindow.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.externalFlowWindow]);

  return (
    <div style={{ display: 'inline-block !important' }} className="w-100">
      {state.flowState === FLOW_STATE.INITIALIZED ? (
        <LoginContainer
          title={
            isLoginPage
              ? t('login.heading', { applicationTitle: settings.appTitle })
              : null
          }
        >
          <FIBankCredentials
            bankCredentials={state.initData.identity_providers}
            selectIdentityProvider={selectIdentityProvider}
          />
        </LoginContainer>
      ) : state.flowState === FLOW_STATE.OAUTH_STARTED ? (
        <LoginContainer>
          <Preloader size="lg" className="text-primary" />
        </LoginContainer>
      ) : state.flowState === FLOW_STATE.ERROR ? (
        <LoginContainer title={t('login.canceledTitle')}>
          <LoginState error>{state.error}</LoginState>
        </LoginContainer>
      ) : state.flowState === FLOW_STATE.COMPLETED ? (
        <LoginContainer title={t('login.pleaseWait')} />
      ) : null}

      {[FLOW_STATE.COMPLETED, FLOW_STATE.NOT_INITIALIZED].includes(
        state.flowState,
      ) ? null : (
        <div className="text-center">{goBackButton}</div>
      )}
    </div>
  );
};

export default BankIDFlow;
