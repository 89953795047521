import { useQuery } from '@tanstack/react-query';

import { useGetCurrentLoanApplicationID } from '@krea/common/hooks';
import { fetchFieldRequestsData } from '@krea/common/store/field-requests/api';
import {
  FIELD_REQUESTS_STORE_KEY,
  queryClient,
} from '@krea/common/store/queryClient';
import { formatFRFields } from '@krea/common/utils';

const getQueryArguments = (loanApplicationID) => ({
  queryKey: [FIELD_REQUESTS_STORE_KEY, loanApplicationID],
  queryFn: async () => {
    try {
      const response = await fetchFieldRequestsData(loanApplicationID);
      const fieldRequests = response?.loanApplication?.fieldRequests || [];

      return formatFRFields(fieldRequests);
    } catch (err) {
      console.error('Failed to fetch field requests: err', err);
    }
  },
});

const useGetFieldRequests = (enabled) => {
  const loanApplicationID = useGetCurrentLoanApplicationID();
  const queryArguments = getQueryArguments(loanApplicationID);

  return useQuery({
    ...queryArguments,
    enabled: !!loanApplicationID && enabled,
  });
};

const prefetchFieldRequests = async (loanApplicationID) => {
  const queryArguments = getQueryArguments(loanApplicationID);

  await queryClient.prefetchQuery(queryArguments);
};

export { useGetFieldRequests, prefetchFieldRequests };
