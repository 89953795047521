import * as Sentry from '@sentry/react';
import React, { Component } from 'react';

import ErrorBlock from '@krea/common/shared-components/error-block';

class SentryBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(
      'Sent to sentry with contactPersonId: ',
      this.props.contactPersonId,
    );
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);

      if (this.props.contactPersonId) {
        scope.setUser({ id: this.props.contactPersonId });
      }
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBlock
          status={500}
          title="Något gick fel"
          description="Krea har noterat felet."
        />
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default SentryBoundary;
