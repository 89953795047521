import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3 * 1000,
    },
  },
});

// Common - Queries

export const USER_STORE_KEY = 'user';

export const BANKID_AUTHENTICATE_STORE_KEY = 'bankid_authenticate';

export const LOAN_APPLICATIONS_STORE_KEY = 'loan_applications';

export const CURRENT_LOAN_APPLICATION_STORE_KEY = 'current_loan_application';

export const HAS_ONGOING_APPLICATION_STORE_KEY = 'HAS_ONGOING_APPLICATION';

export const LENDER_RESPONSES_STORE_KEY = 'lender_responses';

export const SELECTED_LENDER_RESPONSE_FIELD_REQUESTS_STORE_KEY =
  'selected_lender_response';

export const FIELD_REQUESTS_STORE_KEY = 'field_requests';

export const ONE_FIELD_REQUEST_STORE_KEY = 'field_request';

export const DELEGATED_FIELD_REQUESTS_STORE_KEY = 'delegated_field_requests';

// Common - Mutations
export const POST_FIELD_REQUEST_STORE_KEY = 'post_field_request';

export const SUBMIT_APPLICATION_STORE_KEY = 'submit_application';

// Customer Web - Queries
export const IDENTIFY_CUSTOMER_STORE_KEY = 'identify_customer';

// Partner Web - Queries
export const COLLECT_LOAN_APPLICATION_STORE_KEY = 'collect_loan_application';

export const PAYOUTS_STORE_KEY = 'payouts';

export const WHITELIST_DATA_STORE_KEY = 'whitelist_data';
