import { useQuery, useQueryClient } from '@tanstack/react-query';
import { camelCase, isEmpty } from 'lodash';

import { useGetCurrentLoanApplicationID } from '@krea/common/hooks';
import { fetchFieldRequestData } from '@krea/common/store/field-requests/api';
import {
  FIELD_REQUESTS_STORE_KEY,
  ONE_FIELD_REQUEST_STORE_KEY,
} from '@krea/common/store/queryClient';

export const useGetOneFieldRequest = (fieldRequestID, isDelegated) => {
  const queryClient = useQueryClient();
  const loanApplicationID = useGetCurrentLoanApplicationID();

  return useQuery({
    queryKey: [ONE_FIELD_REQUEST_STORE_KEY, fieldRequestID],
    queryFn: async () => {
      try {
        const response = await fetchFieldRequestData(fieldRequestID);
        const fieldRequest = response?.fieldRequest || {};

        if (!isEmpty(fieldRequest)) {
          fieldRequest.field = camelCase(fieldRequest.field);
        }

        if (isDelegated) {
          return queryClient.getQueryData([
            ONE_FIELD_REQUEST_STORE_KEY,
            fieldRequestID,
          ]);
        }

        return fieldRequest;
      } catch (err) {
        console.error('Failed to lender responses data: err', err);
      }
    },
    initialData: () => {
      const cachedFieldRequests =
        queryClient.getQueryData([
          FIELD_REQUESTS_STORE_KEY,
          loanApplicationID,
        ]) || [];
      const fieldRequest = cachedFieldRequests.find(
        ({ id }) => id === fieldRequestID,
      );

      return fieldRequest;
    },
    enabled: !!fieldRequestID,
    staleTime: 5 * 1000,
  });
};
