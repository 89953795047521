import { useQuery } from '@tanstack/react-query';

import { SELECTED_LENDER_RESPONSE_FIELD_REQUESTS_STORE_KEY } from '@krea/common/store/queryClient';

import { fetchSelectedLenderResponseData } from 'store/current-loan-application/api';

export const useGetSelectedLenderResponse = (loanApplicationID) => {
  return useQuery({
    queryKey: [
      SELECTED_LENDER_RESPONSE_FIELD_REQUESTS_STORE_KEY,
      loanApplicationID,
    ],
    queryFn: async () => {
      try {
        const response =
          await fetchSelectedLenderResponseData(loanApplicationID);
        const lenderResponse =
          response?.loanApplication?.selectedLenderResponse || {};

        return lenderResponse;
      } catch (err) {
        console.error('Failed to fetch lender response data: err', err);
      }
    },
    enabled: !!loanApplicationID,
  });
};
