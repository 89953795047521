import { createBrowserRouter } from 'react-router-dom';

import App from './App';

export const router = createBrowserRouter([
  {
    path: 'redirect-return-page', // This is a empty generic redirect return page used for some oauth redirect flows
    element: <div />,
  },
  { path: '*', element: <App /> },
]);
