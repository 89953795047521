import axios from 'axios';

import { settings } from '@krea/common/settings';

export const register = async (payload) => {
  return axios.post('/partner/api/v1/partner-organisation/register', {
    ...payload,
    country_code: settings.countryCode,
  });
};
