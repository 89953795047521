/* eslint-disable import/order */
// !!!NOTE: Disabling the importing order rule as we want the settings to be imported before any other imports. Maybe fix this in the future.
import React, { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import 'dayjs/locale/fi';

// !! IMOPORTANT  !! These needs to be initialized before any @krea/common import //
import './i18n';
import settings from './settings';

import { queryClient } from '@krea/common/store/queryClient';
import { StylingWrapper } from '@krea/common/shared-components/StylingWrapper';
import { router } from './router';

dayjs.locale('sv');

function AppWithCallbackAfterRender() {
  const isDeployedEnvironment = ['production', 'staging'].includes(
    import.meta.env.REACT_APP_APPLICATION_ENV,
  );

  useEffect(() => {
    import('@krea/common/services/httpService').then((module) => {
      const HttpService = module.default;

      const instance = HttpService.getInstance();
      instance.setupInterceptors();
    });

    import('react-gtm-module')
      .then((module) => {
        const TagManager = module.default;

        const tagManagerArgs = {
          // @ts-ignore
          gtmId: settings.gtmId,
          dataLayer: {
            event: 'initTracking',
            environment: settings.environment,
          },
        };

        TagManager.initialize(tagManagerArgs);
      })
      .catch(() => {
        console.error('Unable to load react-gtm-module');
      });
  }, []);

  useEffect(() => {
    Sentry.init({
      dsn:
        import.meta.env.REACT_APP_APPLICATION_ENV === 'production'
          ? 'https://a9a86248d74b4c7881dec3b30c3ba74a@o242905.ingest.sentry.io/5307143'
          : 'https://e589092f09664e51a15c6c2647b668df@o242905.ingest.sentry.io/5455745',
      environment: import.meta.env.REACT_APP_APPLICATION_ENV,
      enabled: isDeployedEnvironment,
    });
  }, [isDeployedEnvironment]);

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Suspense fallback={null}>
            {!isDeployedEnvironment && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
            <StylingWrapper>
              <RouterProvider router={router} />
            </StylingWrapper>
          </Suspense>
        </HelmetProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<AppWithCallbackAfterRender />);
