import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { settings } from '@krea/common/settings';
import { COUNTRY_CODE } from '@krea/common/utils';

const initialState = {
  // Common
  formErrors: {},

  // form step
  currentStep: '',
  currentSteps: [],

  // Contact Person
  email: '',
  mobilePhoneNumber: '',

  // Customer Company
  organisation: {
    name: '',
    organisationNumber: '',
  },

  // Loan Application
  reason: '',
  requestedLoanAmount:
    settings.countryCode === COUNTRY_CODE.FI ? 60_000 : 600_000,

  // Real estate
  realEstateReason: '',
  propertyUnitDesignation: '',
  realEstateRequestedLoanAmount:
    settings.countryCode === COUNTRY_CODE.FI ? 500_000 : 5_000_000,

  // Factoring
  unpaidInvoices: {
    estimatedInvoiceAmount: '',
    numberOfInvoices: '',
    averagePaymentTimeDays: 30,
    files: '',
  },
  unpaidInvoicesDescription: '',

  // Leasing
  leasing: {},
  // leasing: {
  //   leasing_amount: '',
  //   leasing_object_type: '',
  // }

  // Customer-Web
  preliminaryBid: {},

  preApplicationLead: {},

  // Partner-Web
  ssn: '',
  utmCampaign: '',

  remoteIdentification: {
    type: null,
    URL: null,
    URLExpiryDate: null,
    isCustomerApplicationFlowAllowed: true,
  },
};

export const useLoanApplicationFormStore = create(
  persist(
    (set) => ({
      ...initialState,

      // setters
      setFormErrors: (error) =>
        set((state) => ({
          ...state,
          formErrors: { ...state.formErrors, ...error },
        })),
      setCurrentStep: (currentStep) =>
        set((state) => {
          let updatedCurrentSteps = [...state.currentSteps];

          // find index of currentStep in currentSteps and remove all steps after it including currentStep
          const index = updatedCurrentSteps.findIndex(
            (step) => step === currentStep,
          );

          if (index !== -1) {
            updatedCurrentSteps.splice(index);
          }

          // Then we add the currentStep to the end of the array
          updatedCurrentSteps.push(currentStep);

          return {
            ...state,
            currentStep,
            currentSteps: [...updatedCurrentSteps],
          };
        }),
      setEmail: (email) => set((state) => ({ ...state, email })),
      setMobilePhoneNumber: (mobilePhoneNumber) =>
        set((state) => ({ ...state, mobilePhoneNumber })),
      setOrganisation: (organisation) =>
        set((state) => ({ ...state, organisation })),
      setReason: (reason) => set((state) => ({ ...state, reason })),
      setRequestedLoanAmount: (requestedLoanAmount) =>
        set((state) => ({ ...state, requestedLoanAmount })),
      setUnpaidInvoices: (unpaidInvoices) =>
        set((state) => ({ ...state, unpaidInvoices })),
      setUnpaidInvoicesDescription: (unpaidInvoicesDescription) =>
        set((state) => ({ ...state, unpaidInvoicesDescription })),
      setPropertyUnitDesignation: (propertyUnitDesignation) =>
        set((state) => ({ ...state, propertyUnitDesignation })),
      setRealEstateReason: (realEstateReason) =>
        set((state) => ({ ...state, realEstateReason })),
      setRealEstateRequestedLoanAmount: (realEstateRequestedLoanAmount) =>
        set((state) => ({ ...state, realEstateRequestedLoanAmount })),
      setLeasing: (leasing) => set((state) => ({ ...state, leasing })),
      setPreliminaryBid: (preliminaryBid) =>
        set((state) => ({ ...state, preliminaryBid })),
      setPreApplicationLead: (preApplicationLead) =>
        set((state) => ({ ...state, preApplicationLead })),
      setSsn: (ssn) => set((state) => ({ ...state, ssn })),
      setUtmCampaign: (utmCampaign) =>
        set((state) => ({ ...state, utmCampaign })),
      setRemoteIdentification: (remoteIdentification) => {
        return set((state) => ({
          ...state,
          remoteIdentification: {
            ...state.remoteIdentification,
            ...remoteIdentification,
          },
        }));
      },

      // clear functions
      clearSsn: () => set((state) => ({ ...state, ssn: initialState.ssn })),
      clearFormErrors: () =>
        set((state) => ({ ...state, formErrors: initialState.formErrors })),
      clearPreliminaryBid: () =>
        set((state) => ({
          ...state,
          preliminaryBid: initialState.preliminaryBid,
        })),
      clearRemoteIdentification: () => {
        return set((state) => ({
          ...state,
          remoteIdentification: initialState.remoteIdentification,
        }));
      },
      clearProductSpecificData: () => {
        const { unpaidInvoices, propertyUnitDesignation, leasing } =
          initialState;

        return set((state) => ({
          ...state,
          unpaidInvoices,
          propertyUnitDesignation,
          leasing,
        }));
      },

      // custom functions
      mergeFormState: (partialFormState) =>
        set((state) => ({ ...state, ...partialFormState })),
      removeFormError: (key) =>
        set((state) => {
          const { [key]: _, ...errors } = state.formErrors;

          return { ...state, formErrors: errors };
        }),

      // reset store
      resetFormState: () => set(initialState),
    }),
    {
      name: 'application-form',
      version: '1',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
