import loadable from '@loadable/component';
import { Helmet } from 'react-helmet-async';
import { Navigate, Route, Routes, Outlet } from 'react-router-dom';

import { settings } from '@krea/common/settings';

const WhitelistPage = loadable(() => import('../pages/settings/whitelist'));

const SettingsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<Navigate replace to="whitelist" />} />

        <Route
          path="whitelist"
          element={
            <>
              <Helmet
                title={`${settings.appTitle} Partner - Lender whitelist`}
              />
              <WhitelistPage />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default SettingsRouter;
