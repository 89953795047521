import classnames from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from '@krea/common/shared-components/text';

import styles from './StepperVertical.module.scss';

const StepperVertical = ({ className, title, steps }) => {
  const { t } = useTranslation();

  return (
    <div className={classnames(styles.root, className)}>
      {title && <h3 className="h4 mb-6">{title}</h3>}
      {steps && (
        <ul
          className={classnames(
            styles.list,
            'list-unstyled mb-0 h-100 position-relative',
          )}
        >
          {steps.map((item, index) => (
            <li
              key={index}
              className={classnames(
                styles.item,
                'd-flex align-items-start position-relative',
                {
                  [styles.active]: item.active,
                  [styles.done]: item.done,
                },
              )}
            >
              <div className="grow-1">
                <Text
                  size={item.active ? 'md' : 'sm'}
                  className={classnames('font-weight-bold', {
                    'text-black': item.active,
                    'text-gray-dark': !item.active,
                    'text-disabled': item.done,
                  })}
                  style={{ opacity: item.done ? 0.75 : 1 }}
                >
                  <Trans
                    t={t}
                    components={{
                      a: (
                        <Text
                          role="link"
                          tabIndex="0"
                          size={item.active ? 'md' : 'sm'}
                          htmlTag="span"
                          className="font-weight-bold text-primary"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            window.open(item.link, '_blank');
                          }}
                        />
                      ),
                    }}
                  >
                    {item.title}
                  </Trans>
                </Text>

                {item.description && item.description !== '' && (
                  <Text
                    size="sm"
                    className="mt-1 border rounded p-2 w-100 bg-gray"
                  >
                    {item.description}
                  </Text>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default StepperVertical;
