import classnames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

import Footer from '@krea/common/shared-components/app/footer';

import Header from '../header';

import styles from './Layout.module.scss';

const Layout = ({ children, menu, applicationSelector }) => {
  const { pathname } = useLocation();

  const isLoginOrRegisterPage = ['/login', '/register'].includes(pathname);
  const showSubHeader = pathname.includes(`/loan-applications/`);

  return (
    <div className="d-flex flex-column h-100 w-100 overflow-hidden">
      <Header
        menu={menu}
        applicationSelector={applicationSelector}
        showSubHeader={showSubHeader}
      />
      <main
        className={classnames(
          styles.main,
          { [styles.login]: isLoginOrRegisterPage || !showSubHeader },
          'flex-grow-1 shrink-0',
        )}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
