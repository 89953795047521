const COUNTRY_CODE = {
  SE: 'SE', // Sweden
  FI: 'FI', // Finland
};

const CURRENCY_BY_COUNTRY_CODE = {
  [COUNTRY_CODE.SE]: 'SEK',
  [COUNTRY_CODE.FI]: 'EUR',
};

const getCountryCode = () => {
  const topDomainToCountryMap = {
    '.se': COUNTRY_CODE.SE,
    '.fi': COUNTRY_CODE.FI,
  };

  try {
    const urlRegex = window.location.host.match(/(.se)|(.fi)|(localhost)/);
    const topDomainFromUrl = urlRegex && urlRegex[0];
    const countryCodeFromLocalStorage = localStorage.getItem('countryCode');

    // Set from top domain
    if (Object.keys(topDomainToCountryMap).includes(topDomainFromUrl)) {
      const countryCode = topDomainToCountryMap[topDomainFromUrl];
      localStorage.setItem('countryCode', countryCode);

      return countryCode;
    } else if (
      Object.values(topDomainToCountryMap).includes(countryCodeFromLocalStorage)
    ) {
      // If we are not on any domain in 'topDomainToCountryMap', then we are in some dev env
      // and we want to be able to control/set the country code as developer using local storage

      return countryCodeFromLocalStorage;
    } else {
      localStorage.setItem('countryCode', COUNTRY_CODE.SE);

      return COUNTRY_CODE.SE;
    }
  } catch (e) {
    console.error(
      'Error getting country code from top domain(or localStorage), defaulting to SE. Error: ',
      e,
    );

    return COUNTRY_CODE.SE;
  }
};

const getPrimaryLanguage = () => {
  if (getCountryCode() === COUNTRY_CODE.FI) {
    return 'fi';
  }

  return 'sv';
};

export {
  COUNTRY_CODE,
  CURRENCY_BY_COUNTRY_CODE,
  getCountryCode,
  getPrimaryLanguage,
};
