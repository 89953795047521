import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { settings } from '@krea/common/settings';
import LayoutOneColumn from '@krea/common/shared-components/app/one-column';
import Button from '@krea/common/shared-components/button';
import Container from '@krea/common/shared-components/container';
import LoginPage from '@krea/common/shared-components/login';
import LoginContainer from '@krea/common/shared-components/login/LoginContainer';
import { Text } from '@krea/common/shared-components/text';
import { useBankIDAuthenticate } from '@krea/common/store/bankID/hooks';
import { COUNTRY_CODE } from '@krea/common/utils';

const RegisterComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <LayoutOneColumn>
      <div className="d-flex flex-column justify-content-center align-items-center rounded border p-6">
        <LoginContainer
          title={t('register.newPartner', {
            applicationTitle: settings.appTitle,
          })}
        >
          <Button
            block
            style={{ maxWidth: 400 }}
            size="lg"
            justify="center"
            id="register"
            className="text-center"
            variant="outlined"
            onClick={() => navigate('/register')}
          >
            {t('register.heading')}
          </Button>
        </LoginContainer>
      </div>
    </LayoutOneColumn>
  );
};

const LoginComponent = ({ isFlowOpen, setIsFlowOpen }) => {
  const isFinnish = settings.countryCode === COUNTRY_CODE.FI;
  const { t } = useTranslation();

  if (!isFinnish || isFlowOpen) {
    return <LoginPage />;
  } else {
    return (
      <Container className="w-100 pt-6 pt-md-7 pt-xl-8">
        <div
          className="mx-auto rounded border p-6"
          style={{ maxWidth: '750px' }}
        >
          <LoginContainer
            title={t('login.heading', { applicationTitle: settings.appTitle })}
          >
            <Button
              block
              size="lg"
              justify="center"
              id="open-finnish-bankid"
              className="tw-text-center tw-mx-auto"
              variant="contained"
              disabled={isFlowOpen}
              onClick={() => setIsFlowOpen(true)}
            >
              {t('login.startBankId')}
            </Button>
          </LoginContainer>
        </div>
      </Container>
    );
  }
};

export const PartnerLoginPage = () => {
  const isFinnish = settings.countryCode === COUNTRY_CODE.FI;
  const [isFlowOpen, setIsFlowOpen] = useState(false);
  const { isNotInitiated } = useBankIDAuthenticate();

  return (
    <>
      <Container className="pt-6 pt-md-7 pt-xl-8">
        <div className="mx-auto" style={{ maxWidth: '750px' }}>
          <h1>{settings.appTitle} - Partner Portal</h1>

          <Text>
            <Trans i18nKey="login.description">
              Tillgång till rätt finansiering är ett hinder för många
              småföretagare. Tillsammans kan vi hjälpa dig och dina kunder att
              hitta rätt finansiering oavsett behov.&nbsp;
              <a
                target="_blank"
                href={
                  isFinnish
                    ? 'https://kredita.fi/kumppanit'
                    : 'https://krea.se/partner'
                }
                rel="noopener noreferrer"
                className="font-weight-bold"
              >
                Placeholder: Läs mer om fördelarna med vår partnerprogram.
              </a>
            </Trans>
          </Text>
        </div>
      </Container>

      <LoginComponent isFlowOpen={isFlowOpen} setIsFlowOpen={setIsFlowOpen} />

      {(!isFinnish && isNotInitiated) || (isFinnish && !isFlowOpen) ? (
        <RegisterComponent />
      ) : null}
    </>
  );
};
