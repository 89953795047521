import { useQuery } from '@tanstack/react-query';

import { USER_STORE_KEY, queryClient } from '@krea/common/store/queryClient';
import { fetchUserData } from '@krea/common/store/user/api';

const getQueryArguments = () => {
  return {
    queryKey: [USER_STORE_KEY],
    queryFn: async () => {
      try {
        const response = await fetchUserData();

        return response?.me || {};
      } catch (err) {
        console.error('Failed to fetch profile data: err', err);

        return {};
      }
    },
  };
};

export const useGetUserData = () => {
  const queryArguments = getQueryArguments();

  return useQuery({ ...queryArguments, staleTime: Infinity });
};

export const fetchUserQuery = async () => {
  const queryArguments = getQueryArguments();

  return queryClient.fetchQuery(queryArguments);
};
