import classnames from 'classnames';
import React, { useState } from 'react';

import { settings } from '@krea/common/settings';
import { Text } from '@krea/common/shared-components/text';
import { COUNTRY_CODE } from '@krea/common/utils';

import styles from './FIBankCredentials.module.scss';

const FIBankCredentialsItem = ({ id, title, logo, onClick }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      id={id}
      role="button"
      tabIndex="0"
      lang={settings.countryCode === COUNTRY_CODE.FI ? 'fi' : null}
      aria-label={title}
      className={classnames(
        styles.item,
        'd-flex flex-column align-items-center overflow-hidden border rounded p-3',
        {
          'border-primary': isHover,
        },
      )}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="grow-1 shrink-0 pb-3">
        <div
          className={classnames(
            styles.icon,
            'd-flex bg-white justify-content-center align-items-center',
          )}
        >
          <img src={logo} alt={title} />
        </div>
      </div>

      <Text
        size="sm"
        className="grow-0 shrink-0 font-weight-bold text-center w-100"
      >
        {title}
      </Text>
    </div>
  );
};

const FIBankCredentials = ({ bankCredentials, selectIdentityProvider }) => {
  return (
    <div className={classnames(styles.list, 'mt-7 mx-n2 mx-md-0')}>
      {bankCredentials.map((identityProvider) => (
        <FIBankCredentialsItem
          key={identityProvider.ftn_idp_id}
          id={identityProvider.ftn_idp_id}
          title={identityProvider.name}
          logo={identityProvider.image_url}
          onClick={() => selectIdentityProvider(identityProvider.ftn_idp_id)}
        />
      ))}
    </div>
  );
};

export default FIBankCredentials;
