import { useState, useEffect, useRef } from 'react';

export function useMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function windowResize() {
      if (window.matchMedia('(max-width: 1200px)').matches) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    windowResize();

    window.addEventListener('resize', windowResize);

    return () => {
      window.removeEventListener('resize', windowResize);
    };
  }, []);

  return isMobile;
}

export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export * from './useFormatCurrency';
export * from './useLeasingObjectsList';
export * from './useKlarnaBankingData';
export * from './useGetCurrentLoanApplicationID';
export * from './useDelayedAction';
