import classnames from 'classnames';
import React, { Fragment } from 'react';

const LoginContainer = ({ title, children }) => {
  return (
    <Fragment>
      {title && (
        <h2 className={classnames('h4 text-center ', { 'mb-6': children })}>
          {title}
        </h2>
      )}
      {children}
    </Fragment>
  );
};

export default LoginContainer;
