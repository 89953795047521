import { useQuery } from '@tanstack/react-query';

import { useGetCurrentLoanApplicationID } from '@krea/common/hooks';
import { CURRENT_LOAN_APPLICATION_STORE_KEY } from '@krea/common/store/queryClient';
import { ILoanApplication } from '@krea/common/types/loanApplication';

export const useGetCachedCurrentLoanApplication = () => {
  const loanApplicationID = useGetCurrentLoanApplicationID();

  return useQuery<ILoanApplication>({
    queryKey: [CURRENT_LOAN_APPLICATION_STORE_KEY, loanApplicationID],
    enabled: false,
  });
};
