import classnames from 'classnames';
import React from 'react';

import styles from './Dialog.module.scss';

const DialogActions = ({ children, className }) => {
  return (
    <div
      className={classnames('grow-0 shrink-0 px-6 pb-4 border-top', className)}
    >
      <div
        className={classnames(
          styles.actions,
          'd-flex justify-content-end align-items-center pt-4',
        )}
      >
        {children}
      </div>{' '}
    </div>
  );
};

export default DialogActions;
