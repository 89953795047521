import SVG from 'react-inlinesvg';
import classnames from 'classnames';

import PreloaderSVG from '@krea/common/images/svg/preloader.svg';

import styles from './Preloader.module.scss';

const Preloader = ({
  className,
  size = 'md',
}: {
  className: string;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}) => {
  return (
    <div
      className={classnames(
        'tw-flex tw-justify-center tw-items-center',
        className,
      )}
    >
      <div className={classnames(styles.icon, styles[size], 'tw-relative')}>
        <SVG className="tw-absolute tw-w-full tw-h-auto" src={PreloaderSVG} />
      </div>
    </div>
  );
};

export default Preloader;
