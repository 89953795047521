// Source: https://plainjs.com/javascript/utilities/set-cookie-get-cookie-and-delete-cookie-5/

export function getCookie(name) {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');

  return v ? v[2] : null;
}

export const getCookieDomain = () => {
  if (window.location.hostname.includes('localhost')) {
    return 'localhost';
  } else if (window.location.hostname.includes('fakturino.se')) {
    return '.fakturino.se';
  } else if (window.location.hostname.includes('krea.se')) {
    return '.krea.se';
  } else if (window.location.hostname.includes('kredita.fi')) {
    return '.kredita.fi';
  }

  return '';
};

const mutateCookie = (name, value, date) => {
  const domainPart = `;domain=${getCookieDomain()}`;
  document.cookie =
    name +
    '=' +
    value +
    ';path=/;expires=' +
    date.toGMTString() +
    `;domain=${domainPart}`;
};

export function setCookie(name, value, days = 1) {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);

  mutateCookie(name, value, d);
}

export function deleteCookie(name) {
  // Expire today with value = " " deletes the cookie
  mutateCookie(name, ' ', new Date());
}
