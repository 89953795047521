import { useIsMutating } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import StepperVertical from '@krea/common/shared-components/stepper-vertical';
import { useGetCachedCurrentLoanApplication } from '@krea/common/store/current-loan-application/hooks';
import { SUBMIT_APPLICATION_STORE_KEY } from '@krea/common/store/queryClient';
import { LOAN_APPLICATION_STATES } from '@krea/common/utils';

const LoanApplicationFlowDescription = () => {
  const { t } = useTranslation();
  const isSubmitting = useIsMutating({
    mutationKey: [SUBMIT_APPLICATION_STORE_KEY],
  });
  const { data: { state } = {} } = useGetCachedCurrentLoanApplication();

  const isApplicationSent = () => {
    return (
      state === LOAN_APPLICATION_STATES.CREATED ||
      state === LOAN_APPLICATION_STATES.ADVICE ||
      state === LOAN_APPLICATION_STATES.OPEN_FOR_BIDS
    );
  };

  const steps = [
    {
      title: t('iaq.applicationPreview.steps.common.reviewAndSubmit'),
      active: state === LOAN_APPLICATION_STATES.DRAFT && !isSubmitting,
      done: isSubmitting || isApplicationSent(),
    },
    {
      title: t('iaq.applicationPreview.steps.common.creditCheck'),
      active: state === LOAN_APPLICATION_STATES.DRAFT && isSubmitting,
      done: isApplicationSent(),
    },
    {
      title: t('iaq.applicationPreview.steps.common.improveApplication'),
      active:
        state === LOAN_APPLICATION_STATES.ADVICE ||
        state === LOAN_APPLICATION_STATES.OPEN_FOR_BIDS,
      done: false,
    },
    {
      title: t('iaq.applicationPreview.steps.common.receiveFunds'),
      active: false,
      done: false,
    },
  ];

  return <StepperVertical steps={steps} />;
};

export default LoanApplicationFlowDescription;
