import classnames from 'classnames';

export const ToastRoot = ({
  children,
  className,
  isWarning,
  excludeBorder,
}) => {
  return (
    <>
      <div
        className={classnames(
          'border rounded d-flex mb-5 p-3 py-xl-4 px-xl-5 justify-content-between',
          {
            'border-gray-light': !excludeBorder && !isWarning,
            'border-warning': !excludeBorder && isWarning,
          },
          className,
        )}
      >
        {children}
      </div>
    </>
  );
};
