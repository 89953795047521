import classnames from 'classnames';
import { debounce } from 'lodash';
import React, {
  useRef,
  useReducer,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { withTranslation } from 'react-i18next';
import sweValidation from 'swe-validation';

import { settings } from '@krea/common/settings';
import { Text } from '@krea/common/shared-components/text';
import { COUNTRY_CODE, TEST_IDS } from '@krea/common/utils';

import TextInput from '../../../fields/input';
import styles from '../../AppForm.module.scss';

const OrganisationNumberInput = (props) => {
  const countryCode = settings.countryCode;
  const {
    value,
    touched,
    errors,
    label,
    setInputValue,
    t,
    isLimitedCompany,
    onBlur,
  } = props;

  function reducer(state, action) {
    switch (action.type) {
      case 'isFocusedOnSearch':
        return { ...state, isFocusedOnSearch: action.payload };
      case 'hasDuplicates':
        return { ...state, hasDuplicates: action.payload };
      case 'isCompanySelected':
        return { ...state, isCompanySelected: action.payload };
      case 'isSearching':
        return { ...state, isSearching: action.payload };
      case 'companyResults':
        return { ...state, companyResults: action.payload };
      default:
        throw new Error();
    }
  }

  const [state, setState] = useReducer(reducer, {
    isFocusedOnSearch: false,
    hasDuplicates: false,
    isCompanySelected: false,
    isSearching: false,
    companyResults: [],
  });

  const {
    isFocusedOnSearch,
    hasDuplicates,
    isCompanySelected,
    isSearching,
    companyResults,
  } = state;

  const [companyResultsItems, setCompanyResultsItems] = useState([]);

  const companyInputRef = useRef();
  const resultsRef = useRef();

  const companySearch = debounce(async (query) => {
    const { isSearching } = state;
    let shouldSearch = false;

    if (query) {
      /**
       * This contains logic to meet the requirements for the search endpoints for the different countries.
       * When it comes to min length of the query, and depending on if its orgNum or orgName search,
       * it's not the same for all countries.
       * To try/test search capabilities, to that here https://connect-portal.creditsafe.com/.
       */
      const trimmedQuery = query.replaceAll('-', '');
      let isOnlyNumbers = /^\d+$/.test(trimmedQuery);

      switch (settings.countryCode) {
        case COUNTRY_CODE.SE:
          if (isOnlyNumbers) {
            if (trimmedQuery.length >= 6) {
              shouldSearch = true;
            }
          } else {
            if (query.length >= 3) {
              shouldSearch = true;
            }
          }
          break;
        case COUNTRY_CODE.FI:
          if (isOnlyNumbers) {
            if (trimmedQuery.length >= 1) {
              shouldSearch = true;
            }
          } else {
            if (query.length >= 2) {
              shouldSearch = true;
            }
          }
          break;
        default:
          break;
      }
    }

    if (shouldSearch) {
      if (!isSearching) {
        setState({ type: 'isSearching', payload: true });
      }

      const response = await fetch(
        `${settings.kreaBaseUrl}/company-data/api/v1/company-search?country_code=${countryCode}&query=${query}`,
        {
          headers: { 'Content-Type': 'application/json' },
          credentials: 'same-origin',
        },
      );

      setState({ type: 'isSearching', payload: false });

      if (response.ok) {
        setCompanyResultsItems([]);
        const companyResults = await response.json();

        const hasDuplicates =
          companyResults
            .slice()
            .map((company) => company.company_name.toLowerCase())
            .filter((v, i, a) => a.indexOf(v) !== i).length > 0;

        setState({ type: 'companyResults', payload: companyResults });
        setState({ type: 'hasDuplicates', payload: hasDuplicates });
      }
    }
  }, 300);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCompanySearch = useCallback(
    (company) => companySearch(company),
    [],
  );

  useEffect(() => {
    document.addEventListener('click', handeSearchFocusOrBlur);

    return () => {
      document.removeEventListener('click', handeSearchFocusOrBlur);
    };
  }, []);

  const onCompanyChange = ({ target }) => {
    let companyInputValue = target.value ? target.value : '';

    setInputValue('organisation.name', companyInputValue);

    let validOrgNumber = null;

    if (countryCode === COUNTRY_CODE.SE) {
      validOrgNumber =
        sweValidation.isCin(companyInputValue) ||
        sweValidation.isSSn(companyInputValue);

      if (validOrgNumber) {
        // Here we know "early" that we have a valid org number.
        setState({ type: 'isCompanySelected', payload: true });
        companyInputValue = validOrgNumber;
      }
    }

    setInputValue(
      'organisation.organisationNumber',
      validOrgNumber || companyInputValue,
    );

    if (companyInputValue.trim().length > 0) {
      debouncedCompanySearch(companyInputValue);

      let shouldFetchCompanyData = false;

      if (countryCode === COUNTRY_CODE.SE) {
        const parsedForOrganizationNumber = companyInputValue
          .slice()
          .replace(/[^0-9]/g, '');

        if (validOrgNumber) {
          shouldFetchCompanyData = true;
          companyInputValue = parsedForOrganizationNumber;
        }
      } else if (countryCode === COUNTRY_CODE.FI) {
        if (
          companyInputValue.length >= 8 &&
          !isNaN(companyInputValue.replaceAll('-', ''))
        ) {
          shouldFetchCompanyData = true;
        }
      }

      if (shouldFetchCompanyData) {
        selectCompany({ organisation_number: companyInputValue });
      }
    } else {
      setState({ type: 'companyResults', payload: [] });
    }
  };

  const onCompanyReset = () => {
    setInputValue('organisation.name', '');
    setInputValue('organisation.organisationNumber', '');

    const resetFunc = async () => {
      await setState({ type: 'isCompanySelected', payload: false });

      const inputOnFocus = () => {
        if (companyInputRef.current) {
          setTimeout(
            () => companyInputRef.current.querySelector('input').focus(),
            0,
          );
        }
      };

      await inputOnFocus();
    };
    resetFunc();
  };

  const onCompanyInputKeyDown = (event) => {
    const { 0: firstItem } = companyResultsItems;

    switch (event.key) {
      case 'Tab':
        if (event.shiftKey || !firstItem) {
          setState({ type: 'isFocusedOnSearch', payload: false });
        }

        break;
      case 'ArrowDown':
        event.preventDefault();

        if (firstItem) {
          firstItem.focus();
        }

        break;
      default:
        break;
    }
  };

  const onCompanyItemKeyDown = (event) => {
    const index = companyResultsItems.indexOf(event.target);

    const { length: resultsLength } = state.companyResults;

    const goToNextItem = () => {
      const nextIndex = index + 1;

      if (resultsLength > nextIndex) {
        event.preventDefault();
        companyResultsItems[index + 1].focus();
      } else {
        setState({ type: 'isFocusedOnSearch', payload: false });
      }
    };

    const goToPreviousItem = () => {
      event.preventDefault();

      const previousIndex = index - 1;

      if (previousIndex === -1) {
        companyInputRef.current.querySelector('input').focus();
      } else {
        companyResultsItems[previousIndex].focus();
      }
    };

    switch (event.key) {
      case 'Tab':
        event.shiftKey ? goToPreviousItem() : goToNextItem();
        break;
      case 'ArrowDown':
        goToNextItem();
        break;
      case 'ArrowUp':
        goToPreviousItem();
        break;
      case 'Enter':
        selectCompany(state.companyResults[index]);
        break;
      default:
        break;
    }
  };

  const formatOrgNumToPost = (organisationNumber) => {
    if (organisationNumber) {
      if (
        countryCode === COUNTRY_CODE.FI &&
        organisationNumber.length === 8 &&
        !isNaN(organisationNumber)
      ) {
        // We have a 8 digit num as string. lets add a dash before the last number.
        return `${organisationNumber.slice(0, organisationNumber.length - 1)}-${organisationNumber.slice(
          organisationNumber.length - 1,
          organisationNumber.length,
        )}`;
      }
    }

    return organisationNumber;
  };

  const selectCompany = async (company) => {
    setInputValue(
      'organisation.name',
      company.company_name || company.organisation_number || '',
    );

    if (!company || (!company.company_name && !company.organisation_number)) {
      setState({ type: 'isCompanySelected', payload: false });
    } else {
      let foundCompany = null;

      if (!company.company_name && company.organisation_number) {
        // A full org number was entered in form. Do a "quick lookup on org_num" to populate name and org (if there is a match)

        const response = await fetch(
          `${settings.kreaBaseUrl}/company-data/api/v1/company-search?country_code=${countryCode}&query=${company.organisation_number}`,
          {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'same-origin',
          },
        );

        if (response.ok) {
          const instantCompanyResults = await response.json();
          foundCompany = instantCompanyResults.find(
            (it) =>
              it.organisation_number ===
              company.organisation_number.replaceAll('-', ''),
          );
        }
      }

      if (foundCompany) {
        // company using  "quick lookup" above was found, use that.
        setInputValue(
          'organisation.organisationNumber',
          formatOrgNumToPost(foundCompany.organisation_number) || '',
        );
        setInputValue('organisation.name', foundCompany.company_name || '');
      } else {
        setInputValue(
          'organisation.organisationNumber',
          formatOrgNumToPost(company.organisation_number) || '',
        );

        if (company.company_name) {
          // If company name existed it means user selected a "complete" item from the auto complete list
          setInputValue('organisation.name', company.company_name || '');
        } else {
          // This happens if no match was found on server but a full "valid" org num was supplied. like 1212121212
          setInputValue(
            'organisation.name',
            formatOrgNumToPost(company.organisation_number) || '',
          );
        }
      }

      setState({ type: 'isCompanySelected', payload: true });
      setState({ type: 'companyResults', payload: [] });
    }
  };

  const setFocusedOnSearch = () => {
    setState({ type: 'isFocusedOnSearch', payload: true });
  };

  const handeSearchFocusOrBlur = (e) => {
    const isFocusedOnSearch =
      (companyInputRef.current && companyInputRef.current.contains(e.target)) ||
      (resultsRef.current && resultsRef.current.contains(e.target)) ||
      false;

    setState({ type: 'isFocusedOnSearch', payload: isFocusedOnSearch });
  };

  useEffect(() => {
    const { name, organisationNumber } = value;

    if (name === '' && organisationNumber === '') {
      setState({ type: 'isCompanySelected', payload: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <TextInput
      data-test-id={TEST_IDS.common.forms.organizationNumberInput}
      tabIndex="0"
      size="lg"
      type="text"
      name="organisationNumber"
      id="company"
      forwardRef={companyInputRef}
      className={styles.input}
      onChange={onCompanyChange}
      onBlur={onBlur}
      value={
        isCompanySelected && value.organisationNumber
          ? `${value.name} (${value.organisationNumber})`
          : value.name || ''
      }
      placeholder={t(
        `applicationForm.commons.companyNamePlaceholder.${countryCode}`,
      )}
      label={label || t('applicationForm.commons.companyNameLabel')}
      onFocus={setFocusedOnSearch}
      readOnly={isCompanySelected}
      onKeyDown={onCompanyInputKeyDown}
      error={
        !isFocusedOnSearch &&
        ((touched?.organisationNumber && errors?.organisationNumber) ||
          (touched?.name && errors?.name))
          ? errors.organisationNumber || errors?.name
          : null
      }
      confirmation={
        isLimitedCompany
          ? t('applicationForm.common.limitedCompanyConfirmation')
          : null
      }
      showConfirmation={isLimitedCompany && !isFocusedOnSearch}
      autoComplete="off"
      textAppend={
        isCompanySelected && (
          <Text
            size="sm"
            role="button"
            className="tw-text-primary tw-py-1 tw-px-2 tw-rounded tw-bg-gray tw-font-bold tw-z-50"
            style={{
              cursor: 'pointer',
              transition: 'background-color 0.3s ease 0s, color 0.3s ease 0s',
              borderBottom: '1px solid #fbf2ff',
            }}
            onClick={onCompanyReset}
            aria-label={t('applicationForm.commons.companyNameChange')}
            tabIndex="0"
          >
            {t('applicationForm.commons.companyNameChange')}
          </Text>
        )
      }
      autoselect={
        isFocusedOnSearch &&
        value.name &&
        value.name.length > 0 &&
        !isCompanySelected && (
          <div
            className={classnames(
              styles.results,
              'tw-absolute tw-bg-white tw-border tw-rounded tw-w-full tw-mt-4 tw-overflow-hidden cursor-pointer',
            )}
            ref={resultsRef}
          >
            <ul
              role="listbox"
              tabIndex="0"
              className="tw-list-none tw-m-0 tw-p-0"
            >
              {isSearching ? (
                <li
                  className={classnames(
                    styles.searching,
                    'tw-text-center tw-p-3',
                  )}
                >
                  {t('applicationForm.commons.companyNamePreloader')}.
                </li>
              ) : (
                <>
                  {companyResults.map((r, index) => (
                    <li
                      role="option"
                      aria-selected="false"
                      tabIndex="0"
                      className={classnames(
                        'tw-flex tw-justify-between tw-overflow-hidden tw-py-2 tw-px-4 tw-relative tw-text-ellipsis tw-cursor-pointer',
                        'tw-bg-white hover:tw-bg-violet-900 hover:color-white tw-text-[0px] tw-leading-[0px] tw-outline-none hover:tw-text-white',
                      )}
                      onKeyDown={onCompanyItemKeyDown}
                      ref={(ref) => (companyResultsItems[index] = ref)}
                      key={`${r.company_name}-${index}`}
                      onClick={() => selectCompany(r)}
                      data-company-name={r.company_name}
                      aria-label={r.company_name}
                      data-test-id={TEST_IDS.common.forms.companyResult(index)}
                    >
                      <div
                        className={classnames(
                          styles.companyName,
                          'tw-w-full tw-pr-4 tw-pt-1 tw-text-ellipsis',
                        )}
                      >
                        {r.company_name}
                      </div>
                      <Text
                        size="sm"
                        className={classnames(
                          styles.resultselect,
                          'tw-font-bold tw-rounded tw-py-1 tw-px-2 tw-shrink-0 n-result-select',
                        )}
                      >
                        {t('applicationForm.commons.companyNameSelect')}
                      </Text>
                    </li>
                  ))}
                </>
              )}
            </ul>

            <div
              className={classnames(
                styles.notresults,
                'tw-p-3 tw-text-center n-not-finding',
                {
                  [styles.hasDuplicates]: hasDuplicates,
                },
              )}
            >
              {t('applicationForm.commons.companyNameFindHelp')}
            </div>
          </div>
        )
      }
    />
  );
};

export default withTranslation()(OrganisationNumberInput);
