import { client } from '@krea/common/graphql';

export const fetchUserData = async () => {
  return client.request(`{
      me {
        id,
        email,
        firstName,
        lastName,
        mobilePhoneNumber,
        preferredLanguage,
        isAdmin,
        currentLoanApplication {
          id
        },
        customerCompanies {
          id,
          name
        },
        partnerOrganisation {
          customerCompany {
            name
          },
          type,
          utmSourceIdentifier,
          utmCampaigns,
          isActive
        }
      }
    }`);
};
