import { settings } from '@krea/common/settings';

import { APPLICATION_NAME } from './index';

const ADVICE_CATEGORY = {
  SIMPLE: 'SIMPLE',
  MODERATELY_COMPLEX: 'MODERATELY_COMPLEX',
  COMPLEX: 'COMPLEX',
};

const ALLOWED_STATES_FOR_DASHBOARD = [
  'CREATED',
  'OPEN_FOR_BIDS',
  'REJECTED',
  'SUBJECT_TO_EU_SANCTIONS',
  'NO_BIDS_RECEIVED',
  'BIDS_REJECTED_BY_CUSTOMER',
  'BID_SELECTED',
  'PAYOUT_RECEIVED_BY_CUSTOMER',
];

const ALLOWED_STATES_FOR_SELECTED_BID = [
  'BID_SELECTED',
  'PAYOUT_RECEIVED_BY_CUSTOMER',
];

const LOAN_APPLICATION_STATES = {
  DRAFT: 'DRAFT',
  CREATED: 'CREATED',
  ADVICE: 'ADVICE',
  OPEN_FOR_BIDS: 'OPEN_FOR_BIDS',
  REJECTED: 'REJECTED',
  SUBJECT_TO_EU_SANCTIONS: 'SUBJECT_TO_EU_SANCTIONS',
  NO_BIDS_RECEIVED: 'NO_BIDS_RECEIVED',
  BIDS_REJECTED_BY_CUSTOMER: 'BIDS_REJECTED_BY_CUSTOMER',
  BID_SELECTED: 'BID_SELECTED',
  PAYOUT_RECEIVED_BY_CUSTOMER: 'PAYOUT_RECEIVED_BY_CUSTOMER',
};

const LOAN_APPLICATION_STATES_WITH_DISABLED_PREVIEW_EDIT = [
  'REJECTED',
  'SUBJECT_TO_EU_SANCTIONS',
  'NO_BIDS_RECEIVED',
  'BIDS_REJECTED_BY_CUSTOMER',
  'BID_SELECTED',
  'PAYOUT_RECEIVED_BY_CUSTOMER',
];

const LOAN_APPLICATION_VERIFICATION_FAILURE_REASONS = {
  NOT_ELIGIBLE_FOR_HELP: 'NOT_ELIGIBLE_FOR_HELP',
  DUPLICATE_EXISTS: 'DUPLICATE_EXISTS',
  NO_COMPANY_DATA: 'NO_COMPANY_DATA',
  SUBJECT_TO_EU_SANCTIONS: 'SUBJECT_TO_EU_SANCTIONS',
  IS_PEP: 'IS_PEP',
  NO_SIGNING_RIGHTS: 'NO_SIGNING_RIGHTS',
  NO_CREDIT_CHECK: 'NO_CREDIT_CHECK',
};

const PRODUCT_TYPE = {
  COMPANY_LOAN: 'COMPANY_LOAN',
  FACTORING: 'FACTORING',
  REAL_ESTATE: 'REAL_ESTATE',
  LEASING: 'LEASING',
};

const LOAN_APPLICATION_FORM_PAGES = {
  [PRODUCT_TYPE.COMPANY_LOAN]: '/direct/loan-application-form',
  [PRODUCT_TYPE.FACTORING]: '/direct/factoring-application-form',
  [PRODUCT_TYPE.REAL_ESTATE]: '/direct/real-estate-application-form',
  [PRODUCT_TYPE.LEASING]: '/direct/leasing-application-form',
};

const PARTNER_WEB_LOAN_APPLICATION_FORM_PAGES = {
  [PRODUCT_TYPE.COMPANY_LOAN]: '/new/loan-application-form',
  [PRODUCT_TYPE.FACTORING]: '/new/factoring-application-form',
  [PRODUCT_TYPE.REAL_ESTATE]: '/new/real-estate-application-form',
  [PRODUCT_TYPE.LEASING]: '/new/leasing-application-form',
};

const getProductByRoute = (pathname) => {
  switch (settings.appName) {
    case APPLICATION_NAME.CUSTOMER_WEB:
      const products = Object.keys(LOAN_APPLICATION_FORM_PAGES);

      return (
        products.find((key) =>
          pathname.includes(LOAN_APPLICATION_FORM_PAGES[key]),
        ) || PRODUCT_TYPE.COMPANY_LOAN
      );

    case APPLICATION_NAME.PARTNER_WEB:
      return (
        Object.keys(PARTNER_WEB_LOAN_APPLICATION_FORM_PAGES).find((key) =>
          pathname.includes(PARTNER_WEB_LOAN_APPLICATION_FORM_PAGES[key]),
        ) || PRODUCT_TYPE.COMPANY_LOAN
      );

    default:
      return null;
  }
};

export {
  ADVICE_CATEGORY,
  ALLOWED_STATES_FOR_DASHBOARD,
  ALLOWED_STATES_FOR_SELECTED_BID,
  LOAN_APPLICATION_FORM_PAGES,
  LOAN_APPLICATION_STATES,
  LOAN_APPLICATION_STATES_WITH_DISABLED_PREVIEW_EDIT,
  LOAN_APPLICATION_VERIFICATION_FAILURE_REASONS,
  PRODUCT_TYPE,
  getProductByRoute,
};
