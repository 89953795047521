import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useMobile } from '@krea/common/hooks';
import Button from '@krea/common/shared-components/button';

import { useGetLoanApplications } from 'store/loan-applications/hooks/queries';

import styles from './Applications.module.scss';
import { getColumns, TableTD, TableTH, getMobileTD } from './helpers';

const ApplicationsList = ({ filter, sortParam, searchTerm, setSort }) => {
  const isMobile = useMobile();
  const { t } = useTranslation();

  const {
    data: { pages = [] } = {},
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useGetLoanApplications({ filter, sortParam, searchTerm });

  const COLUMNS = getColumns(t);

  const loanApplications = pages?.flatMap((page) => page.results) || [];
  const currentLoanApplicationsCount = loanApplications.length;

  const { column: sortColumn, direction: sortDirection } = sortParam;

  const setSorting = (column) => {
    const oppositeDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    const direction = column === sortColumn ? oppositeDirection : 'asc';

    setSort({ column, direction });
  };

  return (
    <>
      {!isMobile ? (
        <div className="d-block w-100 rounded border overflow-hidden my-6">
          <div className="d-block w-100 overflow-auto">
            <table className="w-100 h-100">
              <thead>
                <tr>
                  {COLUMNS.map(({ columnName, sortable, label }) => (
                    <TableTH
                      key={`header-${columnName}`}
                      columnName={columnName}
                      sortable={sortable}
                      sortColumn={sortColumn}
                      setSorting={setSorting}
                    >
                      {label}
                    </TableTH>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loanApplications.map((item) => (
                  <tr
                    key={item.id}
                    className={classnames({
                      'border-bottom':
                        item !==
                        loanApplications[currentLoanApplicationsCount - 1],
                    })}
                  >
                    {COLUMNS.map(({ columnName, noWrap, getValue }) => (
                      <TableTD
                        key={`body-${columnName}`}
                        className={classnames({ 'text-nowrap': noWrap })}
                      >
                        {getValue(item)}
                      </TableTD>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <>
          {loanApplications.map((item) => (
            <div
              key={item.id}
              className={classnames(
                'border rounded p-4 px-md-6 py-md-5 mt-4 mt-md-6',
              )}
            >
              <div className={classnames(styles.mobileCard)}>
                {COLUMNS.map((column) => getMobileTD(item, column))}
              </div>
            </div>
          ))}
        </>
      )}

      {hasNextPage ? (
        <div className="d-flex justify-content-center mt-6 mt-xl-0">
          <Button
            variant="outlined"
            disabled={isFetching}
            onClick={() => {
              fetchNextPage();
            }}
          >
            {isFetching ? (
              <div>{t('listApplicationsView.loadingApplicationsText')}</div>
            ) : (
              <div className="text-nowrap">
                {t('listApplicationsView.fetchMore')}
              </div>
            )}
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default ApplicationsList;
