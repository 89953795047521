import classnames from 'classnames';
import twMerge from '@krea/common/utils/tailwind-utils';
import { PropsWithChildren } from 'react';

import { useMobile } from '@krea/common/hooks';
import { useGetOneFieldRequest } from '@krea/common/store/field-requests/hooks/queries';

const LayoutOneColumnHeader = ({
  title,
  children,
  className,
  isStepPage,
  step,
}: PropsWithChildren<{
  title?: string | null;
  className?: string;
  isStepPage?: boolean;
  step?: any;
}>) => {
  const isMobile = useMobile();
  const { data: { displayName } = {} } = useGetOneFieldRequest(step?.id);

  const pageTitle = displayName || title;

  return (
    <div
      className={twMerge(
        'tw-flex tw-flex-col xl:tw-flex-row tw-items-center xl:tw-items-start xl:tw-justify-between',
        { 'mb-6': !isStepPage, 'mb-4': isStepPage },
        className,
      )}
    >
      {!isStepPage ? (
        <h1
          className={classnames(
            { h3: isMobile },
            'tw-flex-grow tw-text-center lg:tw-text-left tw-mb-0 tw-p-0',
          )}
        >
          {pageTitle}
        </h1>
      ) : (
        <h4 className="tw-flex-grow tw-text-center lg:tw-text-left tw-mb-0 tw-p-0">
          {pageTitle}
        </h4>
      )}

      {children && (
        <div className="tw-shrink-0 tw-mt-5 xl:tw-mt-2 xl:tw-ml-4">
          {children}
        </div>
      )}
    </div>
  );
};

export default LayoutOneColumnHeader;
