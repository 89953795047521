import axios from 'axios';
import HttpService from '@krea/common/services/httpService';

const BASE_URL = '/api/v1/se/bank-id';

const AUTHENTICATE_URL = `${BASE_URL}/authenticate`;
const COLLECT_URL = `${BASE_URL}/collect`;
const CANCEL_URL = `${BASE_URL}/cancel`;

const instanceWithoutInterceptors =
  HttpService.createInstanceWithoutInterceptors();

export const authenticateViaBankID = async (ssn) => {
  return instanceWithoutInterceptors.post(AUTHENTICATE_URL, { ssn });
};

export const collectViaBankID = async ({
  orderRef,
  extraData,
  identificationContext,
  authenticationContext,
}) => {
  return instanceWithoutInterceptors.post(COLLECT_URL, {
    orderRef,
    extraData,
    identificationContext,
    authenticationContext,
  });
};

export const cancelViaBankID = async (orderRef) => {
  return instanceWithoutInterceptors.post(CANCEL_URL, { orderRef });
};
