import twMerge from '@krea/common/utils/tailwind-utils';
import React, { HTMLAttributes } from 'react';

import styles from './Text.module.scss';

interface ITextProps extends HTMLAttributes<HTMLElement> {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  htmlTag?:
    | 'div'
    | 'span'
    | 'p'
    | 'section'
    | 'dl'
    | 'dt'
    | 'dd'
    | 'label'
    | 'li';
  className?: string;
  children?: React.ReactNode;
  htmlFor?: string;
}

export const Text = ({
  size = 'md',
  children,
  className,
  htmlTag = 'div',
  htmlFor,
  ...rest
}: ITextProps) => {
  return React.createElement(
    htmlTag,
    { className: twMerge(styles.root, className, styles[size]), ...rest },
    children,
  );
};
