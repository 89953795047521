import twMerge from '@krea/common/utils/tailwind-utils';
import React, { PropsWithChildren, HTMLAttributes } from 'react';

type HTMLTag = 'div' | 'span' | 'section';
interface IContainerProps<T extends HTMLTag> extends HTMLAttributes<T> {
  className?: string;
  fluid?: boolean;
  htmlTag?: T;
}

const Container = <T extends HTMLTag>({
  htmlTag,
  fluid,
  children,
  className,
  ...rest
}: PropsWithChildren<IContainerProps<T>>) => {
  return React.createElement(
    htmlTag || 'div',
    {
      className: twMerge(
        { container: !fluid, 'container-fluid': fluid },
        className,
      ),
      ...rest,
    },
    children,
  );
};

export default Container;
