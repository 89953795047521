export const getRedirectPathFromLocalStorage = () => {
  try {
    const redirectProps = window.localStorage.getItem('redirect');

    if (redirectProps) {
      const { date, minuteDuration, path } = JSON.parse(redirectProps);
      const dateNow = new Date().getTime();
      const durationInMinutes = minuteDuration * 60 * 1000;
      const hasNotExpired = dateNow - date <= durationInMinutes;

      if (hasNotExpired && path) {
        window.localStorage.removeItem('redirect');

        return path as string;
      }
    }

    return '/';
  } catch (e) {
    return '/';
  }
};
