import classnames from 'classnames';
import { Element } from 'react-scroll';

import Container from '@krea/common/shared-components/container';

import LoginLayout from './LoginLayout';

const LoginWrapper = ({ children }) => {
  return (
    <div className="py-6 py-md-7 py-xl-8">
      <Container>
        <div className="d-flex flex-column flex-xl-row align-items-center justify-content-xl-center align-items-xl-start">
          <div className="w-100" style={{ maxWidth: '750px' }}>
            <Element
              name="applicationForm"
              className={classnames('d-flex justify-content-center')}
            >
              <LoginLayout>{children}</LoginLayout>
            </Element>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LoginWrapper;
