import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '@krea/common/shared-components/button';
import { Text } from '@krea/common/shared-components/text';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';

import Container from '@krea/common/shared-components/container';

const ErrorBlock = ({ status, title, description }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: user = {} } = useGetUserData();

  return (
    <div className="py-12">
      <Container>
        <div className="d-flex flex-column align-items-center py-15">
          {title && (
            <h1 className="h2 text-center">
              {status ? `${status} - ${title}` : title}
            </h1>
          )}

          {description && (
            <Text size="lg" className="font-weight-bold mt-6 mb-8 text-center">
              {description}
            </Text>
          )}

          <Button
            size="lg"
            variant="outlined"
            onClick={() => navigate(isEmpty(user) ? '/' : -1)}
          >
            {isEmpty(user)
              ? t('errors.404.cta.notUser')
              : t('errors.404.cta.user')}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default ErrorBlock;
