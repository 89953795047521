import loadable from '@loadable/component';
import React, { Suspense } from 'react';

import { FieldRequestsFormsImports } from '@krea/common/shared-components/field-request/forms';
import Preloader from '@krea/common/shared-components/preloader';
import { useGetCachedCurrentLoanApplication } from '@krea/common/store/current-loan-application/hooks';
import { useGetOneFieldRequest } from '@krea/common/store/field-requests/hooks/queries';
import { PRODUCT_TYPE, LOAN_APPLICATION_STATES } from '@krea/common/utils';
import { ILoanApplication } from '@krea/common/types/loanApplication';

const FieldRequestCollectedByLender = loadable(
  () =>
    import(
      '@krea/common/shared-components/field-request/FieldRequestCollectedByLender'
    ),
);

export const FieldRequestForm = ({
  fieldRequestID,
  initType = null,
  isPreviewMode = false,
  withInputLabel = false,
  isDelegated = false,
  isBidSelected = false,
  hasParentFieldRequest = false,
}: {
  fieldRequestID: number;
  initType?: string | null;
  isPreviewMode?: boolean;
  withInputLabel?: boolean;
  isDelegated?: boolean;
  isBidSelected?: boolean;
  hasParentFieldRequest?: boolean;
}) => {
  const { data: currentLoanApplication = {} } =
    useGetCachedCurrentLoanApplication();
  const { data: { field, isCollectedByLender, displayName } = {} } =
    useGetOneFieldRequest(fieldRequestID, isDelegated);

  const {
    product,
    state: applicationState,
    eligibleForLeasingBids,
  } = currentLoanApplication as ILoanApplication;

  const getCurrentFieldRequestForm = () => {
    const options = {
      applicationState,
      fieldRequestID,
      isPreviewMode,
      withInputLabel,
      isDelegated,
      isBidSelected,
      isRealEstate: product === PRODUCT_TYPE.REAL_ESTATE,
      isLeasing: product === PRODUCT_TYPE.LEASING,
      eligibleForLeasingBids,
      initType,
      hasParentFieldRequest,
      isAdviceState: applicationState === LOAN_APPLICATION_STATES.ADVICE,
    };

    if (FieldRequestsFormsImports.hasOwnProperty(field)) {
      return (
        <Suspense fallback={<Preloader className="tw-w-full tw-py-4" />}>
          {isCollectedByLender ? (
            <FieldRequestCollectedByLender
              displayName={displayName}
              withInputLabel={withInputLabel}
            />
          ) : (
            React.createElement(
              (
                FieldRequestsFormsImports as Record<
                  string,
                  React.ComponentType<any>
                >
              )[field],
              {
                ...options,
              },
            )
          )}
        </Suspense>
      );
    } else {
      return null;
    }
  };

  return getCurrentFieldRequestForm();
};
