import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames';
import { useCallback, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { EmailValidation } from '@krea/common/form-validation/EmailValidation';
import { OrganisationValidation } from '@krea/common/form-validation/OrganisationValidation';
import { PhoneNumberValidation } from '@krea/common/form-validation/PhoneNumberValidation';
import { useMobile } from '@krea/common/hooks';
import { settings } from '@krea/common/settings';
import LayoutOneColumn from '@krea/common/shared-components/app/one-column';
import LayoutOneColumnHeader from '@krea/common/shared-components/app/one-column/LayoutOneColumnHeader';
import BankIDFlow from '@krea/common/shared-components/bankID/BankIDFlow';
import FIBankIDFlow from '@krea/common/shared-components/bankID-fi/FIBankIDFlow';
import Button from '@krea/common/shared-components/button';
import EmailInput from '@krea/common/shared-components/loan-application-form/fields/email';
import OrganisationNumber from '@krea/common/shared-components/loan-application-form/fields/organisation-number';
import PhoneInput from '@krea/common/shared-components/loan-application-form/fields/phone';
import { Text } from '@krea/common/shared-components/text';
import { APPLICATION_NAME, COUNTRY_CODE } from '@krea/common/utils';

import { useRegisterPartner } from 'store/register/hooks/mutations/useRegisterPartner';

import styles from './Register.module.scss';

const getValidationSchema = () =>
  Yup.object().shape({
    organisation: OrganisationValidation(),
    email: EmailValidation(),
    mobile_phone_number: PhoneNumberValidation(),
  });

const getFormFieldProps = ({ field, fieldState }) => ({
  name: field.name,
  value: field.value,
  onChange: field.onChange,
  onBlur: field.onBlur,
  errors: fieldState.error?.message,
  touched: fieldState.isTouched,
});

const getStepColor = (condition) => {
  return condition ? 'bg-primary' : 'bg-gray';
};

export const RegisterPage = () => {
  const isMobile = useMobile();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(1);

  const { mutateAsync: registerPartner } = useRegisterPartner();
  const { control, getValues, formState } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(getValidationSchema()),
  });

  const emailController = useController({
    name: 'email',
    control,
    defaultValue: '',
  });
  const phoneNumberController = useController({
    name: 'mobile_phone_number',
    control,
    defaultValue: '',
  });
  const organisationController = useController({
    name: 'organisation',
    control,
    defaultValue: {
      name: '',
      organisationNumber: '',
    },
  });

  const FIELDS = [
    {
      ...getFormFieldProps(organisationController),
      Component: OrganisationNumber,
      active: currentStep === 1,
      onChange: async (name, value) => {
        const subFieldName = name.split('.')[1];
        const prevValues = getValues('organisation');

        organisationController.field.onChange({
          ...prevValues,
          [subFieldName]: value,
        });
      },
    },
    {
      ...getFormFieldProps(emailController),
      Component: EmailInput,
      label: t('applicationForm.commons.emailLabel', { ns: 'common' }),
      active: currentStep === 2,
    },
    {
      ...getFormFieldProps(phoneNumberController),
      Component: PhoneInput,
      label: t('applicationForm.commons.phoneLabel', { ns: 'common' }),
      active: currentStep === 2,
    },
  ];

  const isFormValid = () => {
    if (currentStep === 1) {
      const { name, organisationNumber } = organisationController.field.value;

      return (
        !!name &&
        !!organisationNumber &&
        !organisationController.fieldState.invalid &&
        name !== organisationNumber
      );
    }

    return formState.isValid;
  };

  const formattedPostFormValues = useCallback(() => {
    const { organisation: { name, organisationNumber } = {}, ...rest } =
      getValues();

    return {
      ...rest,
      organisation_name: name,
      organisation_number: organisationNumber,
    };
  }, [getValues]);

  const onSuccess = async () => {
    await registerPartner(formattedPostFormValues());
  };

  return (
    <LayoutOneColumn>
      <LayoutOneColumnHeader
        title={t(`register.${currentStep === 3 ? 'bankIDHeading' : 'heading'}`)}
      >
        <Button variant="text" onClick={() => navigate('/login')}>
          {t('register.loginToExistingAccount')}
        </Button>
      </LayoutOneColumnHeader>

      <div className="my-8">
        <Text size={!isMobile ? 'md' : 'sm'} className="font-weight-bold">
          {t('iaq.progressBar.steps', { currentStep, stepsQty: 3 })}
        </Text>

        <div className="d-flex mt-3">
          <div className={classnames(styles.stepBorder, 'bg-primary')} />
          <div
            className={classnames(
              styles.stepBorder,
              getStepColor(currentStep >= 2),
            )}
          />
          <div
            className={classnames(
              styles.stepBorder,
              getStepColor(currentStep === 3),
            )}
          />
        </div>
      </div>

      {currentStep < 3 ? (
        <>
          <h5>
            {t(`register.step${currentStep}`, {
              organisationName: getValues().organisation?.name,
            })}
          </h5>

          <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
            {FIELDS.map(
              ({ name, Component, active, onChange, ...otherProps }) => {
                if (!active) {
                  return null;
                }

                return (
                  <Component
                    key={name}
                    setInputValue={onChange}
                    handleChange={onChange}
                    {...otherProps}
                  />
                );
              },
            )}
          </form>
        </>
      ) : null}

      <div className={styles.buttonsDiv}>
        {currentStep < 3 ? (
          <Button
            onClick={() => setCurrentStep((step) => step + 1)}
            disabled={!isFormValid()}
          >
            {t('iaq.nextQuestionCta')}
          </Button>
        ) : null}

        {currentStep === 2 ? (
          <Button
            variant="text"
            onClick={() => setCurrentStep((step) => step - 1)}
          >
            {t('iaq.previousQuestionCta')}
          </Button>
        ) : null}
      </div>

      {currentStep === 3 ? (
        <>
          {settings.countryCode === COUNTRY_CODE.SE ? (
            <h5 className="text-center mb-10">{t('register.step3')}</h5>
          ) : null}

          {settings.countryCode === COUNTRY_CODE.SE ? (
            <BankIDFlow
              authenticationContext={APPLICATION_NAME.CUSTOMER_WEB}
              onSuccess={onSuccess}
            />
          ) : (
            <FIBankIDFlow
              authenticationContext={APPLICATION_NAME.CUSTOMER_WEB}
              onSuccess={onSuccess}
              onCancel={() => navigate(0)}
            />
          )}
        </>
      ) : null}
    </LayoutOneColumn>
  );
};
