import { GraphQLClient } from 'graphql-request';

import { getAccessToken } from '@krea/common/services/httpService';
import { settings } from '@krea/common/settings';

const requestMiddleware = async (request) => {
  const currentAccessToken = await getAccessToken();

  return {
    ...request,
    headers: {
      ...request.headers,
      authorization: `Bearer ${currentAccessToken}`,
    },
  };
};

const responseMiddleware = async (response) => {
  // About the "response" object:
  // If response is an error it is an instance of Error that has a response and request property
  // * Examples:
  //    * response.request (request object)
  //    * response.response.errors (errors from response object)
  // If it's not an error it is the actual response object (with data, errors, etc. properties)

  if (response instanceof Error) {
    // console.log('response', response?.response)
  }
};

export const client = new GraphQLClient(`${settings.kreaBaseUrl}/api/graphql`, {
  errorPolicy: 'none',
  requestMiddleware,
  responseMiddleware,
});
