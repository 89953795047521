import i18n from 'i18next';
import validator from 'validator';
import * as Yup from 'yup';

export const PhoneNumberValidation = () => {
  return Yup.string()
    .required(
      i18n.t('applicationForm.commons.phoneErrors.required') ?? undefined,
    )
    .test(
      'phone-contains-special-characters',
      `${i18n.t('applicationForm.commons.phoneErrors.containsSpecialCharacters')}`,
      (value: string) => {
        if (value !== undefined) return !!value.match(/^[0-9\s()+-]+$/);
      },
    )
    .test(
      'phone-length',
      `${i18n.t('applicationForm.commons.phoneErrors.incorrectLength')}`,
      (value) => {
        const phoneLengthWithoutPrefix =
          value != null ? value.replace(/^(00|0|\+|\-\ )/, '').length : 0;

        return phoneLengthWithoutPrefix >= 7;
      },
    )
    .test(
      'phone-validity',
      `${i18n.t('applicationForm.commons.phoneErrors.invalid')}`,
      (value) => {
        if (value !== undefined) {
          if (value.startsWith('00')) value = value.substring(2); // Validator library does not recognize the "00" prefix as valid

          return validator.isMobilePhone(value);
        }
      },
    );
};
