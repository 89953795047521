import axios from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';

import { settings } from '@krea/common/settings';
import { APPLICATION_NAME } from '@krea/common/utils';

let ongoingRefreshRequest = null;

const refreshToken = async (refresh_token) => {
  const axiosInstanceWithoutInterceptor = axios.create();
  const isPartnerWeb = settings.appName === APPLICATION_NAME.PARTNER_WEB;
  const URL = `${settings.kreaBaseUrl}${
    isPartnerWeb ? '/partner' : ''
  }/api/v1/auth/refresh-token`;

  if (!ongoingRefreshRequest) {
    ongoingRefreshRequest = axiosInstanceWithoutInterceptor.post(URL, {
      refresh_token,
    });
  }

  const response = await ongoingRefreshRequest;

  const responseData = response.data;

  localStorage.setItem('accessToken', responseData.access_token);
  localStorage.setItem('refreshToken', responseData.refresh_token);

  ongoingRefreshRequest = null;

  return responseData.access_token;
};

const checkAccessAndRefreshTokens = () => {
  const accessTokenValue = localStorage.getItem('accessToken');
  const refreshTokenValue = localStorage.getItem('refreshToken');

  if (!accessTokenValue || !refreshTokenValue) {
    console.log('Not logged in');

    return null;
  }

  const decodedAccessToken = jwtDecode(accessTokenValue);
  const decodedRefreshToken = jwtDecode(refreshTokenValue);

  const hasAccessTokenExpired = decodedAccessToken.exp - dayjs().unix() <= 0;
  const hasRefreshTokenExpired = decodedRefreshToken.exp - dayjs().unix() <= 0;

  if (hasRefreshTokenExpired) {
    console.log('Refresh token has expired');

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    return null;
  }

  if (hasAccessTokenExpired) {
    return refreshToken(refreshTokenValue);
  }

  return accessTokenValue;
};

export const getAccessToken = async () => {
  const currentURL = new URL(window.location.href);

  // quickAuth could contain a very short-lived refreshToken so that we can "quickAuth" into customer web from
  // other authenticated sources.
  const quickAuthRefreshToken = currentURL.searchParams.get('quickAuth');
  currentURL.searchParams.delete('quickAuth');

  window.history.replaceState(null, '', currentURL.href);

  if (quickAuthRefreshToken) {
    try {
      const decodedQuickAuthToken = jwtDecode(quickAuthRefreshToken);

      const quickAuthTokenExpired =
        decodedQuickAuthToken.exp - dayjs().unix() <= 0;

      if (!quickAuthTokenExpired) {
        // If we have a quickAuthRefreshToken.
        // We want to use  that to get a fresh set of "normal" tokens.
        return await refreshToken(quickAuthRefreshToken);
      }
    } catch (err) {}
  }

  return checkAccessAndRefreshTokens();
};

export default class HttpService {
  static instance;

  static getInstance() {
    if (HttpService.instance) {
      return HttpService.instance;
    }

    HttpService.instance = new HttpService();

    return HttpService.instance;
  }
  static createInstanceWithoutInterceptors() {
    return axios.create({
      baseURL: settings.kreaBaseUrl,
    });
  }

  setupInterceptors() {
    axios.defaults.withCredentials = false; // otherwise axios seems to send session-cookie credentials SOMETIMES

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error.response);
      },
    );

    axios.interceptors.request.use(
      async (config) => {
        const currentAccessToken = await getAccessToken();
        config.headers.Authorization = currentAccessToken
          ? 'Bearer ' + currentAccessToken
          : null;

        const params = new URLSearchParams(window.location.search);
        const auth = params.get('auth');

        if (auth) {
          return { ...config, params: { ...config.params, auth } };
        }

        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      },
    );
  }
}
