import { isEmpty } from 'lodash';
import React, { useContext } from 'react';

import { useGetCurrentLoanApplication } from 'store/current-loan-application/hooks/queries';

import LoanApplicationProgressBar from './loan-application-progress-bar';

import { MenuContext } from './index';

const HeaderSteps = ({ isOnLoanApplicationFlowPage, loanApplicationID }) => {
  const context = useContext(MenuContext);
  const { data: currentLoanApplication = {} } =
    useGetCurrentLoanApplication(loanApplicationID);

  return !isEmpty(currentLoanApplication) && isOnLoanApplicationFlowPage ? (
    <LoanApplicationProgressBar
      isOpen={context.stepsIsVisible}
      hideStepsMenu={context.showSteps}
      loanApplicationID={loanApplicationID}
    />
  ) : null;
};

export default HeaderSteps;
