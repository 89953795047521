import axios from 'axios';

import { client } from '@krea/common/graphql';

export const fetchCurrentLoanApplicationData = async (loanApplicationID) => {
  return client.request(`{
    loanApplication(loanApplicationId: ${loanApplicationID}) {
      id,
      state,
      requestedLoanAmount,
      requestedPaybackTimeMonths,
      biddingEndsAt,
      eligibleForLeasingBids,
      loanApplicationDraftConversionEventSentAt,
      product,
      advisoryAcceptedAt,
      adviceCategory,
      isDigitalAdvice,
      initialNumberOfAttractivenessImprovements,
      applicationAttractiveness,
      customerPreferredRate,
      alternativeRate,
      verificationFailureReasons,
      latestDuplicatedApplicationInfo {
        id
        submittedAt
        numBids
      }
      attractivenessImprovements {
        field,
        displayText
      },
      assignedToAdminUser {
        id,
        email,
        firstName,
        lastName,
        mobilePhoneNumber,
        profileImage {
          lowResolutionUrl
        }
      },
      contactPerson {
        id,
        ssn,
        firstName,
        lastName,
        email,
        mobilePhoneNumber
      },
      triageFields,
      previewFields,
      applyingCompany {
        id,
        customerCompany {
          name,
          organisationNumber,
          typeDisplayName
        },
        boardMembers {
          ssn,
          firstName,
          lastName,
          roles {
            roleCode,
            roleName
          }
        }
      }
    }
  }`);
};

export const fetchSelectedLenderResponseData = async (loanApplicationID) => {
  return client.request(`{
    loanApplication(loanApplicationId: ${loanApplicationID}) {
      selectedLenderResponse {
        id,
        signingUrl,
        eligibleForPaymentAt,
        invoicePurchaseBid {
          totalPaidOut,
          purchasedInvoiceAmount,
          commissionPercent,
          handlingFeePerInvoice,
          monthlyFee,
          purchasedAmountPercent,
          totalMonthlyCost,
          purchaseLimit,
          limitFeePerYearPercent,
          setupFee,
          withRegress,
          regressNumDaysAfterDueDate,
          paidOutPerInvoice,
          paidOutPerInvoicePercent
        },

        lenderApplication {
          id,
          createdAt,
          updatedAt,
          lenderCompany {
            id,
            name,
            description,
            registeredAtFinansinspektionenAt,
            signingUrlTemplate,
            customerContactPhoneNumber,
            customerContactEmail,
            image {
              lowResolutionUrl
            }
          }
        },
        bid {
          monthlyCostWithoutSetupFee,
          amortizationMethodDisplayName,
          amortizationMethodDescription,
          totalLoanCost,
          monthlyCost,
          setupFee,
          postalFee,
          directDebitFee,
          otherFees {
            type,
            fee,
            interval,
            name
          },
          paybackTimeMonths,
          amortizationMethod,
          lenderMonthlyInterest,
          earlyRepaymentFee,
          amount
        },
        realEstateBid {
          id,
          monthlyCostWithoutSetupFee,
          amortizationMethodDisplayName,
          amortizationMethodDescription,
          totalLoanCost,
          monthlyCost,
          setupFee,
          postalFee,
          directDebitFee,
          paybackTimeMonths,
          amortizationMethod,
          lenderMonthlyInterest,
          earlyRepaymentFee,
          amount,
          loanToValueRatio
        },
        leasingBid {
          id,
          amount,
          paybackTimeMonths,
          monthlyPayment,
          totalCost,
          downPayment,
          terminalValue,
          setupFee,
          contractType,
          postalFee,
          insuranceMonthlyCost
        },
        revolvingCreditBid {
          id,
          earlyRepaymentFee,
          postalFee,
          directDebitFee,
          setupFee,
          amount,
          monthlyAmortisation,
          lenderMonthlyInterest,
          withdrawalFee,
          setupFee,
          limitFeePerYear,
          postalFee,
          monthlyPayment,
          monthlyCost
        },
        fieldRequests {
          id,
          type,
          field,
          required,
          displayName,
          displayText
          isSubmitted,
          subtitle,
          isCollectedByLender,
          submittedData,
          lenderComment
        },
        processSteps {
          text
        }
      }
    }
  }`);
};

export const submitApplication = async (loanApplicationID) => {
  return axios.post(
    `partner/api/v1/loan-applications/${loanApplicationID}/submit`,
  );
};

export const deselectLenderResponse = async (loanApplicationID) => {
  return axios.post(
    `partner/api/v1/loan-applications/${loanApplicationID}/deselect-lender-response`,
  );
};

export const markNoBidsReceived = async (loanApplicationID) => {
  return axios.post(
    `partner/api/v1/loan-applications/${loanApplicationID}/mark-no-bids-received`,
  );
};

export const markBidsRejectedByCustomer = async (loanApplicationID) => {
  return axios.post(
    `partner/api/v1/loan-applications/${loanApplicationID}/mark-bids-rejected-by-customer`,
  );
};

export const acceptAdvice = async (loanApplicationID) => {
  return axios.post(
    `partner/api/v1/loan-applications/${loanApplicationID}/accept-advisory`,
  );
};

export const withdrawAccept = async (loanApplicationID) => {
  return axios.delete(
    `partner/api/v1/loan-applications/${loanApplicationID}/accept-advisory`,
  );
};
