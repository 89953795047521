import loadable from '@loadable/component';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import LoanApplicationFlowDescription from '@krea/common/shared-components/loan-application-flow-description';
import Preloader from '@krea/common/shared-components/preloader';
import { Text } from '@krea/common/shared-components/text';
import { useGetCachedCurrentLoanApplication } from '@krea/common/store/current-loan-application/hooks';
import { LOAN_APPLICATION_STATES } from '@krea/common/utils';

import { DialogContent, DialogTitle } from '../dialog';

const ProgressBar = loadable(
  () => import('@krea/common/shared-components/progress-bar'),
);
const LoanApplicationMessages = loadable(
  () => import('@krea/common/shared-components/loan-application-messages'),
);

export const SubmitApplicationModal = () => {
  const { t } = useTranslation();
  const { data: { state, isDigitalAdvice } = {} } =
    useGetCachedCurrentLoanApplication();

  const [isDraft, setIsDraft] = useState(
    state === LOAN_APPLICATION_STATES.DRAFT,
  );

  const setDraft = (boolean) => {
    setTimeout(() => setIsDraft(boolean), 1000);
  };

  const getPopupTitle = () => {
    switch (state) {
      case LOAN_APPLICATION_STATES.CREATED:
        return t('iaq.applicationPreview.popup.title.applicationNotSent');

      case LOAN_APPLICATION_STATES.ADVICE:
        if (isDigitalAdvice) {
          return t(
            'iaq.applicationPreview.popup.title.applicationDigitalAdvice',
          );
        } else {
          return t('iaq.applicationPreview.popup.title.applicationAdvice');
        }
      case LOAN_APPLICATION_STATES.OPEN_FOR_BIDS:
        return t('iaq.applicationPreview.popup.title.applicationSent');
      default:
        return t('iaq.applicationPreview.popup.title.loading');
    }
  };

  useEffect(() => {
    setDraft(state === LOAN_APPLICATION_STATES.DRAFT);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <>
      <DialogTitle title={getPopupTitle()} />
      <DialogContent>
        {isDraft ? (
          <div className="bg-gray rounded p-4">
            <div className="d-flex align-items-center mb-2">
              <div className="text-primary mr-2 shrink-0">
                <Preloader className="text-primary" size="md" />
              </div>

              <Text size="sm" className="font-weight-bold text-gray-dark">
                {t('iaq.applicationPreview.popup.preloader')}
              </Text>
            </div>

            <ProgressBar
              isCompleted={state !== LOAN_APPLICATION_STATES.DRAFT}
            />
          </div>
        ) : (
          <LoanApplicationMessages isSubmitApplicationModal />
        )}

        <div className="border rounded px-6 py-4 mt-4">
          <LoanApplicationFlowDescription />
        </div>
      </DialogContent>
    </>
  );
};
