import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CopySVG from '@krea/common/images/svg/copy.svg';
import AppTooltip from '@krea/common/shared-components/tooltip';

const ClipboardCopyLink = ({ link, text }) => {
  const { t } = useTranslation();
  const [textCopied, setTextCopied] = useState(false);

  return (
    <div
      role={link && 'button'}
      onClick={() => {
        if (link) {
          navigator.clipboard.writeText(link);
          setTextCopied(true);
          setTimeout(() => setTextCopied(false), 2000);
        }
      }}
    >
      <AppTooltip
        isOpen={textCopied}
        content={t('remoteIdentification.copiedLink')}
        className={classNames('d-flex font-weight-bold', {
          'text-primary': link,
          'text-muted': !link || textCopied,
        })}
      >
        <span>{text || link}</span>

        <SVG src={CopySVG} className="ml-1" style={{ width: '15px' }} />
      </AppTooltip>
    </div>
  );
};

export default ClipboardCopyLink;
