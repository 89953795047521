import {
  getCookie,
  setCookie,
  deleteCookie,
} from '@krea/common/services/cookieService';

const AFFILIATE_COOKIE_NAME = 'affiliate-query-params';

export function storeUtmParamsInCookie() {
  const urlParams = new URLSearchParams(window.location.search);

  // TODO: remove this band aid fix when updated
  //  adtraction links are deployed with them, ask marketing about this.
  if (urlParams.get('at_gd')) {
    urlParams.set('utm_source', 'adtraction');
  }

  if (urlParams.get('utm_source')) {
    // iterate through all the query params and store them in a cookie
    let affiliateInfo = {};

    try {
      affiliateInfo = Array.from(urlParams.entries()).reduce(
        (acc, [key, value]) => {
          acc[key] = value;

          return acc;
        },
        {},
      );
    } catch (error) {
      console.error('Failed to extract affiliate info', error);
    }

    // Handle special cases (that need renaming etc..)
    if (urlParams.get('utm_source') === 'addrevenue.io') {
      if (urlParams.get('channelId')) {
        affiliateInfo.addrevenueChannelId = urlParams.get('channelId');
      }

      if (urlParams.get('clickId')) {
        affiliateInfo.addrevenueClickId = urlParams.get('clickId');
      }
    }

    console.log('Extracted affiliate info', affiliateInfo);
    setCookie(AFFILIATE_COOKIE_NAME, JSON.stringify(affiliateInfo));
  }
}

export function getUtmParamsFromCookie() {
  const utm_data_json_string = getCookie(AFFILIATE_COOKIE_NAME);

  try {
    const utm_data_json = {};
    const _gaCookie = getCookie('_ga');

    if (utm_data_json_string) {
      Object.assign(utm_data_json, JSON.parse(utm_data_json_string));
    }

    if (_gaCookie) {
      Object.assign(utm_data_json, {
        ga_client_id: _gaCookie.split('.')[2] + '.' + _gaCookie.split('.')[3],
      });
    }

    return JSON.stringify(utm_data_json);
  } catch (e) {}

  return utm_data_json_string;
}

export function deleteUtmParamsFromCookie() {
  return deleteCookie(AFFILIATE_COOKIE_NAME);
}
