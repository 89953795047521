import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { settings } from '@krea/common/settings';
import ClipboardCopyLink from '@krea/common/shared-components/clipboard-copy-link';
import Container from '@krea/common/shared-components/container';
import { Select } from '@krea/common/shared-components/fields/select/';
import { Text } from '@krea/common/shared-components/text';
import { Toast } from '@krea/common/shared-components/toast/Toast';
import { ToastWithContactPerson } from '@krea/common/shared-components/toast/ToastWithContactPerson';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';
import { COUNTRY_CODE, LOAN_APPLICATION_STATES } from '@krea/common/utils';

import { useGetLoanApplications } from 'store/loan-applications/hooks/queries';

import ApplicationsList from './ApplicationsList';
import ApplicationsSearch from './ApplicationsSearch';

const Applications = () => {
  const isSwedish = settings.countryCode === COUNTRY_CODE.SE;
  const { t } = useTranslation();
  const [isToastOpen, setIsToastOpen] = useState(true);

  const [filter, setFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortParam, setSort] = useState({
    column: 'created_at',
    direction: 'desc',
  });

  const { data: { isAdmin, partnerOrganisation } = {} } = useGetUserData();
  const { data: { pages = [] } = {}, isFetching } = useGetLoanApplications({
    filter,
    sortParam,
    searchTerm,
  });

  const { isActive, utmSourceIdentifier } = partnerOrganisation || {};
  const loanApplicationsCount = pages[0]?.count;

  const baseLink = `https://${isSwedish ? 'krea.se' : 'kredita.fi'}`;
  const referralLink = `${baseLink}/?utm_medium=partner&utm_source=${utmSourceIdentifier}`;

  const toastTitle = t(
    `listApplicationsView.${isActive ? 'activated' : 'deactivated'}Toast.title`,
  );
  const toastSubtitle = t(
    `listApplicationsView.${isActive ? 'activated' : 'deactivated'}Toast.subtitle`,
  );

  return (
    <Container>
      <div className="py-8">
        <h1 className="p-0">
          {isActive || isAdmin
            ? t('listApplicationsView.header')
            : t('listApplicationsView.nonActiveHeader')}
        </h1>

        {!isActive && !isAdmin ? (
          <ToastWithContactPerson
            className="mb-5 mt-8"
            innerText={{
              title: t('listApplicationsView.contactToast.title'),
              subtitle: t('listApplicationsView.contactToast.subtitle'),
            }}
            isWarning
          />
        ) : null}

        {isToastOpen && !isAdmin ? (
          <Toast
            className="mb-5"
            innerText={{ title: toastTitle, subtitle: toastSubtitle }}
            onClick={() => setIsToastOpen(false)}
          />
        ) : null}

        {!isAdmin ? (
          <div className="d-flex">
            <Text className="font-weight-bold mr-3 py-1">
              {t('listApplicationsView.referralLink')}
            </Text>

            <div className="bg-light border border-primary rounded px-3 py-1">
              <ClipboardCopyLink link={referralLink} />
            </div>
          </div>
        ) : null}
      </div>

      {isActive || isAdmin ? (
        <div className="pb-8">
          <div className="row mx-n3 mb-6 mb-lg-0">
            <div className="col-12 col-lg-7 col-xl-8 px-3">
              <ApplicationsSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                loading={isFetching}
                className="mt-6"
              />
            </div>

            <div className="col-12 col-lg-5 col-xl-4 px-3">
              <Select
                className="mt-4 mt-lg-6"
                name="state"
                label={t('listApplicationsView.stateSorting.label')}
                value={filter}
                onChange={({ target }) => setFilter(target.value || '')}
              >
                <option key={-1} value="">
                  {t('listApplicationsView.stateSorting.showAll')}
                </option>
                {Object.keys(LOAN_APPLICATION_STATES).map((item, index) => (
                  <option key={index} value={item}>
                    {t(`loanApplication.states.${item}`)}
                  </option>
                ))}
              </Select>
            </div>
          </div>
          <ApplicationsList
            filter={filter}
            sortParam={sortParam}
            searchTerm={searchTerm}
            setSort={setSort}
          />

          {!loanApplicationsCount ? (
            <Text className="text-center font-weight-bold mt-4">
              {t(
                `listApplicationsView.${isFetching ? 'loadingApplicationsText' : 'noApplicationsFound'}`,
              )}
            </Text>
          ) : null}
        </div>
      ) : null}
    </Container>
  );
};

export default Applications;
