import classnames from 'classnames';
import React from 'react';

import styles from './Dialog.module.scss';

const DialogTitle = ({ className, title, closeButton = null }) => {
  return (
    <div
      className={classnames(
        styles.header,
        'grow-0 shrink-0 pt-5 px-6 border-bottom',
        className,
      )}
    >
      <div className="d-flex align-items-center pb-5 justify-content-between">
        <h5 className="m-0 p-0">{title}</h5>
        {closeButton}
      </div>
    </div>
  );
};

export default DialogTitle;
