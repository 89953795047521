import loadable from '@loadable/component';
import { camelCase, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useParams, Outlet } from 'react-router-dom';

import { settings } from '@krea/common/settings';
import { FieldRequestsFormsImports } from '@krea/common/shared-components/field-request/forms';
import Preloader from '@krea/common/shared-components/preloader';
import { useGetFieldRequests } from '@krea/common/store/field-requests/hooks/queries';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';
import {
  LOAN_APPLICATION_STATES,
  getFilteredFieldRequests,
} from '@krea/common/utils';

import { useGetCurrentLoanApplication } from 'store/current-loan-application/hooks/queries';

const {
  DRAFT,
  CREATED,
  OPEN_FOR_BIDS,
  REJECTED,
  SUBJECT_TO_EU_SANCTIONS,
  NO_BIDS_RECEIVED,
  BIDS_REJECTED_BY_CUSTOMER,
  BID_SELECTED,
  PAYOUT_RECEIVED_BY_CUSTOMER,
  ADVICE,
} = LOAN_APPLICATION_STATES;

const AdvicePage = loadable(
  () => import('./../pages/loan-application-flow/advice'),
);
const CustomerDashboardPage = loadable(
  () => import('../pages/loan-application-flow/customer-dashboard'),
);
const ApplicationQuestionsStep = loadable(
  () => import('../pages/loan-application-flow/improve-application'),
);
const PreviewPage = loadable(
  () => import('../pages/loan-application-flow/preview'),
);
const PostSelectPage = loadable(
  () => import('../pages/loan-application-flow/post-select'),
);
const PermissionRoute = loadable(() => import('./PermissionRoute'));
const NotFound = loadable(() => import('../pages/not-found'));

const LoanApplicationRouterRedirect = ({ state, fieldRequests }) => {
  switch (state) {
    case DRAFT:
      return (
        <Navigate
          replace={true}
          to={`questions/${fieldRequests?.[0]?.field}`}
        />
      );
    case CREATED:
    case OPEN_FOR_BIDS:
    case REJECTED:
    case SUBJECT_TO_EU_SANCTIONS:
    case NO_BIDS_RECEIVED:
    case BIDS_REJECTED_BY_CUSTOMER:
      return <Navigate replace={true} to="dashboard" />;
    case BID_SELECTED:
    case PAYOUT_RECEIVED_BY_CUSTOMER:
      return <Navigate replace={true} to="selected" />;
    case ADVICE:
      return <Navigate replace={true} to="advice" />;
    default:
      return <Navigate replace={true} to="preview" />;
  }
};

const LoanApplicationRouter = () => {
  const { t } = useTranslation();
  const { loanApplicationID } = useParams();

  const { data: user = {} } = useGetUserData();
  const { data: currentLoanApplication = {}, isLoading } =
    useGetCurrentLoanApplication(loanApplicationID);
  const { data: fieldRequests = [] } = useGetFieldRequests(!isEmpty(user));

  const { state, previewFields, triageFields } = currentLoanApplication;
  const filteredFieldRequests = getFilteredFieldRequests(
    triageFields,
    fieldRequests,
  );
  const { appTitle } = settings;

  useEffect(() => {
    // Manually preload all preview field requests for improve-application-questions
    previewFields?.map(
      (item) =>
        FieldRequestsFormsImports.hasOwnProperty(camelCase(item)) &&
        FieldRequestsFormsImports[camelCase(item)],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!filteredFieldRequests?.[0] || isLoading) {
    return (
      <Preloader
        className="text-primary bg-white position-fixed w-100 h-200 mt-5"
        size="xl"
      />
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route
          index
          element={
            <LoanApplicationRouterRedirect
              state={state}
              fieldRequests={filteredFieldRequests}
            />
          }
        />

        <Route
          path="preview"
          element={
            <>
              <Helmet
                title={`${appTitle} Partner - ${t('commons.app.title.improveApp')}`}
              />
              <PreviewPage />
            </>
          }
        />

        <Route
          path="questions/*"
          element={
            <PermissionRoute>
              <Helmet
                title={`${appTitle} Partner - ${t('commons.app.title.improveApp')}`}
              />
              <Outlet />
            </PermissionRoute>
          }
        >
          {filteredFieldRequests?.map((step) => (
            <Route
              path={`${step.field}`}
              key={`route-${step.field}`}
              element={
                <ApplicationQuestionsStep
                  step={step}
                  steps={filteredFieldRequests}
                />
              }
            />
          ))}

          <Route
            path="*"
            element={
              <Navigate replace={true} to={filteredFieldRequests?.[0]?.field} />
            }
          />
        </Route>

        <Route
          path="advice"
          element={
            <PermissionRoute>
              <Helmet
                title={`${appTitle} Partner - ${t('commons.app.title.advice')}`}
              />
              <AdvicePage />
            </PermissionRoute>
          }
        />

        <Route
          path="dashboard"
          element={
            <PermissionRoute>
              <Helmet
                title={`${appTitle} Partner - ${t('commons.app.title.dashboard')}`}
              />
              <CustomerDashboardPage />
            </PermissionRoute>
          }
        />

        <Route
          path="selected"
          element={
            <PermissionRoute>
              <Helmet
                title={`${appTitle} Partner - ${t('commons.app.title.selectedLenderResponse')}`}
              />
              <PostSelectPage />
            </PermissionRoute>
          }
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default LoanApplicationRouter;
