import loadable from '@loadable/component';
import classnames from 'classnames';
import React from 'react';
import SVG from 'react-inlinesvg';

import InfoIcon from '@krea/common/images/svg/info.svg';
import QuestionIcon from '@krea/common/images/svg/question.svg';

import styles from './Helper.module.scss';

const AppTooltip = loadable(
  () => import('@krea/common/shared-components/tooltip'),
);

export const Helper = ({
  className,
  content,
  useQuestionIcon = false,
}: {
  className?: string;
  content: React.ReactNode;
  useQuestionIcon?: boolean;
}) => {
  return (
    <div
      className={classnames(
        'd-inline-flex justify-content-center align-items-center',
        styles.root,
        className,
      )}
      onClick={(e) => e.stopPropagation}
    >
      <AppTooltip
        className={classnames(styles.icon, {
          [styles.questionIcon]: useQuestionIcon,
        })}
        content={content}
      >
        <SVG
          className="position-absolute w-100 h-auto"
          src={useQuestionIcon ? QuestionIcon : InfoIcon}
        />
      </AppTooltip>
    </div>
  );
};
