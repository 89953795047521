import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import React from 'react';

import FakturinoLogoSVG from '@krea/common/images/svg/logo/fakturino-krea-logo.svg';
import KreditaLogoSVG from '@krea/common/images/svg/logo/kredita-logo.svg';
import LogoSVG from '@krea/common/images/svg/logo/logo.svg';
import { settings } from '@krea/common/settings';
import { COUNTRY_CODE } from '@krea/common/utils';

import styles from './Logo.module.scss';

const Logo = ({ className, href }) => {
  const htmlTag = href ? 'a' : 'div';
  let logoSVG = '';

  if (settings.countryCode === COUNTRY_CODE.SE) {
    logoSVG = settings.isOnFakturino ? FakturinoLogoSVG : LogoSVG;
  } else if (settings.countryCode === COUNTRY_CODE.FI) {
    logoSVG = KreditaLogoSVG;
  }

  const logoBlock = (
    <SVG className="d-block position-absolute w-100 h-auto" src={logoSVG} />
  );

  const icon = (countrycode) => {
    switch (countrycode) {
      case COUNTRY_CODE.SE:
        return styles.kreaLogo;
      case COUNTRY_CODE.FI:
        return styles.kreditaLogo;
      default:
        return styles.kreaLogo;
    }
  };

  return React.createElement(
    htmlTag,
    {
      className: classnames(
        styles.root,
        icon(settings.countryCode),
        'd-block position-relative',
        className,
      ),
      href: href,
    },
    logoBlock,
  );
};

export default Logo;
