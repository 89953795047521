import { useMutation, useQueryClient } from '@tanstack/react-query';

import { cancelViaBankID } from '@krea/common/store/bankID/api';
import { useBankIDStore } from '@krea/common/store/bankID/hooks';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import { BANKID_AUTHENTICATE_STORE_KEY } from '@krea/common/store/queryClient';
import { BANKID_FLOW_STATES, HINT_CODE_STATES } from '@krea/common/utils';

export const useCancelBankID = () => {
  const queryClient = useQueryClient();
  const clearSsn = useLoanApplicationFormStore(({ clearSsn }) => clearSsn);
  const { orderRef, resetBankIDStore } = useBankIDStore((state) => ({
    orderRef: state.orderRef,
    resetBankIDStore: state.resetBankIDStore,
  }));

  return useMutation({
    onMutate: clearSsn,
    mutationFn: () => cancelViaBankID(orderRef),
    onSuccess: () => {
      resetBankIDStore();

      queryClient.cancelQueries([BANKID_AUTHENTICATE_STORE_KEY]);
      queryClient.setQueryData([BANKID_AUTHENTICATE_STORE_KEY], {});
    },
  });
};
