import classnames from 'classnames';

import { ConditionalTooltip } from '../app/ConditionalTooltip';

import { Text } from './';

const TextActionLink = ({ value, className, ...otherProps }) => {
  const condition = value?.length > 30;

  return (
    <ConditionalTooltip condition={condition} value={value}>
      <Text
        role="link"
        tabIndex="0"
        aria-label={value}
        size="md"
        className={classnames(
          'font-weight-bold text-primary',
          { 'text-truncate tw-truncate': condition },
          className,
        )}
        style={{ cursor: 'pointer' }}
        {...otherProps}
      >
        {value}
      </Text>
    </ConditionalTooltip>
  );
};

export default TextActionLink;
