import loadable from '@loadable/component';
import { useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';
import i18n from 'i18next';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import SVG from 'react-inlinesvg';

import BankIdSVG from '@krea/common/images/svg/bankid/bankid-colorless.svg';
import BottomLeftCornerSVG from '@krea/common/images/svg/bankid/bottom-left-bankid-corner.svg';
import BottomRightCornerSVG from '@krea/common/images/svg/bankid/bottom-right-bankid-corner.svg';
import TopLeftCornerSVG from '@krea/common/images/svg/bankid/top-left-bankid-corner.svg';
import TopRightCornerSVG from '@krea/common/images/svg/bankid/top-right-bankid-corner.svg';
import { settings } from '@krea/common/settings';
import Button from '@krea/common/shared-components/button';
import TextInput from '@krea/common/shared-components/fields/input';
import { Helper } from '@krea/common/shared-components/helper';
import LoginContainer from '@krea/common/shared-components/login/LoginContainer';
import LoginGoToForm from '@krea/common/shared-components/login/LoginGoToForm';
import LoginState from '@krea/common/shared-components/login/LoginState';
import { useModalStore } from '@krea/common/store/modal/hooks';
import {
  useBankIDAuthenticate,
  useBankIDStore,
} from '@krea/common/store/bankID/hooks';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import { BANKID_AUTHENTICATE_STORE_KEY } from '@krea/common/store/queryClient';
import {
  APPLICATION_NAME,
  BANKID_FLOW_STATES,
  HINT_CODE_STATES,
  isIOS,
  isMobile,
  isValidSwedishPersonalNumber,
  LOAN_APPLICATION_FORM_PAGES,
  TEST_IDS,
} from '@krea/common/utils';

import Preloader from '../preloader';

import styles from './BankIDFlow.module.scss';

const QRCode = loadable(() => import('qrcode.react'));

const BANKID_SCHEMA = 'bankid:///';

const TestFlowSsnAuth = ({
  bankIDAuthenticate,
  isTestFlowFormSubmitted,
  setTestFlowFormSubmitted,
}) => {
  /*
   * This is a form for testing the BankID flow with a fake SSN.
   * Only used in non-prod.
   * Never to be used in production!!
   * */
  const { t } = useTranslation();
  const { ssn, setSsn } = useLoanApplicationFormStore(
    useShallow(({ ssn, setSsn }) => ({ ssn, setSsn })),
  );
  const [SSNError, setSSNError] = useState(null);
  const isValidSSN = isValidSwedishPersonalNumber(ssn);

  const onChange = ({ target: { value } }) => {
    const digitsValue = value.replace(/[^0-9]/g, '');

    setSSNError(null);
    setSsn(digitsValue);
  };

  const testFlowWithSsnAuth = (e) => {
    e.preventDefault();

    if (!isValidSSN) {
      setSSNError(t('login.giveValidSSN'));

      return;
    }

    setTestFlowFormSubmitted(true);
    bankIDAuthenticate();
  };

  return (
    <form className="w-100" onSubmit={testFlowWithSsnAuth}>
      <TextInput
        data-test-id={TEST_IDS.common.bankId.ssnInput}
        autoFocus
        size="lg"
        noValidate
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
        value={ssn}
        onChange={onChange}
        placeholder="ÅÅÅÅMMDD-XXXX"
        name="bankid-ssn"
        id="bankid-ssn"
        data-storage="false"
        error={SSNError}
        autoComplete="off"
        label={
          <>
            <span className="mr-1">{t('login.SSN')}</span>

            <Helper content={t('login.SSNHelper')} />
          </>
        }
      />

      <Button
        block
        size="lg"
        variant={
          !!SSNError || ssn.length < 12 || !isValidSSN
            ? 'outlined'
            : 'contained'
        }
        type="submit"
        className="mt-5 tw-w-full"
        disabled={isTestFlowFormSubmitted}
        data-test-id={TEST_IDS.common.bankId.bankIdContinueButton}
      >
        {t('login.continue')}
      </Button>
    </form>
  );
};

const AlternativeAuthOptions = ({ authType, changeAuthType }) => {
  const { t } = useTranslation();
  const options = [];

  if (authType === BankIDAuthType.QR_CODE) {
    options.push(
      <Button
        key="qr-code"
        block
        size="lg"
        variant="outlined"
        justify="center"
        className="text-left tw-w-full"
        tabIndex="0"
        id="bankid-flow-mobile"
        onClick={(e) => {
          changeAuthType(BankIDAuthType.AUTO_START);
        }}
      >
        {t('login.bankIdOnThisDevice')}
      </Button>,
    );
  }

  if (authType === BankIDAuthType.AUTO_START) {
    options.push(
      <Button
        key="current-device"
        block
        size="lg"
        variant="outlined"
        justify="center"
        tabIndex="0"
        id="bankid-flow-current-device"
        className="text-left tw-w-full"
        onClick={(e) => changeAuthType(BankIDAuthType.QR_CODE)}
      >
        {t('login.bankIdOnAnotherDevice')}
      </Button>,
    );
  }

  if (
    settings.environment !== 'production' &&
    ![BankIDAuthType.SSN, null].includes(authType)
  ) {
    options.push(
      <Button
        key="test-flow"
        block
        size="lg"
        variant="outlined"
        justify="center tw-w-full"
        className="text-left tw-w-full"
        tabIndex="0"
        id="bankid-flow-test-flow"
        onClick={(e) => changeAuthType(BankIDAuthType.SSN)}
        data-test-id={TEST_IDS.common.bankId.mobileBankIdTestFlowButton}
      >
        {t('login.bankIdOnTestFlow')}
      </Button>,
    );
  }

  return (
    <div className="d-flex flex-column pt-6" style={{ maxWidth: 400 }}>
      {options.map((option, index) => (
        <div key={index} className="py-2">
          {option}
        </div>
      ))}
    </div>
  );
};

const BankIDAuthType = {
  QR_CODE: 'QR_CODE',
  AUTO_START: 'AUTO_START',
  SSN: 'SSN', // Only used for testing
};

const BankIDFlow = ({
  identificationContext = undefined,
  authenticationContext,
  onSuccess,
  onCancel,
  onFailure = undefined,
  hideHeading,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const [authType, setAuthType] = useState(null);
  const [isTestFlowFormSubmitted, setTestFlowFormSubmitted] = useState(false);

  const isCustomerWeb = settings.appName === APPLICATION_NAME.CUSTOMER_WEB;
  const isLoginPage = pathname.includes('/login');
  const isRemoteIdentificationPage = pathname.includes('/identify');
  const isLoanApplicationFormPage =
    Object.values(LOAN_APPLICATION_FORM_PAGES).some((page) =>
      pathname.includes(page),
    ) || pathname.includes('/application');

  const qrCode = useBankIDStore(({ qrCode }) => qrCode);

  const { openModal, closeModal, isSupportTabOpen } = useModalStore(
    useShallow(({ openModal, closeModal, isSupportTabOpen }) => ({
      openModal,
      closeModal,
      isSupportTabOpen,
    })),
  );

  const { clearFormErrors, errorCode } = useLoanApplicationFormStore(
    (state) => ({
      clearFormErrors: state.clearFormErrors,
      errorCode: state.formErrors.errorCode,
    }),
  );

  const {
    isPending,
    isComplete,
    isNotInitiated,
    isError,
    isUserCancel,
    isUserSign,
    bankIDAuthenticate,
    cancelBankID,
    resetAuthenticateMutation,
  } = useBankIDAuthenticate(
    onSuccess,
    identificationContext,
    authenticationContext,
    onFailure,
  );

  const openBankIDOnThisDevice = useCallback((autoStartToken) => {
    if (autoStartToken) {
      // A hash is set to ensure that the BankID app can redirect back to the browser
      window.location.hash = '#bankid-container';

      let redirectURL = null;

      if (isIOS()) {
        // List of IOS user agents: https://gist.github.com/taylorhughes/dae564f63b3d702429a5f37bd22b97b8
        // Android will work natively to redirect back to the prior browser with redirectURL = null
        // However, on IOS we have to handle this manually.
        // Leaving the redirectURL as null will show a completion screen in the BankID app telling the user to
        // manually switch back to the browser.

        const isChrome = Boolean(navigator.userAgent.match(/CriOS/));
        const isFirefox = Boolean(navigator.userAgent.match(/FxiOS/));
        const isOpera = Boolean(navigator.userAgent.match(/OPiOS/));
        const isEdgeBrowser = Boolean(navigator.userAgent.match(/EdgiOS/)); // Not yet supported by BankID app

        // Only way to safely detect Safari on iOS is to exclude "other vendors".
        // Which what we try to do here to our greatest extent.
        // As of the time of implementation we only need to check for "not isChrome" (but we do others as well just in case)
        const isSafari =
          /Safari/.test(navigator.userAgent) &&
          !isChrome &&
          !isFirefox &&
          !isOpera &&
          !isEdgeBrowser;

        if (isChrome) {
          // Force the BankID app to reopen chrome on completion
          redirectURL = encodeURIComponent('googlechrome://');
        }

        if (isFirefox) {
          // Force the BankID app to reopen firefox on completion
          redirectURL = encodeURIComponent('firefox://');
        }

        if (isSafari) {
          // Force the BankID app to reopen Safari on completion
          // A different hash than the one set above is used to ensure that the BankID app can redirect back to the browser
          redirectURL = encodeURIComponent(
            window.location.href.replace(window.location.hash, '#bankid'),
          );
        }
      }

      const query = `?autostarttoken=${autoStartToken}&redirect=${redirectURL}`;
      window.location.href = `${BANKID_SCHEMA}${query}`;
    }
  }, []);

  const changeAuthType = (bankIdAuthType) => {
    // Resets Authentication mutation state
    resetAuthenticateMutation();

    // Optimistically set status / hintCode in queryClient to the natural "initial state" of the first collect call.
    // So the UI can switch between authType's without any UI flickering.
    queryClient.setQueryData([BANKID_AUTHENTICATE_STORE_KEY], {
      status: BANKID_FLOW_STATES.PENDING,
      hintCode: HINT_CODE_STATES.OUTSTANDING_TRANSACTION,
    });

    // re-start auth
    startAuth(bankIdAuthType);
  };

  const startAuth = (bankIdAuthType) => {
    // Set the auth type in local state
    setAuthType(bankIdAuthType);

    bankIDAuthenticate(null, {
      onSuccess: (response) => {
        const { autoStartToken } = response?.data || {};

        // For AUTO_START, we want to do this side effect of opening the BankID app on this device
        if (bankIdAuthType === BankIDAuthType.AUTO_START) {
          if (autoStartToken) {
            // Important to set the hash here, so that the BankID app "on this device" can redirect back to this url
            openBankIDOnThisDevice(autoStartToken);
          } else {
            console.error(
              'No autoStartToken found in response. Should not happen.',
            );
          }
        }
      },
    });
  };

  const cancelHandler = async () => {
    // Clean up / reset necessary local state when canceling
    setAuthType(null);
    // Resets Authentication mutation state
    resetAuthenticateMutation();

    if (isPending) {
      // If canceling while in pending state, we want to cancel the BankID flow
      cancelBankID();
    } else {
      // Otherwise, we want to reset the state to the initial state as if loaded for the first time

      // Resets test flow form state
      setTestFlowFormSubmitted(false);

      // reset status / hintCode in queryClient
      queryClient.setQueryData([BANKID_AUTHENTICATE_STORE_KEY], {});

      if (onCancel) {
        onCancel();
      }
    }
  };

  const onInitialAuth = useCallback(() => {
    const bankIdAuthType = isMobile()
      ? BankIDAuthType.AUTO_START
      : BankIDAuthType.QR_CODE;
    startAuth(bankIdAuthType);
  }, []);

  const getPendingStateTitle = () => {
    if (authType === BankIDAuthType.QR_CODE && isUserSign) {
      return t('login.writeSecurityCode');
    }

    if (authType === BankIDAuthType.AUTO_START) {
      return t('login.autostart.title');
    }

    return t('login.startBankId');
  };

  return (
    <div className="d-flex flex-column align-items-center w-100">
      {isPending ? (
        <LoginContainer title={getPendingStateTitle()}>
          {authType === BankIDAuthType.QR_CODE ? (
            <div style={{ maxWidth: 290 }}>
              {!isUserSign && qrCode ? (
                <div className="d-flex flex-column align-items-center mt-2 w-100">
                  <div className={styles.qrCodeContainer}>
                    <SVG src={TopLeftCornerSVG} />
                    <SVG src={TopRightCornerSVG} />
                    <SVG src={BottomLeftCornerSVG} />
                    <SVG src={BottomRightCornerSVG} />
                    <QRCode
                      size={200}
                      renderAs="svg"
                      className="n-bankid-qr-code"
                      value={qrCode}
                    />
                  </div>
                </div>
              ) : (
                <Preloader size="md" />
              )}

              <div className="tw-mt-6">
                <h6 className="tw-text-left tw-pl-6">
                  {t('login.bankIdQrCodeManual.title')}:
                </h6>

                <ol className="tw-max-w-[300px] tw-list-decimal tw-pl-10">
                  <li>{t('login.bankIdQrCodeManual.step1')}</li>
                  <li>{t('login.bankIdQrCodeManual.step2')}</li>
                  <li>{t('login.bankIdQrCodeManual.step3')}</li>
                  <li>{t('login.bankIdQrCodeManual.step4')}</li>
                </ol>
              </div>
            </div>
          ) : authType === BankIDAuthType.AUTO_START ? (
            <div className="d-flex flex-column">
              <Preloader size="md" />
              <LoginState className="mt-2">
                {t('login.autostart.description')}
              </LoginState>
            </div>
          ) : authType === BankIDAuthType.SSN ? (
            <TestFlowSsnAuth
              bankIDAuthenticate={bankIDAuthenticate}
              isTestFlowFormSubmitted={isTestFlowFormSubmitted}
              setTestFlowFormSubmitted={setTestFlowFormSubmitted}
            />
          ) : null}
          {!isUserSign && (
            <AlternativeAuthOptions
              authType={authType}
              changeAuthType={changeAuthType}
            />
          )}
        </LoginContainer>
      ) : null}

      {isUserCancel ? (
        <LoginContainer title={i18n.t('login.canceledTitle')}>
          <LoginState>{t('login.loginCancelled')}</LoginState>
        </LoginContainer>
      ) : null}

      {isError ? (
        errorCode === 1007 ? (
          <>
            <LoginState className="mt-2" error>
              <div>
                <p>
                  {i18n.t(
                    'remoteIdentification.fraudClientErrorPage.description',
                  )}
                </p>
                <span>
                  {i18n.t(
                    'remoteIdentification.fraudClientErrorPage.descriptionNewLine',
                  )}
                </span>
              </div>
            </LoginState>
            <div className="tw-mt-4 tw-flex tw-justify-center">
              <Button
                className="tw-w-full"
                onClick={() => {
                  openModal({
                    modal: null,
                    closeOnBackgroundClick: false,
                    onCloseSideEffects: null,
                    isSupportTabOpen: true,
                  });
                }}
              >
                {i18n.t(
                  'commons.contactCustomerServiceDialog.contactCustomerService',
                )}
              </Button>
            </div>
          </>
        ) : (
          <>
            <LoginState className="mt-2" error>
              {i18n.t('remoteIdentification.genericErrorPage.description')}
            </LoginState>
            <div className="tw-mt-4 tw-flex tw-justify-center">
              <Button
                className="tw-w-full"
                onClick={() => {
                  openModal({
                    modal: null,
                    closeOnBackgroundClick: false,
                    onCloseSideEffects: null,
                    isSupportTabOpen: true,
                  });
                }}
              >
                {i18n.t(
                  'commons.contactCustomerServiceDialog.contactCustomerService',
                )}
              </Button>
            </div>
          </>
        )
      ) : null}

      {isNotInitiated ? (
        <LoginContainer
          title={
            !hideHeading
              ? t('login.heading', { applicationTitle: settings.appTitle })
              : null
          }
        >
          <Button
            style={{ maxWidth: 400 }}
            block
            size="lg"
            variant="contained"
            justify="center"
            className={classnames('text-center', 'tw-w-full', {
              'mt-4': hideHeading,
            })}
            tabIndex="0"
            id="bankid-flow-mobile"
            disabled={!isError && authType !== null}
            onClick={(e) => onInitialAuth()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onInitialAuth();
              }
            }}
            startIcon={<SVG src={BankIdSVG} />}
            data-test-id={TEST_IDS.common.bankId.mobileBankIdButton}
          >
            {t('login.bankIdGeneric')}
          </Button>

          {isLoginPage && isCustomerWeb && <LoginGoToForm />}
        </LoginContainer>
      ) : null}

      {isComplete ? (
        <div className={isRemoteIdentificationPage ? 'mt-5' : ''}>
          <LoginContainer title={t('login.pleaseWait')} />
        </div>
      ) : null}

      {!isComplete &&
      (isPending || isUserCancel || isError || isLoanApplicationFormPage) ? (
        <Button
          variant="text"
          className="tw-mt-6 tw-w-full"
          onClick={cancelHandler}
          onKeyDown={(e) => {
            if (e.key !== 'Enter') return;
            e.preventDefault();

            cancelHandler();
          }}
          tabIndex="0"
        >
          <Trans i18nKey={`login.${isPending ? 'abortLogin' : 'back'}`}>
            {isPending ? 'Avbryt inloggning' : 'Tillbaka'}
          </Trans>
        </Button>
      ) : null}
    </div>
  );
};

export default BankIDFlow;
